import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Dimension: any;
  HexColor: any;
  JSON: any;
  Quality: any;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotification = Entry &
  _Node & {
    __typename: 'AlertNotification';
    _id: Scalars['ID'];
    body: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AlertNotificationLinkingCollections>;
    sys: Sys;
    tagsCollection: Maybe<AlertNotificationTagsCollection>;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
  };

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AlertNotificationTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A global level alert [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertNotification) */
export type AlertNotificationTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AlertNotificationCollection = {
  __typename: 'AlertNotificationCollection';
  items: Array<Maybe<AlertNotification>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AlertNotificationFilter = {
  AND: InputMaybe<Array<InputMaybe<AlertNotificationFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AlertNotificationFilter>>>;
  body: InputMaybe<Scalars['String']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not: InputMaybe<Scalars['String']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  body_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AlertNotificationLinkingCollections = {
  __typename: 'AlertNotificationLinkingCollections';
  alertWrapperCollection: Maybe<AlertWrapperCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type AlertNotificationLinkingCollectionsAlertWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AlertNotificationLinkingCollectionsAlertWrapperCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AlertNotificationLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AlertNotificationLinkingCollectionsAlertWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AlertNotificationOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AlertNotificationTagsCollection = {
  __typename: 'AlertNotificationTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AlertNotificationTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapper = Entry &
  _Node & {
    __typename: 'AlertWrapper';
    _id: Scalars['ID'];
    activeAlertsCollection: Maybe<AlertWrapperActiveAlertsCollection>;
    contentfulMetadata: ContentfulMetadata;
    inactiveAlertsCollection: Maybe<AlertWrapperInactiveAlertsCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AlertWrapperLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapperActiveAlertsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AlertWrapperActiveAlertsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlertNotificationFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapperInactiveAlertsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AlertWrapperInactiveAlertsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlertNotificationFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/alertWrapper) */
export type AlertWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AlertWrapperActiveAlertsCollection = {
  __typename: 'AlertWrapperActiveAlertsCollection';
  items: Array<Maybe<AlertNotification>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AlertWrapperActiveAlertsCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AlertWrapperCollection = {
  __typename: 'AlertWrapperCollection';
  items: Array<Maybe<AlertWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AlertWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<AlertWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AlertWrapperFilter>>>;
  activeAlerts: InputMaybe<CfAlertNotificationNestedFilter>;
  activeAlertsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  inactiveAlerts: InputMaybe<CfAlertNotificationNestedFilter>;
  inactiveAlertsCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type AlertWrapperInactiveAlertsCollection = {
  __typename: 'AlertWrapperInactiveAlertsCollection';
  items: Array<Maybe<AlertNotification>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AlertWrapperInactiveAlertsCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AlertWrapperLinkingCollections = {
  __typename: 'AlertWrapperLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AlertWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AlertWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReport = Entry &
  _Node & {
    __typename: 'AnnualSustainabilityReport';
    _id: Scalars['ID'];
    billboard: Maybe<Teaser>;
    contentfulMetadata: ContentfulMetadata;
    graphicImages2Collection: Maybe<AssetCollection>;
    graphicImagesCollection: Maybe<AssetCollection>;
    heroBanner: Maybe<Teaser>;
    introCta: Maybe<CtaButton>;
    introPreamble: Maybe<Scalars['String']>;
    introTitle: Maybe<Scalars['String']>;
    introVideo: Maybe<Asset>;
    linkedArticle: Maybe<AnnualSustainabilityReportLinkedArticle>;
    linkedArticlesCollection: Maybe<AnnualSustainabilityReportLinkedArticlesCollection>;
    linkedFrom: Maybe<AnnualSustainabilityReportLinkingCollections>;
    sys: Sys;
    uniqueId: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportGraphicImages2CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportGraphicImagesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportIntroCtaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportIntroPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportIntroTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportIntroVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportLinkedArticleArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AnnualSustainabilityReportLinkedArticleFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportLinkedArticlesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AnnualSustainabilityReportLinkedArticlesFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/annualSustainabilityReport) */
export type AnnualSustainabilityReportUniqueIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AnnualSustainabilityReportCollection = {
  __typename: 'AnnualSustainabilityReportCollection';
  items: Array<Maybe<AnnualSustainabilityReport>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnualSustainabilityReportFilter = {
  AND: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportFilter>>>;
  billboard: InputMaybe<CfTeaserNestedFilter>;
  billboard_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  graphicImages2Collection_exists: InputMaybe<Scalars['Boolean']>;
  graphicImagesCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  introCta: InputMaybe<CfCtaButtonNestedFilter>;
  introCta_exists: InputMaybe<Scalars['Boolean']>;
  introPreamble: InputMaybe<Scalars['String']>;
  introPreamble_contains: InputMaybe<Scalars['String']>;
  introPreamble_exists: InputMaybe<Scalars['Boolean']>;
  introPreamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introPreamble_not: InputMaybe<Scalars['String']>;
  introPreamble_not_contains: InputMaybe<Scalars['String']>;
  introPreamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introTitle: InputMaybe<Scalars['String']>;
  introTitle_contains: InputMaybe<Scalars['String']>;
  introTitle_exists: InputMaybe<Scalars['Boolean']>;
  introTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introTitle_not: InputMaybe<Scalars['String']>;
  introTitle_not_contains: InputMaybe<Scalars['String']>;
  introTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introVideo_exists: InputMaybe<Scalars['Boolean']>;
  linkedArticle: InputMaybe<CflinkedArticleMultiTypeNestedFilter>;
  linkedArticle_exists: InputMaybe<Scalars['Boolean']>;
  linkedArticles: InputMaybe<CflinkedArticlesMultiTypeNestedFilter>;
  linkedArticlesCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  uniqueId: InputMaybe<Scalars['String']>;
  uniqueId_contains: InputMaybe<Scalars['String']>;
  uniqueId_exists: InputMaybe<Scalars['Boolean']>;
  uniqueId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueId_not: InputMaybe<Scalars['String']>;
  uniqueId_not_contains: InputMaybe<Scalars['String']>;
  uniqueId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnnualSustainabilityReportLinkedArticle = Article | Teaser;

export type AnnualSustainabilityReportLinkedArticleFilter = {
  AND: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportLinkedArticleFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportLinkedArticleFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnnualSustainabilityReportLinkedArticlesCollection = {
  __typename: 'AnnualSustainabilityReportLinkedArticlesCollection';
  items: Array<Maybe<AnnualSustainabilityReportLinkedArticlesItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AnnualSustainabilityReportLinkedArticlesFilter = {
  AND: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportLinkedArticlesFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportLinkedArticlesFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AnnualSustainabilityReportLinkedArticlesItem = Article | Teaser;

export type AnnualSustainabilityReportLinkingCollections = {
  __typename: 'AnnualSustainabilityReportLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AnnualSustainabilityReportLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AnnualSustainabilityReportOrder {
  IntroPreambleAsc = 'introPreamble_ASC',
  IntroPreambleDesc = 'introPreamble_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
}

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type Article = Entry &
  _Node & {
    __typename: 'Article';
    _id: Scalars['ID'];
    authorCollection: Maybe<ArticleAuthorCollection>;
    body: Maybe<ArticleBody> | null;
    category: Maybe<Scalars['String']> | null;
    contentfulMetadata: ContentfulMetadata;
    embedMedia: Maybe<EmbedMedia> | null;
    factBoxCollection: Maybe<ArticleFactBoxCollection>;
    linkedFrom: Maybe<ArticleLinkingCollections>;
    mediaImageCollection: Maybe<ArticleMediaImageCollection>;
    mediaVideoCollection: Maybe<ArticleMediaVideoCollection>;
    preamble: Maybe<Scalars['String']> | null;
    publicationDate: Maybe<Scalars['DateTime']> | null;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo> | null;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<ArticleTagsCollection>;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
  };

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleAuthorCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleAuthorCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AuthorFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleCategoryArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleEmbedMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<EmbedMediaFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleMediaImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleMediaImageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleMediaVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleMediaVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticlePreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticlePublicationDateArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** An editorial written work depicting a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/article) */
export type ArticleTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type ArticleAuthorCollection = {
  __typename: 'ArticleAuthorCollection';
  items: Array<Maybe<Author>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleAuthorCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  LinkedInAsc = 'linkedIn_ASC',
  LinkedInDesc = 'linkedIn_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
}

export type ArticleBody = {
  __typename: 'ArticleBody';
  json: Scalars['JSON'];
  links: ArticleBodyLinks;
};

export type ArticleBodyAssets = {
  __typename: 'ArticleBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type ArticleBodyEntries = {
  __typename: 'ArticleBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type ArticleBodyLinks = {
  __typename: 'ArticleBodyLinks';
  assets: ArticleBodyAssets;
  entries: ArticleBodyEntries;
  resources: ArticleBodyResources;
};

export type ArticleBodyResources = {
  __typename: 'ArticleBodyResources';
  block: Array<ArticleBodyResourcesBlock>;
  hyperlink: Array<ArticleBodyResourcesHyperlink>;
  inline: Array<ArticleBodyResourcesInline>;
};

export type ArticleBodyResourcesBlock = ResourceLink & {
  __typename: 'ArticleBodyResourcesBlock';
  sys: ResourceSys;
};

export type ArticleBodyResourcesHyperlink = ResourceLink & {
  __typename: 'ArticleBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type ArticleBodyResourcesInline = ResourceLink & {
  __typename: 'ArticleBodyResourcesInline';
  sys: ResourceSys;
};

export type ArticleCollection = {
  __typename: 'ArticleCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ArticleFactBoxCollection = {
  __typename: 'ArticleFactBoxCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ArticleFilter = {
  AND: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  author: InputMaybe<CfAuthorNestedFilter>;
  authorCollection_exists: InputMaybe<Scalars['Boolean']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia: InputMaybe<CfEmbedMediaNestedFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  factBox: InputMaybe<CfFactBoxNestedFilter>;
  factBoxCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationDate: InputMaybe<Scalars['DateTime']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']>;
  publicationDate_gt: InputMaybe<Scalars['DateTime']>;
  publicationDate_gte: InputMaybe<Scalars['DateTime']>;
  publicationDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publicationDate_lt: InputMaybe<Scalars['DateTime']>;
  publicationDate_lte: InputMaybe<Scalars['DateTime']>;
  publicationDate_not: InputMaybe<Scalars['DateTime']>;
  publicationDate_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleLinkingCollections = {
  __typename: 'ArticleLinkingCollections';
  annualSustainabilityReportCollection: Maybe<AnnualSustainabilityReportCollection>;
  assemblyCareersAboutLifeAtTeliaCollection: Maybe<AssemblyCareersAboutLifeAtTeliaCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  assemblyCareersTeamsPageCollection: Maybe<AssemblyCareersTeamsPageCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel1Collection: Maybe<AssemblyLevel1Collection>;
  assemblyMenuTeasersCollection: Maybe<AssemblyMenuTeasersCollection>;
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  entryCollection: Maybe<EntryCollection>;
  eventCollection: Maybe<EventCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
  teamCardsWrapperCollection: Maybe<TeamCardsWrapperCollection>;
};

export type ArticleLinkingCollectionsAnnualSustainabilityReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleLinkingCollectionsAnnualSustainabilityReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsAssemblyCareersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyCareersTeamsPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyLevel1CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsAssemblyLevel1CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblyMenuTeasersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleLinkingCollectionsAssemblyMenuTeasersCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsAssemblySolutionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ArticleLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsEventCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsEventCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsSectionAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ArticleLinkingCollectionsTeamCardsWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleLinkingCollectionsTeamCardsWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ArticleLinkingCollectionsAnnualSustainabilityReportCollectionOrder {
  IntroPreambleAsc = 'introPreamble_ASC',
  IntroPreambleDesc = 'introPreamble_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
}

export enum ArticleLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsAssemblyCareersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export enum ArticleLinkingCollectionsAssemblyLevel1CollectionOrder {
  BottomCarouselHeadingAsc = 'bottomCarouselHeading_ASC',
  BottomCarouselHeadingDesc = 'bottomCarouselHeading_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TableToggleAsc = 'tableToggle_ASC',
  TableToggleDesc = 'tableToggle_DESC',
  TopFirstCarouselHeadingAsc = 'topFirstCarouselHeading_ASC',
  TopFirstCarouselHeadingDesc = 'topFirstCarouselHeading_DESC',
  TopSecondCarouselHeadingAsc = 'topSecondCarouselHeading_ASC',
  TopSecondCarouselHeadingDesc = 'topSecondCarouselHeading_DESC',
}

export enum ArticleLinkingCollectionsAssemblyMenuTeasersCollectionOrder {
  MenuTeasersAsc = 'menuTeasers_ASC',
  MenuTeasersDesc = 'menuTeasers_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleLinkingCollectionsAssemblySolutionsCollectionOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum ArticleLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum ArticleLinkingCollectionsEventCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ArticleLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ArticleLinkingCollectionsTeamCardsWrapperCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ArticleMediaImageCollection = {
  __typename: 'ArticleMediaImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleMediaImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ArticleMediaVideoCollection = {
  __typename: 'ArticleMediaVideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleMediaVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ArticleOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ArticleTagsCollection = {
  __typename: 'ArticleTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ArticleTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareers = Entry &
  _Node & {
    __typename: 'AssemblyCareers';
    _id: Scalars['ID'];
    additionalCardsCollection: Maybe<AssemblyCareersAdditionalCardsCollection>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyCareersLinkingCollections>;
    richPreamble: Maybe<AssemblyCareersRichPreamble>;
    seoMetadata: Maybe<Seo>;
    storyCardsCollection: Maybe<AssemblyCareersStoryCardsCollection>;
    storyCardsLink: Maybe<CtaButton>;
    storyCardsTitle: Maybe<Scalars['String']>;
    sys: Sys;
    teamCards: Maybe<TeamCardsWrapper>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersAdditionalCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersAdditionalCardsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersRichPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersStoryCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersStoryCardsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersStoryCardsLinkArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersStoryCardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareers) */
export type AssemblyCareersTeamCardsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeamCardsWrapperFilter>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTelia = Entry &
  _Node & {
    __typename: 'AssemblyCareersAboutLifeAtTelia';
    _id: Scalars['ID'];
    body: Maybe<AssemblyCareersAboutLifeAtTeliaBody>;
    bottomCardsCollection: Maybe<AssemblyCareersAboutLifeAtTeliaBottomCardsCollection>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyCareersAboutLifeAtTeliaLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
    teamCards: Maybe<TeamCardsWrapper>;
  };

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaBottomCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersAboutLifeAtTeliaBottomCardsFilter>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Assembly for about life at Telia page in careers section - /careers/about-life-at-telia [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersAboutLifeAtTelia) */
export type AssemblyCareersAboutLifeAtTeliaTeamCardsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeamCardsWrapperFilter>;
};

export type AssemblyCareersAboutLifeAtTeliaBody = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBody';
  json: Scalars['JSON'];
  links: AssemblyCareersAboutLifeAtTeliaBodyLinks;
};

export type AssemblyCareersAboutLifeAtTeliaBodyAssets = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblyCareersAboutLifeAtTeliaBodyEntries = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblyCareersAboutLifeAtTeliaBodyLinks = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyLinks';
  assets: AssemblyCareersAboutLifeAtTeliaBodyAssets;
  entries: AssemblyCareersAboutLifeAtTeliaBodyEntries;
  resources: AssemblyCareersAboutLifeAtTeliaBodyResources;
};

export type AssemblyCareersAboutLifeAtTeliaBodyResources = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyResources';
  block: Array<AssemblyCareersAboutLifeAtTeliaBodyResourcesBlock>;
  hyperlink: Array<AssemblyCareersAboutLifeAtTeliaBodyResourcesHyperlink>;
  inline: Array<AssemblyCareersAboutLifeAtTeliaBodyResourcesInline>;
};

export type AssemblyCareersAboutLifeAtTeliaBodyResourcesBlock = ResourceLink & {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyResourcesBlock';
  sys: ResourceSys;
};

export type AssemblyCareersAboutLifeAtTeliaBodyResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblyCareersAboutLifeAtTeliaBodyResourcesInline = ResourceLink & {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBodyResourcesInline';
  sys: ResourceSys;
};

export type AssemblyCareersAboutLifeAtTeliaBottomCardsCollection = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaBottomCardsCollection';
  items: Array<Maybe<AssemblyCareersAboutLifeAtTeliaBottomCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersAboutLifeAtTeliaBottomCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersAboutLifeAtTeliaBottomCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersAboutLifeAtTeliaBottomCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyCareersAboutLifeAtTeliaBottomCardsItem = Article | Teaser;

export type AssemblyCareersAboutLifeAtTeliaCollection = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaCollection';
  items: Array<Maybe<AssemblyCareersAboutLifeAtTelia>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersAboutLifeAtTeliaFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersAboutLifeAtTeliaFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersAboutLifeAtTeliaFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  bottomCards: InputMaybe<CfbottomCardsMultiTypeNestedFilter>;
  bottomCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  teamCards: InputMaybe<CfTeamCardsWrapperNestedFilter>;
  teamCards_exists: InputMaybe<Scalars['Boolean']>;
};

export type AssemblyCareersAboutLifeAtTeliaLinkingCollections = {
  __typename: 'AssemblyCareersAboutLifeAtTeliaLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyCareersAboutLifeAtTeliaLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyCareersAboutLifeAtTeliaOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyCareersAdditionalCardsCollection = {
  __typename: 'AssemblyCareersAdditionalCardsCollection';
  items: Array<Maybe<AssemblyCareersAdditionalCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersAdditionalCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersAdditionalCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersAdditionalCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyCareersAdditionalCardsItem = Article | Teaser;

export type AssemblyCareersCollection = {
  __typename: 'AssemblyCareersCollection';
  items: Array<Maybe<AssemblyCareers>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersFilter>>>;
  additionalCards: InputMaybe<CfadditionalCardsMultiTypeNestedFilter>;
  additionalCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richPreamble_contains: InputMaybe<Scalars['String']>;
  richPreamble_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_not_contains: InputMaybe<Scalars['String']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  storyCards: InputMaybe<CfstoryCardsMultiTypeNestedFilter>;
  storyCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  storyCardsLink: InputMaybe<CfCtaButtonNestedFilter>;
  storyCardsLink_exists: InputMaybe<Scalars['Boolean']>;
  storyCardsTitle: InputMaybe<Scalars['String']>;
  storyCardsTitle_contains: InputMaybe<Scalars['String']>;
  storyCardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  storyCardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  storyCardsTitle_not: InputMaybe<Scalars['String']>;
  storyCardsTitle_not_contains: InputMaybe<Scalars['String']>;
  storyCardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  teamCards: InputMaybe<CfTeamCardsWrapperNestedFilter>;
  teamCards_exists: InputMaybe<Scalars['Boolean']>;
};

export type AssemblyCareersLinkingCollections = {
  __typename: 'AssemblyCareersLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyCareersLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyCareersOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyCareersRichPreamble = {
  __typename: 'AssemblyCareersRichPreamble';
  json: Scalars['JSON'];
  links: AssemblyCareersRichPreambleLinks;
};

export type AssemblyCareersRichPreambleAssets = {
  __typename: 'AssemblyCareersRichPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblyCareersRichPreambleEntries = {
  __typename: 'AssemblyCareersRichPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblyCareersRichPreambleLinks = {
  __typename: 'AssemblyCareersRichPreambleLinks';
  assets: AssemblyCareersRichPreambleAssets;
  entries: AssemblyCareersRichPreambleEntries;
  resources: AssemblyCareersRichPreambleResources;
};

export type AssemblyCareersRichPreambleResources = {
  __typename: 'AssemblyCareersRichPreambleResources';
  block: Array<AssemblyCareersRichPreambleResourcesBlock>;
  hyperlink: Array<AssemblyCareersRichPreambleResourcesHyperlink>;
  inline: Array<AssemblyCareersRichPreambleResourcesInline>;
};

export type AssemblyCareersRichPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblyCareersRichPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblyCareersRichPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblyCareersRichPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblyCareersRichPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblyCareersRichPreambleResourcesInline';
  sys: ResourceSys;
};

export type AssemblyCareersStoryCardsCollection = {
  __typename: 'AssemblyCareersStoryCardsCollection';
  items: Array<Maybe<AssemblyCareersStoryCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersStoryCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersStoryCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersStoryCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyCareersStoryCardsItem = Article | Teaser;

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPage = Entry &
  _Node & {
    __typename: 'AssemblyCareersTeamsPage';
    _id: Scalars['ID'];
    cardsCollection: Maybe<AssemblyCareersTeamsPageCardsCollection>;
    cardsPreamble: Maybe<Scalars['String']>;
    cardsTitle: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyCareersTeamsPageLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
  };

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCareersTeamsPageCardsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageCardsPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageCardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Assembly for teams page in careers section - /careers/teams [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCareersTeamsPage) */
export type AssemblyCareersTeamsPageSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

export type AssemblyCareersTeamsPageCardsCollection = {
  __typename: 'AssemblyCareersTeamsPageCardsCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyCareersTeamsPageCardsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AssemblyCareersTeamsPageCollection = {
  __typename: 'AssemblyCareersTeamsPageCollection';
  items: Array<Maybe<AssemblyCareersTeamsPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCareersTeamsPageFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCareersTeamsPageFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCareersTeamsPageFilter>>>;
  cards: InputMaybe<CfArticleNestedFilter>;
  cardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  cardsPreamble: InputMaybe<Scalars['String']>;
  cardsPreamble_contains: InputMaybe<Scalars['String']>;
  cardsPreamble_exists: InputMaybe<Scalars['Boolean']>;
  cardsPreamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsPreamble_not: InputMaybe<Scalars['String']>;
  cardsPreamble_not_contains: InputMaybe<Scalars['String']>;
  cardsPreamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsTitle: InputMaybe<Scalars['String']>;
  cardsTitle_contains: InputMaybe<Scalars['String']>;
  cardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  cardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cardsTitle_not: InputMaybe<Scalars['String']>;
  cardsTitle_not_contains: InputMaybe<Scalars['String']>;
  cardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyCareersTeamsPageLinkingCollections = {
  __typename: 'AssemblyCareersTeamsPageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyCareersTeamsPageLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyCareersTeamsPageOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPage = Entry &
  _Node & {
    __typename: 'AssemblyCollectionPage';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<AssemblyCollectionPageDescription>;
    heroBanner: Maybe<Teaser>;
    linkedFrom: Maybe<AssemblyCollectionPageLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sectionsCollection: Maybe<AssemblyCollectionPageSectionsCollection>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageSectionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCollectionPageSectionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SectionAssemblyFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyCollectionPage) */
export type AssemblyCollectionPageSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblyCollectionPageCollection = {
  __typename: 'AssemblyCollectionPageCollection';
  items: Array<Maybe<AssemblyCollectionPage>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyCollectionPageDescription = {
  __typename: 'AssemblyCollectionPageDescription';
  json: Scalars['JSON'];
  links: AssemblyCollectionPageDescriptionLinks;
};

export type AssemblyCollectionPageDescriptionAssets = {
  __typename: 'AssemblyCollectionPageDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblyCollectionPageDescriptionEntries = {
  __typename: 'AssemblyCollectionPageDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblyCollectionPageDescriptionLinks = {
  __typename: 'AssemblyCollectionPageDescriptionLinks';
  assets: AssemblyCollectionPageDescriptionAssets;
  entries: AssemblyCollectionPageDescriptionEntries;
  resources: AssemblyCollectionPageDescriptionResources;
};

export type AssemblyCollectionPageDescriptionResources = {
  __typename: 'AssemblyCollectionPageDescriptionResources';
  block: Array<AssemblyCollectionPageDescriptionResourcesBlock>;
  hyperlink: Array<AssemblyCollectionPageDescriptionResourcesHyperlink>;
  inline: Array<AssemblyCollectionPageDescriptionResourcesInline>;
};

export type AssemblyCollectionPageDescriptionResourcesBlock = ResourceLink & {
  __typename: 'AssemblyCollectionPageDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type AssemblyCollectionPageDescriptionResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblyCollectionPageDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblyCollectionPageDescriptionResourcesInline = ResourceLink & {
  __typename: 'AssemblyCollectionPageDescriptionResourcesInline';
  sys: ResourceSys;
};

export type AssemblyCollectionPageFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyCollectionPageFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyCollectionPageFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sections: InputMaybe<CfSectionAssemblyNestedFilter>;
  sectionsCollection_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyCollectionPageLinkingCollections = {
  __typename: 'AssemblyCollectionPageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type AssemblyCollectionPageLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyCollectionPageLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyCollectionPageLinkingCollectionsSectionAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyCollectionPageLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AssemblyCollectionPageOrder {
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyCollectionPageSectionsCollection = {
  __typename: 'AssemblyCollectionPageSectionsCollection';
  items: Array<Maybe<SectionAssembly>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyCollectionPageSectionsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0 = Entry &
  _Node & {
    __typename: 'AssemblyLevel0';
    _id: Scalars['ID'];
    bottomBillboard: Maybe<Teaser>;
    bottomCardItemsCollection: Maybe<AssemblyLevel0BottomCardItemsCollection>;
    bottomCardsHeading: Maybe<Scalars['String']>;
    campaignCardsCollection: Maybe<AssemblyLevel0CampaignCardsCollection>;
    contentfulMetadata: ContentfulMetadata;
    facts: Maybe<FactBoxWrapper>;
    filteredItemsCta: Maybe<CtaButton>;
    filteredItemsHeading: Maybe<Scalars['String']>;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyLevel0LinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
    topBillboard: Maybe<Teaser>;
    topCarouselHeading: Maybe<Scalars['String']>;
    topCarouselItemsCollection: Maybe<AssemblyLevel0TopCarouselItemsCollection>;
  };

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0BottomBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0BottomCardItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel0BottomCardItemsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0BottomCardsHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0CampaignCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel0CampaignCardsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0FactsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FactBoxWrapperFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0FilteredItemsCtaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0FilteredItemsHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0HeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0InternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0LinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0SeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0TopBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0TopCarouselHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Landing Page content assembly [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel0) */
export type AssemblyLevel0TopCarouselItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel0TopCarouselItemsFilter>;
};

export type AssemblyLevel0BottomCardItemsCollection = {
  __typename: 'AssemblyLevel0BottomCardItemsCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyLevel0BottomCardItemsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AssemblyLevel0CampaignCardsCollection = {
  __typename: 'AssemblyLevel0CampaignCardsCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyLevel0CampaignCardsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblyLevel0Collection = {
  __typename: 'AssemblyLevel0Collection';
  items: Array<Maybe<AssemblyLevel0>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel0Filter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel0Filter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel0Filter>>>;
  bottomBillboard: InputMaybe<CfTeaserNestedFilter>;
  bottomBillboard_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardItems: InputMaybe<CfArticleNestedFilter>;
  bottomCardItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsHeading: InputMaybe<Scalars['String']>;
  bottomCardsHeading_contains: InputMaybe<Scalars['String']>;
  bottomCardsHeading_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomCardsHeading_not: InputMaybe<Scalars['String']>;
  bottomCardsHeading_not_contains: InputMaybe<Scalars['String']>;
  bottomCardsHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  campaignCards: InputMaybe<CfTeaserNestedFilter>;
  campaignCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  facts: InputMaybe<CfFactBoxWrapperNestedFilter>;
  facts_exists: InputMaybe<Scalars['Boolean']>;
  filteredItemsCta: InputMaybe<CfCtaButtonNestedFilter>;
  filteredItemsCta_exists: InputMaybe<Scalars['Boolean']>;
  filteredItemsHeading: InputMaybe<Scalars['String']>;
  filteredItemsHeading_contains: InputMaybe<Scalars['String']>;
  filteredItemsHeading_exists: InputMaybe<Scalars['Boolean']>;
  filteredItemsHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filteredItemsHeading_not: InputMaybe<Scalars['String']>;
  filteredItemsHeading_not_contains: InputMaybe<Scalars['String']>;
  filteredItemsHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  topBillboard: InputMaybe<CfTeaserNestedFilter>;
  topBillboard_exists: InputMaybe<Scalars['Boolean']>;
  topCarouselHeading: InputMaybe<Scalars['String']>;
  topCarouselHeading_contains: InputMaybe<Scalars['String']>;
  topCarouselHeading_exists: InputMaybe<Scalars['Boolean']>;
  topCarouselHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topCarouselHeading_not: InputMaybe<Scalars['String']>;
  topCarouselHeading_not_contains: InputMaybe<Scalars['String']>;
  topCarouselHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topCarouselItems: InputMaybe<CftopCarouselItemsMultiTypeNestedFilter>;
  topCarouselItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type AssemblyLevel0LinkingCollections = {
  __typename: 'AssemblyLevel0LinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type AssemblyLevel0LinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel0LinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel0LinkingCollectionsSectionAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyLevel0LinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AssemblyLevel0Order {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export type AssemblyLevel0TopCarouselItemsCollection = {
  __typename: 'AssemblyLevel0TopCarouselItemsCollection';
  items: Array<Maybe<AssemblyLevel0TopCarouselItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel0TopCarouselItemsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel0TopCarouselItemsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel0TopCarouselItemsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyLevel0TopCarouselItemsItem = Article | Teaser;

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1 = Entry &
  _Node & {
    __typename: 'AssemblyLevel1';
    _id: Scalars['ID'];
    billboard: Maybe<Teaser> | null;
    bottomCarouselCollection: Maybe<AssemblyLevel1BottomCarouselCollection>;
    bottomCarouselHeading: Maybe<Scalars['String']> | null;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    linkedFrom: Maybe<AssemblyLevel1LinkingCollections>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    tableCollection: Maybe<AssemblyLevel1TableCollection>;
    tableCta: Maybe<CtaButton>;
    tableHeading: Maybe<Scalars['String']>;
    tableToggle: Maybe<Scalars['Boolean']>;
    topFirstCarouselHeading: Maybe<Scalars['String']>;
    topFirstCarouselItemsCollection: Maybe<AssemblyLevel1TopFirstCarouselItemsCollection>;
    topSecondCarouselHeading: Maybe<Scalars['String']> | null;
    topSecondCarouselItemsCollection: Maybe<AssemblyLevel1TopSecondCarouselItemsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1BillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1BottomCarouselCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel1BottomCarouselFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1BottomCarouselHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1HeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1LinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1SeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1SlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TableCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel1TableCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TableCtaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TableHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TableToggleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TopFirstCarouselHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TopFirstCarouselItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel1TopFirstCarouselItemsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TopSecondCarouselHeadingArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyLevel1) */
export type AssemblyLevel1TopSecondCarouselItemsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel1TopSecondCarouselItemsFilter>;
};

export type AssemblyLevel1BottomCarouselCollection = {
  __typename: 'AssemblyLevel1BottomCarouselCollection';
  items: Array<Maybe<AssemblyLevel1BottomCarouselItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel1BottomCarouselFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel1BottomCarouselFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel1BottomCarouselFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyLevel1BottomCarouselItem = Article | Teaser;

export type AssemblyLevel1Collection = {
  __typename: 'AssemblyLevel1Collection';
  items: Array<Maybe<AssemblyLevel1>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel1Filter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel1Filter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel1Filter>>>;
  billboard: InputMaybe<CfTeaserNestedFilter>;
  billboard_exists: InputMaybe<Scalars['Boolean']>;
  bottomCarousel: InputMaybe<CfbottomCarouselMultiTypeNestedFilter>;
  bottomCarouselCollection_exists: InputMaybe<Scalars['Boolean']>;
  bottomCarouselHeading: InputMaybe<Scalars['String']>;
  bottomCarouselHeading_contains: InputMaybe<Scalars['String']>;
  bottomCarouselHeading_exists: InputMaybe<Scalars['Boolean']>;
  bottomCarouselHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomCarouselHeading_not: InputMaybe<Scalars['String']>;
  bottomCarouselHeading_not_contains: InputMaybe<Scalars['String']>;
  bottomCarouselHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  table: InputMaybe<CfArticleNestedFilter>;
  tableCollection_exists: InputMaybe<Scalars['Boolean']>;
  tableCta: InputMaybe<CfCtaButtonNestedFilter>;
  tableCta_exists: InputMaybe<Scalars['Boolean']>;
  tableHeading: InputMaybe<Scalars['String']>;
  tableHeading_contains: InputMaybe<Scalars['String']>;
  tableHeading_exists: InputMaybe<Scalars['Boolean']>;
  tableHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tableHeading_not: InputMaybe<Scalars['String']>;
  tableHeading_not_contains: InputMaybe<Scalars['String']>;
  tableHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tableToggle: InputMaybe<Scalars['Boolean']>;
  tableToggle_exists: InputMaybe<Scalars['Boolean']>;
  tableToggle_not: InputMaybe<Scalars['Boolean']>;
  topFirstCarouselHeading: InputMaybe<Scalars['String']>;
  topFirstCarouselHeading_contains: InputMaybe<Scalars['String']>;
  topFirstCarouselHeading_exists: InputMaybe<Scalars['Boolean']>;
  topFirstCarouselHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topFirstCarouselHeading_not: InputMaybe<Scalars['String']>;
  topFirstCarouselHeading_not_contains: InputMaybe<Scalars['String']>;
  topFirstCarouselHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topFirstCarouselItems: InputMaybe<CftopFirstCarouselItemsMultiTypeNestedFilter>;
  topFirstCarouselItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
  topSecondCarouselHeading: InputMaybe<Scalars['String']>;
  topSecondCarouselHeading_contains: InputMaybe<Scalars['String']>;
  topSecondCarouselHeading_exists: InputMaybe<Scalars['Boolean']>;
  topSecondCarouselHeading_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topSecondCarouselHeading_not: InputMaybe<Scalars['String']>;
  topSecondCarouselHeading_not_contains: InputMaybe<Scalars['String']>;
  topSecondCarouselHeading_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topSecondCarouselItems: InputMaybe<CftopSecondCarouselItemsMultiTypeNestedFilter>;
  topSecondCarouselItemsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type AssemblyLevel1LinkingCollections = {
  __typename: 'AssemblyLevel1LinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type AssemblyLevel1LinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyLevel1LinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblyLevel1LinkingCollectionsSectionAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyLevel1LinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AssemblyLevel1Order {
  BottomCarouselHeadingAsc = 'bottomCarouselHeading_ASC',
  BottomCarouselHeadingDesc = 'bottomCarouselHeading_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TableToggleAsc = 'tableToggle_ASC',
  TableToggleDesc = 'tableToggle_DESC',
  TopFirstCarouselHeadingAsc = 'topFirstCarouselHeading_ASC',
  TopFirstCarouselHeadingDesc = 'topFirstCarouselHeading_DESC',
  TopSecondCarouselHeadingAsc = 'topSecondCarouselHeading_ASC',
  TopSecondCarouselHeadingDesc = 'topSecondCarouselHeading_DESC',
}

export type AssemblyLevel1TableCollection = {
  __typename: 'AssemblyLevel1TableCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyLevel1TableCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AssemblyLevel1TopFirstCarouselItemsCollection = {
  __typename: 'AssemblyLevel1TopFirstCarouselItemsCollection';
  items: Array<Maybe<AssemblyLevel1TopFirstCarouselItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel1TopFirstCarouselItemsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel1TopFirstCarouselItemsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel1TopFirstCarouselItemsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyLevel1TopFirstCarouselItemsItem = Article | Teaser;

export type AssemblyLevel1TopSecondCarouselItemsCollection = {
  __typename: 'AssemblyLevel1TopSecondCarouselItemsCollection';
  items: Array<Maybe<AssemblyLevel1TopSecondCarouselItemsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyLevel1TopSecondCarouselItemsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyLevel1TopSecondCarouselItemsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyLevel1TopSecondCarouselItemsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyLevel1TopSecondCarouselItemsItem = Article | Teaser;

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasers = Entry &
  _Node & {
    __typename: 'AssemblyMenuTeasers';
    _id: Scalars['ID'];
    aboutTheCompany: Maybe<AssemblyMenuTeasersAboutTheCompany>;
    careers: Maybe<AssemblyMenuTeasersCareers>;
    contactUs: Maybe<AssemblyMenuTeasersContactUs>;
    contentfulMetadata: ContentfulMetadata;
    investors: Maybe<AssemblyMenuTeasersInvestors>;
    linkedFrom: Maybe<AssemblyMenuTeasersLinkingCollections>;
    menuTeasers: Maybe<Scalars['String']>;
    newsroom: Maybe<AssemblyMenuTeasersNewsroom>;
    solutions: Maybe<AssemblyMenuTeasersSolutions>;
    sustainability: Maybe<AssemblyMenuTeasersSustainability>;
    sys: Sys;
  };

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersAboutTheCompanyArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersAboutTheCompanyFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersCareersArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersCareersFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersContactUsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersContactUsFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersInvestorsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersInvestorsFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersMenuTeasersArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersNewsroomArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersNewsroomFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersSolutionsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersSolutionsFilter>;
};

/** Used to populate teasers in the Mega menu sections [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyMenuTeasers) */
export type AssemblyMenuTeasersSustainabilityArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssemblyMenuTeasersSustainabilityFilter>;
};

export type AssemblyMenuTeasersAboutTheCompany = Article | Teaser;

export type AssemblyMenuTeasersAboutTheCompanyFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersAboutTheCompanyFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersAboutTheCompanyFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyMenuTeasersCareers = Article | Teaser;

export type AssemblyMenuTeasersCareersFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersCareersFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersCareersFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyMenuTeasersCollection = {
  __typename: 'AssemblyMenuTeasersCollection';
  items: Array<Maybe<AssemblyMenuTeasers>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyMenuTeasersContactUs = Article | Teaser;

export type AssemblyMenuTeasersContactUsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersContactUsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersContactUsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyMenuTeasersFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersFilter>>>;
  aboutTheCompany: InputMaybe<CfaboutTheCompanyMultiTypeNestedFilter>;
  aboutTheCompany_exists: InputMaybe<Scalars['Boolean']>;
  careers: InputMaybe<CfcareersMultiTypeNestedFilter>;
  careers_exists: InputMaybe<Scalars['Boolean']>;
  contactUs: InputMaybe<CfcontactUsMultiTypeNestedFilter>;
  contactUs_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  investors: InputMaybe<CfinvestorsMultiTypeNestedFilter>;
  investors_exists: InputMaybe<Scalars['Boolean']>;
  menuTeasers: InputMaybe<Scalars['String']>;
  menuTeasers_contains: InputMaybe<Scalars['String']>;
  menuTeasers_exists: InputMaybe<Scalars['Boolean']>;
  menuTeasers_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  menuTeasers_not: InputMaybe<Scalars['String']>;
  menuTeasers_not_contains: InputMaybe<Scalars['String']>;
  menuTeasers_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  newsroom: InputMaybe<CfnewsroomMultiTypeNestedFilter>;
  newsroom_exists: InputMaybe<Scalars['Boolean']>;
  solutions: InputMaybe<CfsolutionsMultiTypeNestedFilter>;
  solutions_exists: InputMaybe<Scalars['Boolean']>;
  sustainability: InputMaybe<CfsustainabilityMultiTypeNestedFilter>;
  sustainability_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyMenuTeasersInvestors = Article | Teaser;

export type AssemblyMenuTeasersInvestorsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersInvestorsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersInvestorsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyMenuTeasersLinkingCollections = {
  __typename: 'AssemblyMenuTeasersLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyMenuTeasersLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyMenuTeasersNewsroom = Article | Teaser;

export type AssemblyMenuTeasersNewsroomFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersNewsroomFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersNewsroomFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum AssemblyMenuTeasersOrder {
  MenuTeasersAsc = 'menuTeasers_ASC',
  MenuTeasersDesc = 'menuTeasers_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AssemblyMenuTeasersSolutions = Article | Teaser;

export type AssemblyMenuTeasersSolutionsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersSolutionsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersSolutionsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblyMenuTeasersSustainability = Article | Teaser;

export type AssemblyMenuTeasersSustainabilityFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersSustainabilityFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersSustainabilityFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Open positions page for job ads [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyOpenPositions) */
export type AssemblyOpenPositions = Entry &
  _Node & {
    __typename: 'AssemblyOpenPositions';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyOpenPositionsLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
  };

/** Open positions page for job ads [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyOpenPositions) */
export type AssemblyOpenPositionsHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Open positions page for job ads [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyOpenPositions) */
export type AssemblyOpenPositionsInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Open positions page for job ads [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyOpenPositions) */
export type AssemblyOpenPositionsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Open positions page for job ads [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyOpenPositions) */
export type AssemblyOpenPositionsSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

export type AssemblyOpenPositionsCollection = {
  __typename: 'AssemblyOpenPositionsCollection';
  items: Array<Maybe<AssemblyOpenPositions>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyOpenPositionsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyOpenPositionsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyOpenPositionsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type AssemblyOpenPositionsLinkingCollections = {
  __typename: 'AssemblyOpenPositionsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblyOpenPositionsLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyOpenPositionsOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentations = Entry &
  _Node & {
    __typename: 'AssemblyReportsAndPresentations';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    eventsCollection: Maybe<AssemblyReportsAndPresentationsEventsCollection>;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblyReportsAndPresentationsLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
    year: Maybe<Scalars['Int']>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsEventsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyReportsAndPresentationsEventsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EventFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblyReportsAndPresentations) */
export type AssemblyReportsAndPresentationsYearArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblyReportsAndPresentationsCollection = {
  __typename: 'AssemblyReportsAndPresentationsCollection';
  items: Array<Maybe<AssemblyReportsAndPresentations>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblyReportsAndPresentationsEventsCollection = {
  __typename: 'AssemblyReportsAndPresentationsEventsCollection';
  items: Array<Maybe<Event>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblyReportsAndPresentationsEventsCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AssemblyReportsAndPresentationsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblyReportsAndPresentationsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblyReportsAndPresentationsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  events: InputMaybe<CfEventNestedFilter>;
  eventsCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  year: InputMaybe<Scalars['Int']>;
  year_exists: InputMaybe<Scalars['Boolean']>;
  year_gt: InputMaybe<Scalars['Int']>;
  year_gte: InputMaybe<Scalars['Int']>;
  year_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  year_lt: InputMaybe<Scalars['Int']>;
  year_lte: InputMaybe<Scalars['Int']>;
  year_not: InputMaybe<Scalars['Int']>;
  year_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssemblyReportsAndPresentationsLinkingCollections = {
  __typename: 'AssemblyReportsAndPresentationsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type AssemblyReportsAndPresentationsLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblyReportsAndPresentationsLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<
      InputMaybe<AssemblyReportsAndPresentationsLinkingCollectionsSectionAssemblyCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblyReportsAndPresentationsLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AssemblyReportsAndPresentationsOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
}

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutions = Entry &
  _Node & {
    __typename: 'AssemblySolutions';
    _id: Scalars['ID'];
    alternatingTeasersCollection: Maybe<AssemblySolutionsAlternatingTeasersCollection>;
    alternatingTeasersCtaLink: Maybe<CtaButton>;
    alternatingTeasersTitle: Maybe<Scalars['String']>;
    bottomCardsCollection: Maybe<AssemblySolutionsBottomCardsCollection>;
    bottomCardsPreamble: Maybe<AssemblySolutionsBottomCardsPreamble>;
    bottomCardsTitle: Maybe<Scalars['String']>;
    bottomPromotionCard: Maybe<Teaser>;
    bottomPromotionCardType: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    introSectionCarouselCollection: Maybe<AssemblySolutionsIntroSectionCarouselCollection>;
    introSectionCtaLink: Maybe<CtaButton>;
    introSectionTitle: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblySolutionsLinkingCollections>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    uspCardsCollection: Maybe<AssemblySolutionsUspCardsCollection>;
    uspCardsCtaLink: Maybe<CtaButton>;
    uspCardsPreamble: Maybe<AssemblySolutionsUspCardsPreamble>;
    uspCardsTitle: Maybe<Scalars['String']>;
    uspIconsCollection: Maybe<AssemblySolutionsUspIconsCollection>;
    uspPreamble: Maybe<AssemblySolutionsUspPreamble>;
    uspTitle: Maybe<Scalars['String']>;
  };

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsAlternatingTeasersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsAlternatingTeasersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsAlternatingTeasersCtaLinkArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsAlternatingTeasersTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsBottomCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblySolutionsBottomCardsFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsBottomCardsPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsBottomCardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsBottomPromotionCardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsBottomPromotionCardTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsIntroSectionCarouselCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsIntroSectionCarouselCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsIntroSectionCtaLinkArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsIntroSectionTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblySolutionsUspCardsFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspCardsCtaLinkArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspCardsPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspCardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspIconsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsUspIconsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityFilter>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Global business level 1 template [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutions) */
export type AssemblySolutionsUspTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblySolutionsAlternatingTeasersCollection = {
  __typename: 'AssemblySolutionsAlternatingTeasersCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblySolutionsAlternatingTeasersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblySolutionsBottomCardsCollection = {
  __typename: 'AssemblySolutionsBottomCardsCollection';
  items: Array<Maybe<AssemblySolutionsBottomCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblySolutionsBottomCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblySolutionsBottomCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblySolutionsBottomCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblySolutionsBottomCardsItem = Article | Teaser;

export type AssemblySolutionsBottomCardsPreamble = {
  __typename: 'AssemblySolutionsBottomCardsPreamble';
  json: Scalars['JSON'];
  links: AssemblySolutionsBottomCardsPreambleLinks;
};

export type AssemblySolutionsBottomCardsPreambleAssets = {
  __typename: 'AssemblySolutionsBottomCardsPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsBottomCardsPreambleEntries = {
  __typename: 'AssemblySolutionsBottomCardsPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsBottomCardsPreambleLinks = {
  __typename: 'AssemblySolutionsBottomCardsPreambleLinks';
  assets: AssemblySolutionsBottomCardsPreambleAssets;
  entries: AssemblySolutionsBottomCardsPreambleEntries;
  resources: AssemblySolutionsBottomCardsPreambleResources;
};

export type AssemblySolutionsBottomCardsPreambleResources = {
  __typename: 'AssemblySolutionsBottomCardsPreambleResources';
  block: Array<AssemblySolutionsBottomCardsPreambleResourcesBlock>;
  hyperlink: Array<AssemblySolutionsBottomCardsPreambleResourcesHyperlink>;
  inline: Array<AssemblySolutionsBottomCardsPreambleResourcesInline>;
};

export type AssemblySolutionsBottomCardsPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsBottomCardsPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsBottomCardsPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsBottomCardsPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsBottomCardsPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsBottomCardsPreambleResourcesInline';
  sys: ResourceSys;
};

export type AssemblySolutionsCollection = {
  __typename: 'AssemblySolutionsCollection';
  items: Array<Maybe<AssemblySolutions>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblySolutionsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblySolutionsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblySolutionsFilter>>>;
  alternatingTeasers: InputMaybe<CfTeaserNestedFilter>;
  alternatingTeasersCollection_exists: InputMaybe<Scalars['Boolean']>;
  alternatingTeasersCtaLink: InputMaybe<CfCtaButtonNestedFilter>;
  alternatingTeasersCtaLink_exists: InputMaybe<Scalars['Boolean']>;
  alternatingTeasersTitle: InputMaybe<Scalars['String']>;
  alternatingTeasersTitle_contains: InputMaybe<Scalars['String']>;
  alternatingTeasersTitle_exists: InputMaybe<Scalars['Boolean']>;
  alternatingTeasersTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  alternatingTeasersTitle_not: InputMaybe<Scalars['String']>;
  alternatingTeasersTitle_not_contains: InputMaybe<Scalars['String']>;
  alternatingTeasersTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomCards: InputMaybe<CfbottomCardsMultiTypeNestedFilter>;
  bottomCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsPreamble_contains: InputMaybe<Scalars['String']>;
  bottomCardsPreamble_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsPreamble_not_contains: InputMaybe<Scalars['String']>;
  bottomCardsTitle: InputMaybe<Scalars['String']>;
  bottomCardsTitle_contains: InputMaybe<Scalars['String']>;
  bottomCardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  bottomCardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomCardsTitle_not: InputMaybe<Scalars['String']>;
  bottomCardsTitle_not_contains: InputMaybe<Scalars['String']>;
  bottomCardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCard: InputMaybe<CfTeaserNestedFilter>;
  bottomPromotionCardType: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_exists: InputMaybe<Scalars['Boolean']>;
  bottomPromotionCardType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCardType_not: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_not_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCard_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  introSectionCarousel: InputMaybe<CfTeaserNestedFilter>;
  introSectionCarouselCollection_exists: InputMaybe<Scalars['Boolean']>;
  introSectionCtaLink: InputMaybe<CfCtaButtonNestedFilter>;
  introSectionCtaLink_exists: InputMaybe<Scalars['Boolean']>;
  introSectionTitle: InputMaybe<Scalars['String']>;
  introSectionTitle_contains: InputMaybe<Scalars['String']>;
  introSectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  introSectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introSectionTitle_not: InputMaybe<Scalars['String']>;
  introSectionTitle_not_contains: InputMaybe<Scalars['String']>;
  introSectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  uspCards: InputMaybe<CfuspCardsMultiTypeNestedFilter>;
  uspCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  uspCardsCtaLink: InputMaybe<CfCtaButtonNestedFilter>;
  uspCardsCtaLink_exists: InputMaybe<Scalars['Boolean']>;
  uspCardsPreamble_contains: InputMaybe<Scalars['String']>;
  uspCardsPreamble_exists: InputMaybe<Scalars['Boolean']>;
  uspCardsPreamble_not_contains: InputMaybe<Scalars['String']>;
  uspCardsTitle: InputMaybe<Scalars['String']>;
  uspCardsTitle_contains: InputMaybe<Scalars['String']>;
  uspCardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  uspCardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspCardsTitle_not: InputMaybe<Scalars['String']>;
  uspCardsTitle_not_contains: InputMaybe<Scalars['String']>;
  uspCardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspIcons: InputMaybe<CfUspEntityNestedFilter>;
  uspIconsCollection_exists: InputMaybe<Scalars['Boolean']>;
  uspPreamble_contains: InputMaybe<Scalars['String']>;
  uspPreamble_exists: InputMaybe<Scalars['Boolean']>;
  uspPreamble_not_contains: InputMaybe<Scalars['String']>;
  uspTitle: InputMaybe<Scalars['String']>;
  uspTitle_contains: InputMaybe<Scalars['String']>;
  uspTitle_exists: InputMaybe<Scalars['Boolean']>;
  uspTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspTitle_not: InputMaybe<Scalars['String']>;
  uspTitle_not_contains: InputMaybe<Scalars['String']>;
  uspTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexible = Entry &
  _Node & {
    __typename: 'AssemblySolutionsFlexible';
    _id: Scalars['ID'];
    alternatingTeasersAnchorTitle: Maybe<Scalars['String']>;
    alternatingTeasersCollection: Maybe<AssemblySolutionsFlexibleAlternatingTeasersCollection>;
    bottomAccordionfaqSection: Maybe<FaqSection>;
    bottomAccordionfaqSectionAnchorTitle: Maybe<Scalars['String']>;
    bottomPromotionCard: Maybe<Teaser>;
    bottomPromotionCardAnchorTitle: Maybe<Scalars['String']>;
    bottomPromotionCardType: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    introSectionAnchorTitle: Maybe<Scalars['String']>;
    introSectionMedia: Maybe<AssemblySolutionsFlexibleIntroSectionMedia>;
    introSectionPreamble: Maybe<AssemblySolutionsFlexibleIntroSectionPreamble>;
    introSectionTitle: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AssemblySolutionsFlexibleLinkingCollections>;
    midFaqSection: Maybe<FaqSection>;
    midFaqSectionAnchorTitle: Maybe<Scalars['String']>;
    midSectionTitleWithMedia: Maybe<Teaser>;
    midSectionTitleWithMediaAnchorTitle: Maybe<Scalars['String']>;
    miniParagraphs: Maybe<FaqSection>;
    miniParagraphsAnchorTitle: Maybe<Scalars['String']>;
    richtextSection: Maybe<AssemblySolutionsFlexibleRichtextSection>;
    richtextSectionAnchorTitle: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    topCardsAnchorTitle: Maybe<Scalars['String']>;
    topCardsCollection: Maybe<AssemblySolutionsFlexibleTopCardsCollection>;
    topCardsTitle: Maybe<Scalars['String']>;
    uspAnchorTitle: Maybe<Scalars['String']>;
    uspIconsCollection: Maybe<AssemblySolutionsFlexibleUspIconsCollection>;
    uspPreamble: Maybe<AssemblySolutionsFlexibleUspPreamble>;
    uspTitle: Maybe<Scalars['String']>;
  };

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleAlternatingTeasersAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleAlternatingTeasersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleAlternatingTeasersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleBottomAccordionfaqSectionArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleBottomAccordionfaqSectionAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleBottomPromotionCardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleBottomPromotionCardAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleBottomPromotionCardTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleIntroSectionAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleIntroSectionMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleIntroSectionPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleIntroSectionTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMidFaqSectionArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMidFaqSectionAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMidSectionTitleWithMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMidSectionTitleWithMediaAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMiniParagraphsArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleMiniParagraphsAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleRichtextSectionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleRichtextSectionAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleTopCardsAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleTopCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblySolutionsFlexibleTopCardsFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleTopCardsTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleUspAnchorTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleUspIconsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleUspIconsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityFilter>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleUspPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Flexible template with a lot of optional fields [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/assemblySolutionsFlexible) */
export type AssemblySolutionsFlexibleUspTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssemblySolutionsFlexibleAlternatingTeasersCollection = {
  __typename: 'AssemblySolutionsFlexibleAlternatingTeasersCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblySolutionsFlexibleAlternatingTeasersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblySolutionsFlexibleCollection = {
  __typename: 'AssemblySolutionsFlexibleCollection';
  items: Array<Maybe<AssemblySolutionsFlexible>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblySolutionsFlexibleFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleFilter>>>;
  alternatingTeasers: InputMaybe<CfTeaserNestedFilter>;
  alternatingTeasersAnchorTitle: InputMaybe<Scalars['String']>;
  alternatingTeasersAnchorTitle_contains: InputMaybe<Scalars['String']>;
  alternatingTeasersAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  alternatingTeasersAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  alternatingTeasersAnchorTitle_not: InputMaybe<Scalars['String']>;
  alternatingTeasersAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  alternatingTeasersAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  alternatingTeasersCollection_exists: InputMaybe<Scalars['Boolean']>;
  bottomAccordionfaqSection: InputMaybe<CfFaqSectionNestedFilter>;
  bottomAccordionfaqSectionAnchorTitle: InputMaybe<Scalars['String']>;
  bottomAccordionfaqSectionAnchorTitle_contains: InputMaybe<Scalars['String']>;
  bottomAccordionfaqSectionAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  bottomAccordionfaqSectionAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomAccordionfaqSectionAnchorTitle_not: InputMaybe<Scalars['String']>;
  bottomAccordionfaqSectionAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  bottomAccordionfaqSectionAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomAccordionfaqSection_exists: InputMaybe<Scalars['Boolean']>;
  bottomPromotionCard: InputMaybe<CfTeaserNestedFilter>;
  bottomPromotionCardAnchorTitle: InputMaybe<Scalars['String']>;
  bottomPromotionCardAnchorTitle_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  bottomPromotionCardAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCardAnchorTitle_not: InputMaybe<Scalars['String']>;
  bottomPromotionCardAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCardType: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_exists: InputMaybe<Scalars['Boolean']>;
  bottomPromotionCardType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCardType_not: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_not_contains: InputMaybe<Scalars['String']>;
  bottomPromotionCardType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bottomPromotionCard_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  introSectionAnchorTitle: InputMaybe<Scalars['String']>;
  introSectionAnchorTitle_contains: InputMaybe<Scalars['String']>;
  introSectionAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  introSectionAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introSectionAnchorTitle_not: InputMaybe<Scalars['String']>;
  introSectionAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  introSectionAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introSectionMedia_exists: InputMaybe<Scalars['Boolean']>;
  introSectionPreamble_contains: InputMaybe<Scalars['String']>;
  introSectionPreamble_exists: InputMaybe<Scalars['Boolean']>;
  introSectionPreamble_not_contains: InputMaybe<Scalars['String']>;
  introSectionTitle: InputMaybe<Scalars['String']>;
  introSectionTitle_contains: InputMaybe<Scalars['String']>;
  introSectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  introSectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introSectionTitle_not: InputMaybe<Scalars['String']>;
  introSectionTitle_not_contains: InputMaybe<Scalars['String']>;
  introSectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  midFaqSection: InputMaybe<CfFaqSectionNestedFilter>;
  midFaqSectionAnchorTitle: InputMaybe<Scalars['String']>;
  midFaqSectionAnchorTitle_contains: InputMaybe<Scalars['String']>;
  midFaqSectionAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  midFaqSectionAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  midFaqSectionAnchorTitle_not: InputMaybe<Scalars['String']>;
  midFaqSectionAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  midFaqSectionAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  midFaqSection_exists: InputMaybe<Scalars['Boolean']>;
  midSectionTitleWithMedia: InputMaybe<CfTeaserNestedFilter>;
  midSectionTitleWithMediaAnchorTitle: InputMaybe<Scalars['String']>;
  midSectionTitleWithMediaAnchorTitle_contains: InputMaybe<Scalars['String']>;
  midSectionTitleWithMediaAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  midSectionTitleWithMediaAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  midSectionTitleWithMediaAnchorTitle_not: InputMaybe<Scalars['String']>;
  midSectionTitleWithMediaAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  midSectionTitleWithMediaAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  midSectionTitleWithMedia_exists: InputMaybe<Scalars['Boolean']>;
  miniParagraphs: InputMaybe<CfFaqSectionNestedFilter>;
  miniParagraphsAnchorTitle: InputMaybe<Scalars['String']>;
  miniParagraphsAnchorTitle_contains: InputMaybe<Scalars['String']>;
  miniParagraphsAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  miniParagraphsAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  miniParagraphsAnchorTitle_not: InputMaybe<Scalars['String']>;
  miniParagraphsAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  miniParagraphsAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  miniParagraphs_exists: InputMaybe<Scalars['Boolean']>;
  richtextSectionAnchorTitle: InputMaybe<Scalars['String']>;
  richtextSectionAnchorTitle_contains: InputMaybe<Scalars['String']>;
  richtextSectionAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  richtextSectionAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richtextSectionAnchorTitle_not: InputMaybe<Scalars['String']>;
  richtextSectionAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  richtextSectionAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richtextSection_contains: InputMaybe<Scalars['String']>;
  richtextSection_exists: InputMaybe<Scalars['Boolean']>;
  richtextSection_not_contains: InputMaybe<Scalars['String']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  topCards: InputMaybe<CftopCardsMultiTypeNestedFilter>;
  topCardsAnchorTitle: InputMaybe<Scalars['String']>;
  topCardsAnchorTitle_contains: InputMaybe<Scalars['String']>;
  topCardsAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  topCardsAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topCardsAnchorTitle_not: InputMaybe<Scalars['String']>;
  topCardsAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  topCardsAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topCardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  topCardsTitle: InputMaybe<Scalars['String']>;
  topCardsTitle_contains: InputMaybe<Scalars['String']>;
  topCardsTitle_exists: InputMaybe<Scalars['Boolean']>;
  topCardsTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  topCardsTitle_not: InputMaybe<Scalars['String']>;
  topCardsTitle_not_contains: InputMaybe<Scalars['String']>;
  topCardsTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspAnchorTitle: InputMaybe<Scalars['String']>;
  uspAnchorTitle_contains: InputMaybe<Scalars['String']>;
  uspAnchorTitle_exists: InputMaybe<Scalars['Boolean']>;
  uspAnchorTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspAnchorTitle_not: InputMaybe<Scalars['String']>;
  uspAnchorTitle_not_contains: InputMaybe<Scalars['String']>;
  uspAnchorTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspIcons: InputMaybe<CfUspEntityNestedFilter>;
  uspIconsCollection_exists: InputMaybe<Scalars['Boolean']>;
  uspPreamble_contains: InputMaybe<Scalars['String']>;
  uspPreamble_exists: InputMaybe<Scalars['Boolean']>;
  uspPreamble_not_contains: InputMaybe<Scalars['String']>;
  uspTitle: InputMaybe<Scalars['String']>;
  uspTitle_contains: InputMaybe<Scalars['String']>;
  uspTitle_exists: InputMaybe<Scalars['Boolean']>;
  uspTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uspTitle_not: InputMaybe<Scalars['String']>;
  uspTitle_not_contains: InputMaybe<Scalars['String']>;
  uspTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblySolutionsFlexibleIntroSectionMedia = Image | Video;

export type AssemblySolutionsFlexibleIntroSectionPreamble = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreamble';
  json: Scalars['JSON'];
  links: AssemblySolutionsFlexibleIntroSectionPreambleLinks;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleAssets = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleEntries = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleLinks = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleLinks';
  assets: AssemblySolutionsFlexibleIntroSectionPreambleAssets;
  entries: AssemblySolutionsFlexibleIntroSectionPreambleEntries;
  resources: AssemblySolutionsFlexibleIntroSectionPreambleResources;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleResources = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleResources';
  block: Array<AssemblySolutionsFlexibleIntroSectionPreambleResourcesBlock>;
  hyperlink: Array<AssemblySolutionsFlexibleIntroSectionPreambleResourcesHyperlink>;
  inline: Array<AssemblySolutionsFlexibleIntroSectionPreambleResourcesInline>;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleIntroSectionPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleResourcesInline';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleLinkingCollections = {
  __typename: 'AssemblySolutionsFlexibleLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type AssemblySolutionsFlexibleLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssemblySolutionsFlexibleLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<AssemblySolutionsFlexibleLinkingCollectionsSectionAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblySolutionsFlexibleLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AssemblySolutionsFlexibleOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export type AssemblySolutionsFlexibleRichtextSection = {
  __typename: 'AssemblySolutionsFlexibleRichtextSection';
  json: Scalars['JSON'];
  links: AssemblySolutionsFlexibleRichtextSectionLinks;
};

export type AssemblySolutionsFlexibleRichtextSectionAssets = {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsFlexibleRichtextSectionEntries = {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsFlexibleRichtextSectionLinks = {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionLinks';
  assets: AssemblySolutionsFlexibleRichtextSectionAssets;
  entries: AssemblySolutionsFlexibleRichtextSectionEntries;
  resources: AssemblySolutionsFlexibleRichtextSectionResources;
};

export type AssemblySolutionsFlexibleRichtextSectionResources = {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionResources';
  block: Array<AssemblySolutionsFlexibleRichtextSectionResourcesBlock>;
  hyperlink: Array<AssemblySolutionsFlexibleRichtextSectionResourcesHyperlink>;
  inline: Array<AssemblySolutionsFlexibleRichtextSectionResourcesInline>;
};

export type AssemblySolutionsFlexibleRichtextSectionResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleRichtextSectionResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleRichtextSectionResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleRichtextSectionResourcesInline';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleTopCardsCollection = {
  __typename: 'AssemblySolutionsFlexibleTopCardsCollection';
  items: Array<Maybe<AssemblySolutionsFlexibleTopCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblySolutionsFlexibleTopCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleTopCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleTopCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblySolutionsFlexibleTopCardsItem = Article | Teaser;

export type AssemblySolutionsFlexibleUspIconsCollection = {
  __typename: 'AssemblySolutionsFlexibleUspIconsCollection';
  items: Array<Maybe<UspEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblySolutionsFlexibleUspIconsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblySolutionsFlexibleUspPreamble = {
  __typename: 'AssemblySolutionsFlexibleUspPreamble';
  json: Scalars['JSON'];
  links: AssemblySolutionsFlexibleUspPreambleLinks;
};

export type AssemblySolutionsFlexibleUspPreambleAssets = {
  __typename: 'AssemblySolutionsFlexibleUspPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsFlexibleUspPreambleEntries = {
  __typename: 'AssemblySolutionsFlexibleUspPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsFlexibleUspPreambleLinks = {
  __typename: 'AssemblySolutionsFlexibleUspPreambleLinks';
  assets: AssemblySolutionsFlexibleUspPreambleAssets;
  entries: AssemblySolutionsFlexibleUspPreambleEntries;
  resources: AssemblySolutionsFlexibleUspPreambleResources;
};

export type AssemblySolutionsFlexibleUspPreambleResources = {
  __typename: 'AssemblySolutionsFlexibleUspPreambleResources';
  block: Array<AssemblySolutionsFlexibleUspPreambleResourcesBlock>;
  hyperlink: Array<AssemblySolutionsFlexibleUspPreambleResourcesHyperlink>;
  inline: Array<AssemblySolutionsFlexibleUspPreambleResourcesInline>;
};

export type AssemblySolutionsFlexibleUspPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleUspPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleUspPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleUspPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsFlexibleUspPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsFlexibleUspPreambleResourcesInline';
  sys: ResourceSys;
};

export type AssemblySolutionsIntroSectionCarouselCollection = {
  __typename: 'AssemblySolutionsIntroSectionCarouselCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblySolutionsIntroSectionCarouselCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblySolutionsLinkingCollections = {
  __typename: 'AssemblySolutionsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type AssemblySolutionsLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssemblySolutionsOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export type AssemblySolutionsUspCardsCollection = {
  __typename: 'AssemblySolutionsUspCardsCollection';
  items: Array<Maybe<AssemblySolutionsUspCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssemblySolutionsUspCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<AssemblySolutionsUspCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssemblySolutionsUspCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssemblySolutionsUspCardsItem = Article | Teaser;

export type AssemblySolutionsUspCardsPreamble = {
  __typename: 'AssemblySolutionsUspCardsPreamble';
  json: Scalars['JSON'];
  links: AssemblySolutionsUspCardsPreambleLinks;
};

export type AssemblySolutionsUspCardsPreambleAssets = {
  __typename: 'AssemblySolutionsUspCardsPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsUspCardsPreambleEntries = {
  __typename: 'AssemblySolutionsUspCardsPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsUspCardsPreambleLinks = {
  __typename: 'AssemblySolutionsUspCardsPreambleLinks';
  assets: AssemblySolutionsUspCardsPreambleAssets;
  entries: AssemblySolutionsUspCardsPreambleEntries;
  resources: AssemblySolutionsUspCardsPreambleResources;
};

export type AssemblySolutionsUspCardsPreambleResources = {
  __typename: 'AssemblySolutionsUspCardsPreambleResources';
  block: Array<AssemblySolutionsUspCardsPreambleResourcesBlock>;
  hyperlink: Array<AssemblySolutionsUspCardsPreambleResourcesHyperlink>;
  inline: Array<AssemblySolutionsUspCardsPreambleResourcesInline>;
};

export type AssemblySolutionsUspCardsPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsUspCardsPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsUspCardsPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsUspCardsPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsUspCardsPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsUspCardsPreambleResourcesInline';
  sys: ResourceSys;
};

export type AssemblySolutionsUspIconsCollection = {
  __typename: 'AssemblySolutionsUspIconsCollection';
  items: Array<Maybe<UspEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AssemblySolutionsUspIconsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AssemblySolutionsUspPreamble = {
  __typename: 'AssemblySolutionsUspPreamble';
  json: Scalars['JSON'];
  links: AssemblySolutionsUspPreambleLinks;
};

export type AssemblySolutionsUspPreambleAssets = {
  __typename: 'AssemblySolutionsUspPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AssemblySolutionsUspPreambleEntries = {
  __typename: 'AssemblySolutionsUspPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AssemblySolutionsUspPreambleLinks = {
  __typename: 'AssemblySolutionsUspPreambleLinks';
  assets: AssemblySolutionsUspPreambleAssets;
  entries: AssemblySolutionsUspPreambleEntries;
  resources: AssemblySolutionsUspPreambleResources;
};

export type AssemblySolutionsUspPreambleResources = {
  __typename: 'AssemblySolutionsUspPreambleResources';
  block: Array<AssemblySolutionsUspPreambleResourcesBlock>;
  hyperlink: Array<AssemblySolutionsUspPreambleResourcesHyperlink>;
  inline: Array<AssemblySolutionsUspPreambleResourcesInline>;
};

export type AssemblySolutionsUspPreambleResourcesBlock = ResourceLink & {
  __typename: 'AssemblySolutionsUspPreambleResourcesBlock';
  sys: ResourceSys;
};

export type AssemblySolutionsUspPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'AssemblySolutionsUspPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type AssemblySolutionsUspPreambleResourcesInline = ResourceLink & {
  __typename: 'AssemblySolutionsUspPreambleResourcesInline';
  sys: ResourceSys;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename: 'Asset';
  contentType: Maybe<Scalars['String']>;
  contentfulMetadata: ContentfulMetadata;
  description: Maybe<Scalars['String']>;
  fileName: Maybe<Scalars['String']>;
  height: Maybe<Scalars['Int']>;
  linkedFrom: Maybe<AssetLinkingCollections>;
  size: Maybe<Scalars['Int']>;
  sys: Sys;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
  transform: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AssetCollection = {
  __typename: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AssetFilter = {
  AND: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType: InputMaybe<Scalars['String']>;
  contentType_contains: InputMaybe<Scalars['String']>;
  contentType_exists: InputMaybe<Scalars['Boolean']>;
  contentType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentType_not: InputMaybe<Scalars['String']>;
  contentType_not_contains: InputMaybe<Scalars['String']>;
  contentType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName: InputMaybe<Scalars['String']>;
  fileName_contains: InputMaybe<Scalars['String']>;
  fileName_exists: InputMaybe<Scalars['Boolean']>;
  fileName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fileName_not: InputMaybe<Scalars['String']>;
  fileName_not_contains: InputMaybe<Scalars['String']>;
  fileName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  height: InputMaybe<Scalars['Int']>;
  height_exists: InputMaybe<Scalars['Boolean']>;
  height_gt: InputMaybe<Scalars['Int']>;
  height_gte: InputMaybe<Scalars['Int']>;
  height_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  height_lt: InputMaybe<Scalars['Int']>;
  height_lte: InputMaybe<Scalars['Int']>;
  height_not: InputMaybe<Scalars['Int']>;
  height_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size: InputMaybe<Scalars['Int']>;
  size_exists: InputMaybe<Scalars['Boolean']>;
  size_gt: InputMaybe<Scalars['Int']>;
  size_gte: InputMaybe<Scalars['Int']>;
  size_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  size_lt: InputMaybe<Scalars['Int']>;
  size_lte: InputMaybe<Scalars['Int']>;
  size_not: InputMaybe<Scalars['Int']>;
  size_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  width: InputMaybe<Scalars['Int']>;
  width_exists: InputMaybe<Scalars['Boolean']>;
  width_gt: InputMaybe<Scalars['Int']>;
  width_gte: InputMaybe<Scalars['Int']>;
  width_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  width_lt: InputMaybe<Scalars['Int']>;
  width_lte: InputMaybe<Scalars['Int']>;
  width_not: InputMaybe<Scalars['Int']>;
  width_not_in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type AssetLinkingCollections = {
  __typename: 'AssetLinkingCollections';
  annualSustainabilityReportCollection: Maybe<AnnualSustainabilityReportCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  imageCollection: Maybe<ImageCollection>;
  videoCollection: Maybe<VideoCollection>;
};

export type AssetLinkingCollectionsAnnualSustainabilityReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AssetLinkingCollectionsVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type Author = Entry &
  _Node & {
    __typename: 'Author';
    _id: Scalars['ID'];
    bio: Maybe<AuthorBio>;
    contentfulMetadata: ContentfulMetadata;
    email: Maybe<Scalars['String']>;
    facebook: Maybe<Scalars['String']>;
    linkedFrom: Maybe<AuthorLinkingCollections>;
    linkedIn: Maybe<Scalars['String']>;
    name: Maybe<Scalars['String']>;
    profilePicture: Maybe<Image>;
    sys: Sys;
    twitter: Maybe<Scalars['String']>;
  };

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorBioArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorEmailArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorFacebookArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorLinkedInArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorProfilePictureArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Writer of editorial article entries [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/author) */
export type AuthorTwitterArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type AuthorBio = {
  __typename: 'AuthorBio';
  json: Scalars['JSON'];
  links: AuthorBioLinks;
};

export type AuthorBioAssets = {
  __typename: 'AuthorBioAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type AuthorBioEntries = {
  __typename: 'AuthorBioEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type AuthorBioLinks = {
  __typename: 'AuthorBioLinks';
  assets: AuthorBioAssets;
  entries: AuthorBioEntries;
  resources: AuthorBioResources;
};

export type AuthorBioResources = {
  __typename: 'AuthorBioResources';
  block: Array<AuthorBioResourcesBlock>;
  hyperlink: Array<AuthorBioResourcesHyperlink>;
  inline: Array<AuthorBioResourcesInline>;
};

export type AuthorBioResourcesBlock = ResourceLink & {
  __typename: 'AuthorBioResourcesBlock';
  sys: ResourceSys;
};

export type AuthorBioResourcesHyperlink = ResourceLink & {
  __typename: 'AuthorBioResourcesHyperlink';
  sys: ResourceSys;
};

export type AuthorBioResourcesInline = ResourceLink & {
  __typename: 'AuthorBioResourcesInline';
  sys: ResourceSys;
};

export type AuthorCollection = {
  __typename: 'AuthorCollection';
  items: Array<Maybe<Author>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AuthorFilter = {
  AND: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  OR: InputMaybe<Array<InputMaybe<AuthorFilter>>>;
  bio_contains: InputMaybe<Scalars['String']>;
  bio_exists: InputMaybe<Scalars['Boolean']>;
  bio_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  email: InputMaybe<Scalars['String']>;
  email_contains: InputMaybe<Scalars['String']>;
  email_exists: InputMaybe<Scalars['Boolean']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not: InputMaybe<Scalars['String']>;
  email_not_contains: InputMaybe<Scalars['String']>;
  email_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook: InputMaybe<Scalars['String']>;
  facebook_contains: InputMaybe<Scalars['String']>;
  facebook_exists: InputMaybe<Scalars['Boolean']>;
  facebook_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook_not: InputMaybe<Scalars['String']>;
  facebook_not_contains: InputMaybe<Scalars['String']>;
  facebook_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn: InputMaybe<Scalars['String']>;
  linkedIn_contains: InputMaybe<Scalars['String']>;
  linkedIn_exists: InputMaybe<Scalars['Boolean']>;
  linkedIn_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn_not: InputMaybe<Scalars['String']>;
  linkedIn_not_contains: InputMaybe<Scalars['String']>;
  linkedIn_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profilePicture: InputMaybe<CfImageNestedFilter>;
  profilePicture_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  twitter: InputMaybe<Scalars['String']>;
  twitter_contains: InputMaybe<Scalars['String']>;
  twitter_exists: InputMaybe<Scalars['Boolean']>;
  twitter_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  twitter_not: InputMaybe<Scalars['String']>;
  twitter_not_contains: InputMaybe<Scalars['String']>;
  twitter_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AuthorLinkingCollections = {
  __typename: 'AuthorLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type AuthorLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AuthorLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AuthorLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum AuthorLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum AuthorOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  LinkedInAsc = 'linkedIn_ASC',
  LinkedInDesc = 'linkedIn_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
}

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMember = Entry &
  _Node & {
    __typename: 'BoardMember';
    _id: Scalars['ID'];
    bio: Maybe<BoardMemberBio>;
    contentfulMetadata: ContentfulMetadata;
    fullName: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<BoardMemberLinkingCollections>;
    mediaImagesCollection: Maybe<BoardMemberMediaImagesCollection>;
    position: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberBioArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberFullNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberMediaImagesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<BoardMemberMediaImagesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberPositionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** All content related to a board member [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/boardMember) */
export type BoardMemberSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type BoardMemberBio = {
  __typename: 'BoardMemberBio';
  json: Scalars['JSON'];
  links: BoardMemberBioLinks;
};

export type BoardMemberBioAssets = {
  __typename: 'BoardMemberBioAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BoardMemberBioEntries = {
  __typename: 'BoardMemberBioEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BoardMemberBioLinks = {
  __typename: 'BoardMemberBioLinks';
  assets: BoardMemberBioAssets;
  entries: BoardMemberBioEntries;
  resources: BoardMemberBioResources;
};

export type BoardMemberBioResources = {
  __typename: 'BoardMemberBioResources';
  block: Array<BoardMemberBioResourcesBlock>;
  hyperlink: Array<BoardMemberBioResourcesHyperlink>;
  inline: Array<BoardMemberBioResourcesInline>;
};

export type BoardMemberBioResourcesBlock = ResourceLink & {
  __typename: 'BoardMemberBioResourcesBlock';
  sys: ResourceSys;
};

export type BoardMemberBioResourcesHyperlink = ResourceLink & {
  __typename: 'BoardMemberBioResourcesHyperlink';
  sys: ResourceSys;
};

export type BoardMemberBioResourcesInline = ResourceLink & {
  __typename: 'BoardMemberBioResourcesInline';
  sys: ResourceSys;
};

export type BoardMemberCollection = {
  __typename: 'BoardMemberCollection';
  items: Array<Maybe<BoardMember>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BoardMemberFilter = {
  AND: InputMaybe<Array<InputMaybe<BoardMemberFilter>>>;
  OR: InputMaybe<Array<InputMaybe<BoardMemberFilter>>>;
  bio_contains: InputMaybe<Scalars['String']>;
  bio_exists: InputMaybe<Scalars['Boolean']>;
  bio_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  fullName: InputMaybe<Scalars['String']>;
  fullName_contains: InputMaybe<Scalars['String']>;
  fullName_exists: InputMaybe<Scalars['Boolean']>;
  fullName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullName_not: InputMaybe<Scalars['String']>;
  fullName_not_contains: InputMaybe<Scalars['String']>;
  fullName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaImages: InputMaybe<CfImageNestedFilter>;
  mediaImagesCollection_exists: InputMaybe<Scalars['Boolean']>;
  position: InputMaybe<Scalars['String']>;
  position_contains: InputMaybe<Scalars['String']>;
  position_exists: InputMaybe<Scalars['Boolean']>;
  position_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position_not: InputMaybe<Scalars['String']>;
  position_not_contains: InputMaybe<Scalars['String']>;
  position_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type BoardMemberLinkingCollections = {
  __typename: 'BoardMemberLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type BoardMemberLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type BoardMemberMediaImagesCollection = {
  __typename: 'BoardMemberMediaImagesCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BoardMemberMediaImagesCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum BoardMemberOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type Campaign = Entry &
  _Node & {
    __typename: 'Campaign';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    faqCollection: Maybe<CampaignFaqCollection>;
    focusContent: Maybe<Teaser>;
    generalInformationCollection: Maybe<CampaignGeneralInformationCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<CampaignLinkingCollections>;
    mainContentCollection: Maybe<CampaignMainContentCollection>;
    mediaImageCollection: Maybe<CampaignMediaImageCollection>;
    mediaVideoCollection: Maybe<CampaignMediaVideoCollection>;
    preamble: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
  };

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignFaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignFocusContentArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignGeneralInformationCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignGeneralInformationCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignMainContentCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignMainContentCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserWrapperFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignMediaImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignMediaImageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignMediaVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignMediaVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a campaign [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/campaign) */
export type CampaignTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type CampaignCollection = {
  __typename: 'CampaignCollection';
  items: Array<Maybe<Campaign>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignFaqCollection = {
  __typename: 'CampaignFaqCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CampaignFilter = {
  AND: InputMaybe<Array<InputMaybe<CampaignFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CampaignFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqCollection_exists: InputMaybe<Scalars['Boolean']>;
  focusContent: InputMaybe<CfTeaserNestedFilter>;
  focusContent_exists: InputMaybe<Scalars['Boolean']>;
  generalInformation: InputMaybe<CfFactBoxNestedFilter>;
  generalInformationCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mainContent: InputMaybe<CfTeaserWrapperNestedFilter>;
  mainContentCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CampaignGeneralInformationCollection = {
  __typename: 'CampaignGeneralInformationCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignGeneralInformationCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CampaignLinkingCollections = {
  __typename: 'CampaignLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type CampaignLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CampaignMainContentCollection = {
  __typename: 'CampaignMainContentCollection';
  items: Array<Maybe<TeaserWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignMainContentCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type CampaignMediaImageCollection = {
  __typename: 'CampaignMediaImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignMediaImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CampaignMediaVideoCollection = {
  __typename: 'CampaignMediaVideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CampaignMediaVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CampaignOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConduct = Entry &
  _Node & {
    __typename: 'CodeOfConduct';
    _id: Scalars['ID'];
    billboard: Maybe<Teaser>;
    bottomCard: Maybe<Teaser>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<CodeOfConductDescription>;
    faqCollection: Maybe<CodeOfConductFaqCollection>;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<CodeOfConductLinkingCollections>;
    sys: Sys;
  };

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductBottomCardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductFaqCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CodeOfConductFaqCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqSectionFilter>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Code of conduct page [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/codeOfConduct) */
export type CodeOfConductLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CodeOfConductCollection = {
  __typename: 'CodeOfConductCollection';
  items: Array<Maybe<CodeOfConduct>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CodeOfConductDescription = {
  __typename: 'CodeOfConductDescription';
  json: Scalars['JSON'];
  links: CodeOfConductDescriptionLinks;
};

export type CodeOfConductDescriptionAssets = {
  __typename: 'CodeOfConductDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type CodeOfConductDescriptionEntries = {
  __typename: 'CodeOfConductDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type CodeOfConductDescriptionLinks = {
  __typename: 'CodeOfConductDescriptionLinks';
  assets: CodeOfConductDescriptionAssets;
  entries: CodeOfConductDescriptionEntries;
  resources: CodeOfConductDescriptionResources;
};

export type CodeOfConductDescriptionResources = {
  __typename: 'CodeOfConductDescriptionResources';
  block: Array<CodeOfConductDescriptionResourcesBlock>;
  hyperlink: Array<CodeOfConductDescriptionResourcesHyperlink>;
  inline: Array<CodeOfConductDescriptionResourcesInline>;
};

export type CodeOfConductDescriptionResourcesBlock = ResourceLink & {
  __typename: 'CodeOfConductDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type CodeOfConductDescriptionResourcesHyperlink = ResourceLink & {
  __typename: 'CodeOfConductDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type CodeOfConductDescriptionResourcesInline = ResourceLink & {
  __typename: 'CodeOfConductDescriptionResourcesInline';
  sys: ResourceSys;
};

export type CodeOfConductFaqCollection = {
  __typename: 'CodeOfConductFaqCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CodeOfConductFaqCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CodeOfConductFilter = {
  AND: InputMaybe<Array<InputMaybe<CodeOfConductFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CodeOfConductFilter>>>;
  billboard: InputMaybe<CfTeaserNestedFilter>;
  billboard_exists: InputMaybe<Scalars['Boolean']>;
  bottomCard: InputMaybe<CfTeaserNestedFilter>;
  bottomCard_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  faq: InputMaybe<CfFaqSectionNestedFilter>;
  faqCollection_exists: InputMaybe<Scalars['Boolean']>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CodeOfConductLinkingCollections = {
  __typename: 'CodeOfConductLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type CodeOfConductLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum CodeOfConductOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ContentfulMetadata = {
  __typename: 'ContentfulMetadata';
  concepts: Array<Maybe<TaxonomyConcept>>;
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataConceptsFilter = {
  descendants: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>;
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentfulMetadataFilter = {
  concepts: InputMaybe<ContentfulMetadataConceptsFilter>;
  concepts_exists: InputMaybe<Scalars['Boolean']>;
  tags: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists: InputMaybe<Scalars['Boolean']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename: 'ContentfulTag';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButton = Entry &
  _Node & {
    __typename: 'CtaButton';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    ctaText: Maybe<Scalars['String']>;
    ctaType: Maybe<Scalars['String']>;
    imageTrigger: Maybe<Image>;
    internalName: Maybe<Scalars['String']>;
    linkTrigger: Maybe<Scalars['String']>;
    linkedFrom: Maybe<CtaButtonLinkingCollections>;
    sys: Sys;
    videoTrigger: Maybe<Video>;
  };

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonCtaTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonCtaTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonImageTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonLinkTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A call-to-action [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/ctaButton) */
export type CtaButtonVideoTriggerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

export type CtaButtonCollection = {
  __typename: 'CtaButtonCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type CtaButtonFilter = {
  AND: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CtaButtonFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  ctaText: InputMaybe<Scalars['String']>;
  ctaText_contains: InputMaybe<Scalars['String']>;
  ctaText_exists: InputMaybe<Scalars['Boolean']>;
  ctaText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaText_not: InputMaybe<Scalars['String']>;
  ctaText_not_contains: InputMaybe<Scalars['String']>;
  ctaText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType: InputMaybe<Scalars['String']>;
  ctaType_contains: InputMaybe<Scalars['String']>;
  ctaType_exists: InputMaybe<Scalars['Boolean']>;
  ctaType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType_not: InputMaybe<Scalars['String']>;
  ctaType_not_contains: InputMaybe<Scalars['String']>;
  ctaType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageTrigger: InputMaybe<CfImageNestedFilter>;
  imageTrigger_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger: InputMaybe<Scalars['String']>;
  linkTrigger_contains: InputMaybe<Scalars['String']>;
  linkTrigger_exists: InputMaybe<Scalars['Boolean']>;
  linkTrigger_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger_not: InputMaybe<Scalars['String']>;
  linkTrigger_not_contains: InputMaybe<Scalars['String']>;
  linkTrigger_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  videoTrigger: InputMaybe<CfVideoNestedFilter>;
  videoTrigger_exists: InputMaybe<Scalars['Boolean']>;
};

export type CtaButtonLinkingCollections = {
  __typename: 'CtaButtonLinkingCollections';
  annualSustainabilityReportCollection: Maybe<AnnualSustainabilityReportCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel1Collection: Maybe<AssemblyLevel1Collection>;
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  teamCardsWrapperCollection: Maybe<TeamCardsWrapperCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type CtaButtonLinkingCollectionsAnnualSustainabilityReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<CtaButtonLinkingCollectionsAnnualSustainabilityReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsAssemblyCareersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsAssemblyLevel1CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsAssemblyLevel1CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<CtaButtonLinkingCollectionsAssemblySolutionsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsTeamCardsWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsTeamCardsWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type CtaButtonLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum CtaButtonLinkingCollectionsAnnualSustainabilityReportCollectionOrder {
  IntroPreambleAsc = 'introPreamble_ASC',
  IntroPreambleDesc = 'introPreamble_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
}

export enum CtaButtonLinkingCollectionsAssemblyCareersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CtaButtonLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export enum CtaButtonLinkingCollectionsAssemblyLevel1CollectionOrder {
  BottomCarouselHeadingAsc = 'bottomCarouselHeading_ASC',
  BottomCarouselHeadingDesc = 'bottomCarouselHeading_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TableToggleAsc = 'tableToggle_ASC',
  TableToggleDesc = 'tableToggle_DESC',
  TopFirstCarouselHeadingAsc = 'topFirstCarouselHeading_ASC',
  TopFirstCarouselHeadingDesc = 'topFirstCarouselHeading_DESC',
  TopSecondCarouselHeadingAsc = 'topSecondCarouselHeading_ASC',
  TopSecondCarouselHeadingDesc = 'topSecondCarouselHeading_DESC',
}

export enum CtaButtonLinkingCollectionsAssemblySolutionsCollectionOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum CtaButtonLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CtaButtonLinkingCollectionsTeamCardsWrapperCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CtaButtonLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CtaButtonOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/embedMedia) */
export type EmbedMedia = Entry &
  _Node & {
    __typename: 'EmbedMedia';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<EmbedMediaLinkingCollections>;
    sys: Sys;
    url: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/embedMedia) */
export type EmbedMediaInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/embedMedia) */
export type EmbedMediaLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/embedMedia) */
export type EmbedMediaUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type EmbedMediaCollection = {
  __typename: 'EmbedMediaCollection';
  items: Array<Maybe<EmbedMedia>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EmbedMediaFilter = {
  AND: InputMaybe<Array<InputMaybe<EmbedMediaFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EmbedMediaFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EmbedMediaLinkingCollections = {
  __typename: 'EmbedMediaLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  entryCollection: Maybe<EntryCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type EmbedMediaLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type EmbedMediaLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type EmbedMediaLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum EmbedMediaLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum EmbedMediaLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EmbedMediaOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EntryFilter = {
  AND: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  sys: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type Event = Entry &
  _Node & {
    __typename: 'Event';
    _id: Scalars['ID'];
    body: Maybe<EventBody> | null;
    contentfulMetadata: ContentfulMetadata;
    date: Maybe<Scalars['DateTime']>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<EventLinkingCollections>;
    mediaGroupsCollection: Maybe<EventMediaGroupsCollection>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
    type: Maybe<Scalars['String']>;
    webcastsCollection: Maybe<EventWebcastsCollection>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventDateArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventMediaGroupsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EventMediaGroupsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/event) */
export type EventWebcastsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EventWebcastsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

export type EventBody = {
  __typename: 'EventBody';
  json: Scalars['JSON'];
  links: EventBodyLinks;
};

export type EventBodyAssets = {
  __typename: 'EventBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type EventBodyEntries = {
  __typename: 'EventBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type EventBodyLinks = {
  __typename: 'EventBodyLinks';
  assets: EventBodyAssets;
  entries: EventBodyEntries;
  resources: EventBodyResources;
};

export type EventBodyResources = {
  __typename: 'EventBodyResources';
  block: Array<EventBodyResourcesBlock>;
  hyperlink: Array<EventBodyResourcesHyperlink>;
  inline: Array<EventBodyResourcesInline>;
};

export type EventBodyResourcesBlock = ResourceLink & {
  __typename: 'EventBodyResourcesBlock';
  sys: ResourceSys;
};

export type EventBodyResourcesHyperlink = ResourceLink & {
  __typename: 'EventBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type EventBodyResourcesInline = ResourceLink & {
  __typename: 'EventBodyResourcesInline';
  sys: ResourceSys;
};

export type EventCollection = {
  __typename: 'EventCollection';
  items: Array<Maybe<Event>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type EventFilter = {
  AND: InputMaybe<Array<InputMaybe<EventFilter>>>;
  OR: InputMaybe<Array<InputMaybe<EventFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  date: InputMaybe<Scalars['DateTime']>;
  date_exists: InputMaybe<Scalars['Boolean']>;
  date_gt: InputMaybe<Scalars['DateTime']>;
  date_gte: InputMaybe<Scalars['DateTime']>;
  date_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt: InputMaybe<Scalars['DateTime']>;
  date_lte: InputMaybe<Scalars['DateTime']>;
  date_not: InputMaybe<Scalars['DateTime']>;
  date_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaGroups: InputMaybe<CfFactBoxNestedFilter>;
  mediaGroupsCollection_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webcasts: InputMaybe<CfArticleNestedFilter>;
  webcastsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type EventLinkingCollections = {
  __typename: 'EventLinkingCollections';
  assemblyReportsAndPresentationsCollection: Maybe<AssemblyReportsAndPresentationsCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type EventLinkingCollectionsAssemblyReportsAndPresentationsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<EventLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type EventLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum EventLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
}

export type EventMediaGroupsCollection = {
  __typename: 'EventMediaGroupsCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum EventMediaGroupsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum EventOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type EventWebcastsCollection = {
  __typename: 'EventWebcastsCollection';
  items: Array<Maybe<Article>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum EventWebcastsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBox = Entry &
  _Node & {
    __typename: 'FactBox';
    _id: Scalars['ID'];
    body: Maybe<FactBoxBody>;
    callToActionCollection: Maybe<FactBoxCallToActionCollection>;
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FactBoxLinkingCollections>;
    mediaAssetsCollection: Maybe<AssetCollection>;
    mediaImage: Maybe<Image>;
    mediaVideo: Maybe<Video>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<FactBoxTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxBodyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxCallToActionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxCallToActionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxMediaAssetsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxMediaVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** Short background text that complements a longer text on a specific topic [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBox) */
export type FactBoxTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FactBoxBody = {
  __typename: 'FactBoxBody';
  json: Scalars['JSON'];
  links: FactBoxBodyLinks;
};

export type FactBoxBodyAssets = {
  __typename: 'FactBoxBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type FactBoxBodyEntries = {
  __typename: 'FactBoxBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type FactBoxBodyLinks = {
  __typename: 'FactBoxBodyLinks';
  assets: FactBoxBodyAssets;
  entries: FactBoxBodyEntries;
  resources: FactBoxBodyResources;
};

export type FactBoxBodyResources = {
  __typename: 'FactBoxBodyResources';
  block: Array<FactBoxBodyResourcesBlock>;
  hyperlink: Array<FactBoxBodyResourcesHyperlink>;
  inline: Array<FactBoxBodyResourcesInline>;
};

export type FactBoxBodyResourcesBlock = ResourceLink & {
  __typename: 'FactBoxBodyResourcesBlock';
  sys: ResourceSys;
};

export type FactBoxBodyResourcesHyperlink = ResourceLink & {
  __typename: 'FactBoxBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type FactBoxBodyResourcesInline = ResourceLink & {
  __typename: 'FactBoxBodyResourcesInline';
  sys: ResourceSys;
};

export type FactBoxCallToActionCollection = {
  __typename: 'FactBoxCallToActionCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxCallToActionCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FactBoxCollection = {
  __typename: 'FactBoxCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FactBoxFilter = {
  AND: InputMaybe<Array<InputMaybe<FactBoxFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FactBoxFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  callToAction: InputMaybe<CfCtaButtonNestedFilter>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAssetsCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImage_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FactBoxLinkingCollections = {
  __typename: 'FactBoxLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
  eventCollection: Maybe<EventCollection>;
  factBoxWrapperCollection: Maybe<FactBoxWrapperCollection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
};

export type FactBoxLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsEventCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsEventCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsFactBoxWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsFactBoxWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxLinkingCollectionsFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxLinkingCollectionsFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FactBoxLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FactBoxLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FactBoxLinkingCollectionsEventCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FactBoxLinkingCollectionsFactBoxWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FactBoxLinkingCollectionsFaqSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FactBoxOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FactBoxTagsCollection = {
  __typename: 'FactBoxTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBoxWrapper) */
export type FactBoxWrapper = Entry &
  _Node & {
    __typename: 'FactBoxWrapper';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    factBoxesCollection: Maybe<FactBoxWrapperFactBoxesCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FactBoxWrapperLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBoxWrapper) */
export type FactBoxWrapperFactBoxesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxWrapperFactBoxesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBoxWrapper) */
export type FactBoxWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBoxWrapper) */
export type FactBoxWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A wrapper containing multiple Fact Boxes [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/factBoxWrapper) */
export type FactBoxWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FactBoxWrapperCollection = {
  __typename: 'FactBoxWrapperCollection';
  items: Array<Maybe<FactBoxWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FactBoxWrapperFactBoxesCollection = {
  __typename: 'FactBoxWrapperFactBoxesCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FactBoxWrapperFactBoxesCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FactBoxWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<FactBoxWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FactBoxWrapperFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  factBoxes: InputMaybe<CfFactBoxNestedFilter>;
  factBoxesCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type FactBoxWrapperLinkingCollections = {
  __typename: 'FactBoxWrapperLinkingCollections';
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  entryCollection: Maybe<EntryCollection>;
};

export type FactBoxWrapperLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<FactBoxWrapperLinkingCollectionsAssemblyLevel0CollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FactBoxWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FactBoxWrapperLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export enum FactBoxWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSection = Entry &
  _Node & {
    __typename: 'FaqSection';
    _id: Scalars['ID'];
    backgroundTitle: Maybe<Image>;
    contentfulMetadata: ContentfulMetadata;
    faqSectionCollection: Maybe<FaqSectionFaqSectionCollection>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<FaqSectionLinkingCollections>;
    sectionTitle: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSectionBackgroundTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSectionFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSectionInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSectionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/faqSection) */
export type FaqSectionSectionTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type FaqSectionCollection = {
  __typename: 'FaqSectionCollection';
  items: Array<Maybe<FaqSection>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type FaqSectionFaqSectionCollection = {
  __typename: 'FaqSectionFaqSectionCollection';
  items: Array<Maybe<FactBox>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum FaqSectionFaqSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqSectionFilter = {
  AND: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<FaqSectionFilter>>>;
  backgroundTitle: InputMaybe<CfImageNestedFilter>;
  backgroundTitle_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSection: InputMaybe<CfFactBoxNestedFilter>;
  faqSectionCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle: InputMaybe<Scalars['String']>;
  sectionTitle_contains: InputMaybe<Scalars['String']>;
  sectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  sectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle_not: InputMaybe<Scalars['String']>;
  sectionTitle_not_contains: InputMaybe<Scalars['String']>;
  sectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type FaqSectionLinkingCollections = {
  __typename: 'FaqSectionLinkingCollections';
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  codeOfConductCollection: Maybe<CodeOfConductCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type FaqSectionLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<FaqSectionLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsCodeOfConductCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionLinkingCollectionsCodeOfConductCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type FaqSectionLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum FaqSectionLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum FaqSectionLinkingCollectionsCodeOfConductCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FaqSectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type Image = Entry &
  _Node & {
    __typename: 'Image';
    _id: Scalars['ID'];
    altText: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    copyrightImg: Maybe<Asset>;
    copyrightText: Maybe<Scalars['String']>;
    focusArea: Maybe<Scalars['String']>;
    hyperlink: Maybe<Scalars['String']> | null;
    image: Maybe<Asset>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ImageLinkingCollections>;
    sys: Sys;
  };

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageAltTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageCopyrightImgArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageCopyrightTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageFocusAreaArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageHyperlinkArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Image asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/image) */
export type ImageLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ImageCollection = {
  __typename: 'ImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ImageFilter = {
  AND: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ImageFilter>>>;
  altText: InputMaybe<Scalars['String']>;
  altText_contains: InputMaybe<Scalars['String']>;
  altText_exists: InputMaybe<Scalars['Boolean']>;
  altText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not: InputMaybe<Scalars['String']>;
  altText_not_contains: InputMaybe<Scalars['String']>;
  altText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightImg_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea: InputMaybe<Scalars['String']>;
  focusArea_contains: InputMaybe<Scalars['String']>;
  focusArea_exists: InputMaybe<Scalars['Boolean']>;
  focusArea_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea_not: InputMaybe<Scalars['String']>;
  focusArea_not_contains: InputMaybe<Scalars['String']>;
  focusArea_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink: InputMaybe<Scalars['String']>;
  hyperlink_contains: InputMaybe<Scalars['String']>;
  hyperlink_exists: InputMaybe<Scalars['Boolean']>;
  hyperlink_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink_not: InputMaybe<Scalars['String']>;
  hyperlink_not_contains: InputMaybe<Scalars['String']>;
  hyperlink_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export type ImageLinkingCollections = {
  __typename: 'ImageLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  authorCollection: Maybe<AuthorCollection>;
  boardMemberCollection: Maybe<BoardMemberCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
  personCollection: Maybe<PersonCollection>;
  seoCollection: Maybe<SeoCollection>;
  supportCompositionCollection: Maybe<SupportCompositionCollection>;
  teaserCollection: Maybe<TeaserCollection>;
  videoCollection: Maybe<VideoCollection>;
};

export type ImageLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<ImageLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsAuthorCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsAuthorCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsBoardMemberCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsBoardMemberCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsCtaButtonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsFaqSectionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsPersonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsPersonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsSeoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsSeoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsSupportCompositionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsSupportCompositionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ImageLinkingCollectionsVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageLinkingCollectionsVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ImageLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ImageLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum ImageLinkingCollectionsAuthorCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  LinkedInAsc = 'linkedIn_ASC',
  LinkedInDesc = 'linkedIn_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
}

export enum ImageLinkingCollectionsBoardMemberCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ImageLinkingCollectionsCtaButtonCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageLinkingCollectionsFaqSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SectionTitleAsc = 'sectionTitle_ASC',
  SectionTitleDesc = 'sectionTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsPersonCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageLinkingCollectionsSeoCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SocialMediaSiteNameAsc = 'socialMediaSiteName_ASC',
  SocialMediaSiteNameDesc = 'socialMediaSiteName_DESC',
  SocialMediaTitleAsc = 'socialMediaTitle_ASC',
  SocialMediaTitleDesc = 'socialMediaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  XRobotsTagAsc = 'xRobotsTag_ASC',
  XRobotsTagDesc = 'xRobotsTag_DESC',
}

export enum ImageLinkingCollectionsSupportCompositionCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageLinkingCollectionsVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor: InputMaybe<Scalars['HexColor']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius: InputMaybe<Scalars['Int']>;
  /** Desired image format. Defaults to the original image format. */
  format: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height: InputMaybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality: InputMaybe<Scalars['Quality']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width: InputMaybe<Scalars['Dimension']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssembly = Entry &
  _Node & {
    __typename: 'NewsroomAssembly';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    heroBanner: Maybe<Teaser>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<NewsroomAssemblyLinkingCollections>;
    mediaKitBillboard: Maybe<Teaser>;
    pressAndMediaBillboard: Maybe<Teaser>;
    seoMetadata: Maybe<Seo>;
    sys: Sys;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblyHeroBannerArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblyInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblyLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblyMediaKitBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblyPressAndMediaBillboardArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TeaserFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/newsroomAssembly) */
export type NewsroomAssemblySeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

export type NewsroomAssemblyCollection = {
  __typename: 'NewsroomAssemblyCollection';
  items: Array<Maybe<NewsroomAssembly>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type NewsroomAssemblyFilter = {
  AND: InputMaybe<Array<InputMaybe<NewsroomAssemblyFilter>>>;
  OR: InputMaybe<Array<InputMaybe<NewsroomAssemblyFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  heroBanner: InputMaybe<CfTeaserNestedFilter>;
  heroBanner_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaKitBillboard: InputMaybe<CfTeaserNestedFilter>;
  mediaKitBillboard_exists: InputMaybe<Scalars['Boolean']>;
  pressAndMediaBillboard: InputMaybe<CfTeaserNestedFilter>;
  pressAndMediaBillboard_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type NewsroomAssemblyLinkingCollections = {
  __typename: 'NewsroomAssemblyLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type NewsroomAssemblyLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type NewsroomAssemblyLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<NewsroomAssemblyLinkingCollectionsSectionAssemblyCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum NewsroomAssemblyLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum NewsroomAssemblyOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type Person = Entry &
  _Node & {
    __typename: 'Person';
    _id: Scalars['ID'];
    bio: Maybe<PersonBio>;
    contentfulMetadata: ContentfulMetadata;
    fullName: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<PersonLinkingCollections>;
    mediaImagesCollection: Maybe<PersonMediaImagesCollection>;
    position: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    tag: Maybe<Tag>;
  };

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonBioArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonFullNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonMediaImagesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<PersonMediaImagesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonPositionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** All content related to board members [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/person) */
export type PersonTagArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TagFilter>;
};

export type PersonBio = {
  __typename: 'PersonBio';
  json: Scalars['JSON'];
  links: PersonBioLinks;
};

export type PersonBioAssets = {
  __typename: 'PersonBioAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type PersonBioEntries = {
  __typename: 'PersonBioEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type PersonBioLinks = {
  __typename: 'PersonBioLinks';
  assets: PersonBioAssets;
  entries: PersonBioEntries;
  resources: PersonBioResources;
};

export type PersonBioResources = {
  __typename: 'PersonBioResources';
  block: Array<PersonBioResourcesBlock>;
  hyperlink: Array<PersonBioResourcesHyperlink>;
  inline: Array<PersonBioResourcesInline>;
};

export type PersonBioResourcesBlock = ResourceLink & {
  __typename: 'PersonBioResourcesBlock';
  sys: ResourceSys;
};

export type PersonBioResourcesHyperlink = ResourceLink & {
  __typename: 'PersonBioResourcesHyperlink';
  sys: ResourceSys;
};

export type PersonBioResourcesInline = ResourceLink & {
  __typename: 'PersonBioResourcesInline';
  sys: ResourceSys;
};

export type PersonCollection = {
  __typename: 'PersonCollection';
  items: Array<Maybe<Person>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PersonFilter = {
  AND: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PersonFilter>>>;
  bio_contains: InputMaybe<Scalars['String']>;
  bio_exists: InputMaybe<Scalars['Boolean']>;
  bio_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  fullName: InputMaybe<Scalars['String']>;
  fullName_contains: InputMaybe<Scalars['String']>;
  fullName_exists: InputMaybe<Scalars['Boolean']>;
  fullName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fullName_not: InputMaybe<Scalars['String']>;
  fullName_not_contains: InputMaybe<Scalars['String']>;
  fullName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaImages: InputMaybe<CfImageNestedFilter>;
  mediaImagesCollection_exists: InputMaybe<Scalars['Boolean']>;
  position: InputMaybe<Scalars['String']>;
  position_contains: InputMaybe<Scalars['String']>;
  position_exists: InputMaybe<Scalars['Boolean']>;
  position_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position_not: InputMaybe<Scalars['String']>;
  position_not_contains: InputMaybe<Scalars['String']>;
  position_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tag: InputMaybe<CfTagNestedFilter>;
  tag_exists: InputMaybe<Scalars['Boolean']>;
};

export type PersonLinkingCollections = {
  __typename: 'PersonLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
};

export type PersonLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type PersonLinkingCollectionsSectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<PersonLinkingCollectionsSectionAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum PersonLinkingCollectionsSectionAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type PersonMediaImagesCollection = {
  __typename: 'PersonMediaImagesCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum PersonMediaImagesCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PersonOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Segments from BlueConic CDP [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/personalisationSegments) */
export type PersonalisationSegments = Entry &
  _Node & {
    __typename: 'PersonalisationSegments';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<PersonalisationSegmentsLinkingCollections>;
    sys: Sys;
  };

/** Segments from BlueConic CDP [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/personalisationSegments) */
export type PersonalisationSegmentsInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Segments from BlueConic CDP [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/personalisationSegments) */
export type PersonalisationSegmentsLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PersonalisationSegmentsCollection = {
  __typename: 'PersonalisationSegmentsCollection';
  items: Array<Maybe<PersonalisationSegments>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PersonalisationSegmentsFilter = {
  AND: InputMaybe<Array<InputMaybe<PersonalisationSegmentsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<PersonalisationSegmentsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type PersonalisationSegmentsLinkingCollections = {
  __typename: 'PersonalisationSegmentsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  tagCollection: Maybe<TagCollection>;
};

export type PersonalisationSegmentsLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type PersonalisationSegmentsLinkingCollectionsTagCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<PersonalisationSegmentsLinkingCollectionsTagCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum PersonalisationSegmentsLinkingCollectionsTagCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export enum PersonalisationSegmentsOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Query = {
  __typename: 'Query';
  _node: Maybe<_Node>;
  alertNotification: Maybe<AlertNotification>;
  alertNotificationCollection: Maybe<AlertNotificationCollection>;
  alertWrapper: Maybe<AlertWrapper>;
  alertWrapperCollection: Maybe<AlertWrapperCollection>;
  annualSustainabilityReport: Maybe<AnnualSustainabilityReport>;
  annualSustainabilityReportCollection: Maybe<AnnualSustainabilityReportCollection>;
  article: Maybe<Article>;
  articleCollection: Maybe<ArticleCollection>;
  assemblyCareers: Maybe<AssemblyCareers>;
  assemblyCareersAboutLifeAtTelia: Maybe<AssemblyCareersAboutLifeAtTelia>;
  assemblyCareersAboutLifeAtTeliaCollection: Maybe<AssemblyCareersAboutLifeAtTeliaCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  assemblyCareersTeamsPage: Maybe<AssemblyCareersTeamsPage>;
  assemblyCareersTeamsPageCollection: Maybe<AssemblyCareersTeamsPageCollection>;
  assemblyCollectionPage: Maybe<AssemblyCollectionPage>;
  assemblyCollectionPageCollection: Maybe<AssemblyCollectionPageCollection>;
  assemblyLevel0: Maybe<AssemblyLevel0>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel1: Maybe<AssemblyLevel1>;
  assemblyLevel1Collection: Maybe<AssemblyLevel1Collection>;
  assemblyMenuTeasers: Maybe<AssemblyMenuTeasers>;
  assemblyMenuTeasersCollection: Maybe<AssemblyMenuTeasersCollection>;
  assemblyOpenPositions: Maybe<AssemblyOpenPositions>;
  assemblyOpenPositionsCollection: Maybe<AssemblyOpenPositionsCollection>;
  assemblyReportsAndPresentations: Maybe<AssemblyReportsAndPresentations>;
  assemblyReportsAndPresentationsCollection: Maybe<AssemblyReportsAndPresentationsCollection>;
  assemblySolutions: Maybe<AssemblySolutions>;
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  assemblySolutionsFlexible: Maybe<AssemblySolutionsFlexible>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  asset: Maybe<Asset>;
  assetCollection: Maybe<AssetCollection>;
  author: Maybe<Author>;
  authorCollection: Maybe<AuthorCollection>;
  boardMember: Maybe<BoardMember>;
  boardMemberCollection: Maybe<BoardMemberCollection>;
  campaign: Maybe<Campaign>;
  campaignCollection: Maybe<CampaignCollection>;
  codeOfConduct: Maybe<CodeOfConduct>;
  codeOfConductCollection: Maybe<CodeOfConductCollection>;
  ctaButton: Maybe<CtaButton>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  embedMedia: Maybe<EmbedMedia>;
  embedMediaCollection: Maybe<EmbedMediaCollection>;
  entryCollection: Maybe<EntryCollection>;
  event: Maybe<Event>;
  eventCollection: Maybe<EventCollection>;
  factBox: Maybe<FactBox>;
  factBoxCollection: Maybe<FactBoxCollection>;
  factBoxWrapper: Maybe<FactBoxWrapper>;
  factBoxWrapperCollection: Maybe<FactBoxWrapperCollection>;
  faqSection: Maybe<FaqSection>;
  faqSectionCollection: Maybe<FaqSectionCollection>;
  image: Maybe<Image>;
  imageCollection: Maybe<ImageCollection>;
  newsroomAssembly: Maybe<NewsroomAssembly>;
  newsroomAssemblyCollection: Maybe<NewsroomAssemblyCollection>;
  person: Maybe<Person>;
  personCollection: Maybe<PersonCollection>;
  personalisationSegments: Maybe<PersonalisationSegments>;
  personalisationSegmentsCollection: Maybe<PersonalisationSegmentsCollection>;
  redirectEntity: Maybe<RedirectEntity>;
  redirectEntityCollection: Maybe<RedirectEntityCollection>;
  redirectRule: Maybe<RedirectRule>;
  redirectRuleCollection: Maybe<RedirectRuleCollection>;
  resource: Maybe<Resource>;
  resourceCollection: Maybe<ResourceCollection>;
  resourceSet: Maybe<ResourceSet>;
  resourceSetCollection: Maybe<ResourceSetCollection>;
  sectionAssembly: Maybe<SectionAssembly>;
  sectionAssemblyCollection: Maybe<SectionAssemblyCollection>;
  seo: Maybe<Seo>;
  seoCollection: Maybe<SeoCollection>;
  supportComposition: Maybe<SupportComposition>;
  supportCompositionCollection: Maybe<SupportCompositionCollection>;
  table: Maybe<Table>;
  tableCollection: Maybe<TableCollection>;
  tag: Maybe<Tag>;
  tagCollection: Maybe<TagCollection>;
  tagWrapper: Maybe<TagWrapper>;
  tagWrapperCollection: Maybe<TagWrapperCollection>;
  teamCardsWrapper: Maybe<TeamCardsWrapper>;
  teamCardsWrapperCollection: Maybe<TeamCardsWrapperCollection>;
  teaser: Maybe<Teaser>;
  teaserCollection: Maybe<TeaserCollection>;
  teaserWrapper: Maybe<TeaserWrapper>;
  teaserWrapperCollection: Maybe<TeaserWrapperCollection>;
  uspEntity: Maybe<UspEntity>;
  uspEntityCollection: Maybe<UspEntityCollection>;
  uspEntityWrapper: Maybe<UspEntityWrapper>;
  uspEntityWrapperCollection: Maybe<UspEntityWrapperCollection>;
  video: Maybe<Video>;
  videoCollection: Maybe<VideoCollection>;
};

export type Query_NodeArgs = {
  id: Scalars['ID'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAlertNotificationArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAlertNotificationCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AlertNotificationOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlertNotificationFilter>;
};

export type QueryAlertWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAlertWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AlertWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlertWrapperFilter>;
};

export type QueryAnnualSustainabilityReportArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAnnualSustainabilityReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AnnualSustainabilityReportOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AnnualSustainabilityReportFilter>;
};

export type QueryArticleArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ArticleOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleFilter>;
};

export type QueryAssemblyCareersArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyCareersAboutLifeAtTeliaArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyCareersAboutLifeAtTeliaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCareersAboutLifeAtTeliaOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersAboutLifeAtTeliaFilter>;
};

export type QueryAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCareersOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersFilter>;
};

export type QueryAssemblyCareersTeamsPageArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyCareersTeamsPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCareersTeamsPageOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCareersTeamsPageFilter>;
};

export type QueryAssemblyCollectionPageArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyCollectionPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyCollectionPageOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyCollectionPageFilter>;
};

export type QueryAssemblyLevel0Args = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel0Order>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel0Filter>;
};

export type QueryAssemblyLevel1Args = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyLevel1CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyLevel1Order>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyLevel1Filter>;
};

export type QueryAssemblyMenuTeasersArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyMenuTeasersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyMenuTeasersOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyMenuTeasersFilter>;
};

export type QueryAssemblyOpenPositionsArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyOpenPositionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyOpenPositionsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyOpenPositionsFilter>;
};

export type QueryAssemblyReportsAndPresentationsArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblyReportsAndPresentationsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblyReportsAndPresentationsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblyReportsAndPresentationsFilter>;
};

export type QueryAssemblySolutionsArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblySolutionsFilter>;
};

export type QueryAssemblySolutionsFlexibleArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssemblySolutionsFlexibleOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssemblySolutionsFlexibleFilter>;
};

export type QueryAssetArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAssetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssetFilter>;
};

export type QueryAuthorArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryAuthorCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<AuthorOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AuthorFilter>;
};

export type QueryBoardMemberArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryBoardMemberCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<BoardMemberOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BoardMemberFilter>;
};

export type QueryCampaignArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CampaignOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CampaignFilter>;
};

export type QueryCodeOfConductArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryCodeOfConductCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CodeOfConductOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CodeOfConductFilter>;
};

export type QueryCtaButtonArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<CtaButtonOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

export type QueryEmbedMediaArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryEmbedMediaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EmbedMediaOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EmbedMediaFilter>;
};

export type QueryEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EntryFilter>;
};

export type QueryEventArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryEventCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<EventOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<EventFilter>;
};

export type QueryFactBoxArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxFilter>;
};

export type QueryFactBoxWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFactBoxWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FactBoxWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FactBoxWrapperFilter>;
};

export type QueryFaqSectionArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryFaqSectionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<FaqSectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<FaqSectionFilter>;
};

export type QueryImageArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ImageOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

export type QueryNewsroomAssemblyArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryNewsroomAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<NewsroomAssemblyOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<NewsroomAssemblyFilter>;
};

export type QueryPersonArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryPersonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<PersonOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PersonFilter>;
};

export type QueryPersonalisationSegmentsArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryPersonalisationSegmentsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<PersonalisationSegmentsOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PersonalisationSegmentsFilter>;
};

export type QueryRedirectEntityArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryRedirectEntityCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<RedirectEntityOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<RedirectEntityFilter>;
};

export type QueryRedirectRuleArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryRedirectRuleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<RedirectRuleOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<RedirectRuleFilter>;
};

export type QueryResourceArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryResourceCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceFilter>;
};

export type QueryResourceSetArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryResourceSetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceSetOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceSetFilter>;
};

export type QuerySectionAssemblyArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QuerySectionAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SectionAssemblyOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SectionAssemblyFilter>;
};

export type QuerySeoArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QuerySeoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SeoFilter>;
};

export type QuerySupportCompositionArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QuerySupportCompositionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SupportCompositionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SupportCompositionFilter>;
};

export type QueryTableArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTableCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TableOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TableFilter>;
};

export type QueryTagArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTagCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

export type QueryTagWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTagWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagWrapperFilter>;
};

export type QueryTeamCardsWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTeamCardsWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeamCardsWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeamCardsWrapperFilter>;
};

export type QueryTeaserArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

export type QueryTeaserWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryTeaserWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserWrapperFilter>;
};

export type QueryUspEntityArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryUspEntityCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<UspEntityOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityFilter>;
};

export type QueryUspEntityWrapperArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryUspEntityWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<UspEntityWrapperOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityWrapperFilter>;
};

export type QueryVideoArgs = {
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type QueryVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** Determines redirect rules for path teliacompany.com/services/redirect/{slug} [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectEntity) */
export type RedirectEntity = Entry &
  _Node & {
    __typename: 'RedirectEntity';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    linkedFrom: Maybe<RedirectEntityLinkingCollections>;
    redirectsCollection: Maybe<RedirectEntityRedirectsCollection>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
  };

/** Determines redirect rules for path teliacompany.com/services/redirect/{slug} [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectEntity) */
export type RedirectEntityLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Determines redirect rules for path teliacompany.com/services/redirect/{slug} [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectEntity) */
export type RedirectEntityRedirectsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<RedirectEntityRedirectsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<RedirectRuleFilter>;
};

/** Determines redirect rules for path teliacompany.com/services/redirect/{slug} [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectEntity) */
export type RedirectEntitySlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type RedirectEntityCollection = {
  __typename: 'RedirectEntityCollection';
  items: Array<Maybe<RedirectEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RedirectEntityFilter = {
  AND: InputMaybe<Array<InputMaybe<RedirectEntityFilter>>>;
  OR: InputMaybe<Array<InputMaybe<RedirectEntityFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  redirects: InputMaybe<CfRedirectRuleNestedFilter>;
  redirectsCollection_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type RedirectEntityLinkingCollections = {
  __typename: 'RedirectEntityLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type RedirectEntityLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum RedirectEntityOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RedirectEntityRedirectsCollection = {
  __typename: 'RedirectEntityRedirectsCollection';
  items: Array<Maybe<RedirectRule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum RedirectEntityRedirectsCollectionOrder {
  DestinationAsc = 'Destination_ASC',
  DestinationDesc = 'Destination_DESC',
  FlagAsc = 'flag_ASC',
  FlagDesc = 'flag_DESC',
  MatchByAsc = 'matchBy_ASC',
  MatchByDesc = 'matchBy_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RedirectCodeAsc = 'redirectCode_ASC',
  RedirectCodeDesc = 'redirectCode_DESC',
  SubjectAsc = 'subject_ASC',
  SubjectDesc = 'subject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRule = Entry &
  _Node & {
    __typename: 'RedirectRule';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    destination: Maybe<Scalars['String']>;
    flag: Maybe<Scalars['String']>;
    linkedFrom: Maybe<RedirectRuleLinkingCollections>;
    matchBy: Maybe<Scalars['String']>;
    name: Maybe<Scalars['String']>;
    redirectCode: Maybe<Scalars['String']>;
    subject: Maybe<Scalars['String']>;
    sys: Sys;
    value: Maybe<Scalars['String']>;
  };

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleDestinationArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleFlagArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleMatchByArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleRedirectCodeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleSubjectArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Rules for Redirect Entity - used for redirects handled by Teliacompany.com domain [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/redirectRule) */
export type RedirectRuleValueArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type RedirectRuleCollection = {
  __typename: 'RedirectRuleCollection';
  items: Array<Maybe<RedirectRule>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type RedirectRuleFilter = {
  AND: InputMaybe<Array<InputMaybe<RedirectRuleFilter>>>;
  Destination: InputMaybe<Scalars['String']>;
  Destination_contains: InputMaybe<Scalars['String']>;
  Destination_exists: InputMaybe<Scalars['Boolean']>;
  Destination_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  Destination_not: InputMaybe<Scalars['String']>;
  Destination_not_contains: InputMaybe<Scalars['String']>;
  Destination_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  OR: InputMaybe<Array<InputMaybe<RedirectRuleFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  flag: InputMaybe<Scalars['String']>;
  flag_contains: InputMaybe<Scalars['String']>;
  flag_exists: InputMaybe<Scalars['Boolean']>;
  flag_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flag_not: InputMaybe<Scalars['String']>;
  flag_not_contains: InputMaybe<Scalars['String']>;
  flag_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchBy: InputMaybe<Scalars['String']>;
  matchBy_contains: InputMaybe<Scalars['String']>;
  matchBy_exists: InputMaybe<Scalars['Boolean']>;
  matchBy_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchBy_not: InputMaybe<Scalars['String']>;
  matchBy_not_contains: InputMaybe<Scalars['String']>;
  matchBy_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirectCode: InputMaybe<Scalars['String']>;
  redirectCode_contains: InputMaybe<Scalars['String']>;
  redirectCode_exists: InputMaybe<Scalars['Boolean']>;
  redirectCode_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirectCode_not: InputMaybe<Scalars['String']>;
  redirectCode_not_contains: InputMaybe<Scalars['String']>;
  redirectCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subject: InputMaybe<Scalars['String']>;
  subject_contains: InputMaybe<Scalars['String']>;
  subject_exists: InputMaybe<Scalars['Boolean']>;
  subject_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subject_not: InputMaybe<Scalars['String']>;
  subject_not_contains: InputMaybe<Scalars['String']>;
  subject_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RedirectRuleLinkingCollections = {
  __typename: 'RedirectRuleLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  redirectEntityCollection: Maybe<RedirectEntityCollection>;
};

export type RedirectRuleLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type RedirectRuleLinkingCollectionsRedirectEntityCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<RedirectRuleLinkingCollectionsRedirectEntityCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum RedirectRuleLinkingCollectionsRedirectEntityCollectionOrder {
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum RedirectRuleOrder {
  DestinationAsc = 'Destination_ASC',
  DestinationDesc = 'Destination_DESC',
  FlagAsc = 'flag_ASC',
  FlagDesc = 'flag_DESC',
  MatchByAsc = 'matchBy_ASC',
  MatchByDesc = 'matchBy_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RedirectCodeAsc = 'redirectCode_ASC',
  RedirectCodeDesc = 'redirectCode_DESC',
  SubjectAsc = 'subject_ASC',
  SubjectDesc = 'subject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type Resource = Entry &
  _Node & {
    __typename: 'Resource';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    key: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ResourceLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    value: Maybe<Scalars['String']>;
  };

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type ResourceInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type ResourceKeyArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type ResourceLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type ResourceReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content type for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resource) */
export type ResourceValueArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type ResourceCollection = {
  __typename: 'ResourceCollection';
  items: Array<Maybe<Resource>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResourceFilter = {
  AND: InputMaybe<Array<InputMaybe<ResourceFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ResourceFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key: InputMaybe<Scalars['String']>;
  key_contains: InputMaybe<Scalars['String']>;
  key_exists: InputMaybe<Scalars['Boolean']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not: InputMaybe<Scalars['String']>;
  key_not_contains: InputMaybe<Scalars['String']>;
  key_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceLinkingCollections = {
  __typename: 'ResourceLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  resourceSetCollection: Maybe<ResourceSetCollection>;
};

export type ResourceLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type ResourceLinkingCollectionsResourceSetCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceLinkingCollectionsResourceSetCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ResourceLinkingCollectionsResourceSetCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ResourceOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSet = Entry &
  _Node & {
    __typename: 'ResourceSet';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<ResourceSetLinkingCollections>;
    name: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    resourcesCollection: Maybe<ResourceSetResourcesCollection>;
    sys: Sys;
  };

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSetInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSetLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSetNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSetReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Resource assembler for microcopy [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/resourceSet) */
export type ResourceSetResourcesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<ResourceSetResourcesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ResourceFilter>;
};

export type ResourceSetCollection = {
  __typename: 'ResourceSetCollection';
  items: Array<Maybe<ResourceSet>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type ResourceSetFilter = {
  AND: InputMaybe<Array<InputMaybe<ResourceSetFilter>>>;
  OR: InputMaybe<Array<InputMaybe<ResourceSetFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resources: InputMaybe<CfResourceNestedFilter>;
  resourcesCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type ResourceSetLinkingCollections = {
  __typename: 'ResourceSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type ResourceSetLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum ResourceSetOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ResourceSetResourcesCollection = {
  __typename: 'ResourceSetResourcesCollection';
  items: Array<Maybe<Resource>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ResourceSetResourcesCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ResourceSys = {
  __typename: 'ResourceSys';
  linkType: Scalars['String'];
  urn: Scalars['String'];
};

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssembly = Entry &
  _Node & {
    __typename: 'SectionAssembly';
    _id: Scalars['ID'];
    contentCollection: Maybe<SectionAssemblyContentCollection>;
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<SectionAssemblyDescription>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<SectionAssemblyLinkingCollections>;
    sys: Sys;
    type: Maybe<Scalars['String']>;
  };

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssemblyContentCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SectionAssemblyContentFilter>;
};

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssemblyDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssemblyInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssemblyLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Wrapper used for grouping other content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/sectionAssembly) */
export type SectionAssemblyTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type SectionAssemblyCollection = {
  __typename: 'SectionAssemblyCollection';
  items: Array<Maybe<SectionAssembly>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SectionAssemblyContentCollection = {
  __typename: 'SectionAssemblyContentCollection';
  items: Array<Maybe<SectionAssemblyContentItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SectionAssemblyContentFilter = {
  AND: InputMaybe<Array<InputMaybe<SectionAssemblyContentFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SectionAssemblyContentFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type SectionAssemblyContentItem =
  | Article
  | AssemblyCollectionPage
  | AssemblyLevel0
  | AssemblyLevel1
  | AssemblyReportsAndPresentations
  | AssemblySolutionsFlexible
  | NewsroomAssembly
  | Person;

export type SectionAssemblyDescription = {
  __typename: 'SectionAssemblyDescription';
  json: Scalars['JSON'];
  links: SectionAssemblyDescriptionLinks;
};

export type SectionAssemblyDescriptionAssets = {
  __typename: 'SectionAssemblyDescriptionAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type SectionAssemblyDescriptionEntries = {
  __typename: 'SectionAssemblyDescriptionEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type SectionAssemblyDescriptionLinks = {
  __typename: 'SectionAssemblyDescriptionLinks';
  assets: SectionAssemblyDescriptionAssets;
  entries: SectionAssemblyDescriptionEntries;
  resources: SectionAssemblyDescriptionResources;
};

export type SectionAssemblyDescriptionResources = {
  __typename: 'SectionAssemblyDescriptionResources';
  block: Array<SectionAssemblyDescriptionResourcesBlock>;
  hyperlink: Array<SectionAssemblyDescriptionResourcesHyperlink>;
  inline: Array<SectionAssemblyDescriptionResourcesInline>;
};

export type SectionAssemblyDescriptionResourcesBlock = ResourceLink & {
  __typename: 'SectionAssemblyDescriptionResourcesBlock';
  sys: ResourceSys;
};

export type SectionAssemblyDescriptionResourcesHyperlink = ResourceLink & {
  __typename: 'SectionAssemblyDescriptionResourcesHyperlink';
  sys: ResourceSys;
};

export type SectionAssemblyDescriptionResourcesInline = ResourceLink & {
  __typename: 'SectionAssemblyDescriptionResourcesInline';
  sys: ResourceSys;
};

export type SectionAssemblyFilter = {
  AND: InputMaybe<Array<InputMaybe<SectionAssemblyFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SectionAssemblyFilter>>>;
  content: InputMaybe<CfcontentMultiTypeNestedFilter>;
  contentCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SectionAssemblyLinkingCollections = {
  __typename: 'SectionAssemblyLinkingCollections';
  assemblyCollectionPageCollection: Maybe<AssemblyCollectionPageCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type SectionAssemblyLinkingCollectionsAssemblyCollectionPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<SectionAssemblyLinkingCollectionsAssemblyCollectionPageCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SectionAssemblyLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum SectionAssemblyLinkingCollectionsAssemblyCollectionPageCollectionOrder {
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionAssemblyOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type Seo = Entry &
  _Node & {
    __typename: 'Seo';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    keywords: Maybe<Array<Maybe<Scalars['String']>>>;
    linkedFrom: Maybe<SeoLinkingCollections>;
    noFollow: Maybe<Scalars['Boolean']>;
    noIndex: Maybe<Scalars['Boolean']>;
    readableId: Maybe<Scalars['String']>;
    socialMediaDescription: Maybe<Scalars['String']>;
    socialMediaImage: Maybe<Image>;
    socialMediaSiteName: Maybe<Scalars['String']>;
    socialMediaTitle: Maybe<Scalars['String']>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
    xRobotsTag: Maybe<Scalars['DateTime']>;
  };

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoKeywordsArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoNoFollowArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoNoIndexArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoSocialMediaDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoSocialMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoSocialMediaSiteNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoSocialMediaTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/seo) */
export type SeoXRobotsTagArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type SeoCollection = {
  __typename: 'SeoCollection';
  items: Array<Maybe<Seo>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SeoFilter = {
  AND: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SeoFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists: InputMaybe<Scalars['Boolean']>;
  no_follow: InputMaybe<Scalars['Boolean']>;
  no_follow_exists: InputMaybe<Scalars['Boolean']>;
  no_follow_not: InputMaybe<Scalars['Boolean']>;
  no_index: InputMaybe<Scalars['Boolean']>;
  no_index_exists: InputMaybe<Scalars['Boolean']>;
  no_index_not: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription: InputMaybe<Scalars['String']>;
  socialMediaDescription_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaDescription_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription_not: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaImage: InputMaybe<CfImageNestedFilter>;
  socialMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName: InputMaybe<Scalars['String']>;
  socialMediaSiteName_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSiteName_not: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle: InputMaybe<Scalars['String']>;
  socialMediaTitle_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle_not: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xRobotsTag: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_exists: InputMaybe<Scalars['Boolean']>;
  xRobotsTag_gt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_gte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  xRobotsTag_lt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_lte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type SeoLinkingCollections = {
  __typename: 'SeoLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  assemblyCareersAboutLifeAtTeliaCollection: Maybe<AssemblyCareersAboutLifeAtTeliaCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  assemblyCareersTeamsPageCollection: Maybe<AssemblyCareersTeamsPageCollection>;
  assemblyCollectionPageCollection: Maybe<AssemblyCollectionPageCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel1Collection: Maybe<AssemblyLevel1Collection>;
  assemblyOpenPositionsCollection: Maybe<AssemblyOpenPositionsCollection>;
  assemblyReportsAndPresentationsCollection: Maybe<AssemblyReportsAndPresentationsCollection>;
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  boardMemberCollection: Maybe<BoardMemberCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
  eventCollection: Maybe<EventCollection>;
  newsroomAssemblyCollection: Maybe<NewsroomAssemblyCollection>;
  personCollection: Maybe<PersonCollection>;
  supportCompositionCollection: Maybe<SupportCompositionCollection>;
};

export type SeoLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<SeoLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyCareersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyCareersTeamsPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<SeoLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyCollectionPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyCollectionPageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyLevel1CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyLevel1CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyOpenPositionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblyOpenPositionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblyReportsAndPresentationsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<SeoLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsAssemblySolutionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<SeoLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsBoardMemberCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsBoardMemberCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsEventCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsEventCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsNewsroomAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsNewsroomAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsPersonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsPersonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SeoLinkingCollectionsSupportCompositionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SeoLinkingCollectionsSupportCompositionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum SeoLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum SeoLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyCareersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyCollectionPageCollectionOrder {
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export enum SeoLinkingCollectionsAssemblyLevel1CollectionOrder {
  BottomCarouselHeadingAsc = 'bottomCarouselHeading_ASC',
  BottomCarouselHeadingDesc = 'bottomCarouselHeading_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TableToggleAsc = 'tableToggle_ASC',
  TableToggleDesc = 'tableToggle_DESC',
  TopFirstCarouselHeadingAsc = 'topFirstCarouselHeading_ASC',
  TopFirstCarouselHeadingDesc = 'topFirstCarouselHeading_DESC',
  TopSecondCarouselHeadingAsc = 'topSecondCarouselHeading_ASC',
  TopSecondCarouselHeadingDesc = 'topSecondCarouselHeading_DESC',
}

export enum SeoLinkingCollectionsAssemblyOpenPositionsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
}

export enum SeoLinkingCollectionsAssemblySolutionsCollectionOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum SeoLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum SeoLinkingCollectionsBoardMemberCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum SeoLinkingCollectionsEventCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum SeoLinkingCollectionsNewsroomAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsPersonCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoLinkingCollectionsSupportCompositionCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SeoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SocialMediaSiteNameAsc = 'socialMediaSiteName_ASC',
  SocialMediaSiteNameDesc = 'socialMediaSiteName_DESC',
  SocialMediaTitleAsc = 'socialMediaTitle_ASC',
  SocialMediaTitleDesc = 'socialMediaTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  XRobotsTagAsc = 'xRobotsTag_ASC',
  XRobotsTagDesc = 'xRobotsTag_DESC',
}

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportComposition = Entry &
  _Node & {
    __typename: 'SupportComposition';
    _id: Scalars['ID'];
    category: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<SupportCompositionLinkingCollections>;
    mainContentCollection: Maybe<SupportCompositionMainContentCollection>;
    mediaImageCollection: Maybe<SupportCompositionMediaImageCollection>;
    mediaVideoCollection: Maybe<SupportCompositionMediaVideoCollection>;
    preamble: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    seoMetadata: Maybe<Seo>;
    slug: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<SupportCompositionTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionCategoryArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionMainContentCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionMediaImageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SupportCompositionMediaImageCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageFilter>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionMediaVideoCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SupportCompositionMediaVideoCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoFilter>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionSeoMetadataArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoFilter>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionSlugArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<SupportCompositionTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** A composition of building blocks turned into a comprehensive guide [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/supportComposition) */
export type SupportCompositionTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type SupportCompositionCollection = {
  __typename: 'SupportCompositionCollection';
  items: Array<Maybe<SupportComposition>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SupportCompositionFilter = {
  AND: InputMaybe<Array<InputMaybe<SupportCompositionFilter>>>;
  OR: InputMaybe<Array<InputMaybe<SupportCompositionFilter>>>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mainContentCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage: InputMaybe<CfImageNestedFilter>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo: InputMaybe<CfVideoNestedFilter>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata: InputMaybe<CfSeoNestedFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SupportCompositionLinkingCollections = {
  __typename: 'SupportCompositionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type SupportCompositionLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type SupportCompositionMainContentCollection = {
  __typename: 'SupportCompositionMainContentCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type SupportCompositionMediaImageCollection = {
  __typename: 'SupportCompositionMediaImageCollection';
  items: Array<Maybe<Image>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SupportCompositionMediaImageCollectionOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  FocusAreaAsc = 'focusArea_ASC',
  FocusAreaDesc = 'focusArea_DESC',
  HyperlinkAsc = 'hyperlink_ASC',
  HyperlinkDesc = 'hyperlink_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SupportCompositionMediaVideoCollection = {
  __typename: 'SupportCompositionMediaVideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SupportCompositionMediaVideoCollectionOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SupportCompositionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SupportCompositionTagsCollection = {
  __typename: 'SupportCompositionTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SupportCompositionTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type Sys = {
  __typename: 'Sys';
  environmentId: Scalars['String'];
  firstPublishedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The locale that was requested. */
  locale: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  publishedVersion: Maybe<Scalars['Int']>;
  spaceId: Scalars['String'];
};

export type SysFilter = {
  firstPublishedAt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_exists: InputMaybe<Scalars['Boolean']>;
  firstPublishedAt_gt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_gte: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  firstPublishedAt_lt: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_lte: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  id: InputMaybe<Scalars['String']>;
  id_contains: InputMaybe<Scalars['String']>;
  id_exists: InputMaybe<Scalars['Boolean']>;
  id_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id_not: InputMaybe<Scalars['String']>;
  id_not_contains: InputMaybe<Scalars['String']>;
  id_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishedAt: InputMaybe<Scalars['DateTime']>;
  publishedAt_exists: InputMaybe<Scalars['Boolean']>;
  publishedAt_gt: InputMaybe<Scalars['DateTime']>;
  publishedAt_gte: InputMaybe<Scalars['DateTime']>;
  publishedAt_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedAt_lt: InputMaybe<Scalars['DateTime']>;
  publishedAt_lte: InputMaybe<Scalars['DateTime']>;
  publishedAt_not: InputMaybe<Scalars['DateTime']>;
  publishedAt_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedVersion: InputMaybe<Scalars['Float']>;
  publishedVersion_exists: InputMaybe<Scalars['Boolean']>;
  publishedVersion_gt: InputMaybe<Scalars['Float']>;
  publishedVersion_gte: InputMaybe<Scalars['Float']>;
  publishedVersion_in: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  publishedVersion_lt: InputMaybe<Scalars['Float']>;
  publishedVersion_lte: InputMaybe<Scalars['Float']>;
  publishedVersion_not: InputMaybe<Scalars['Float']>;
  publishedVersion_not_in: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type Table = Entry &
  _Node & {
    __typename: 'Table';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TableLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    richTableContent: Maybe<TableRichTableContent>;
    sys: Sys;
    tagsCollection: Maybe<TableTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableRichTableContentArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TableTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** A standard table to arrange data into rows and columns [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/table) */
export type TableTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TableCollection = {
  __typename: 'TableCollection';
  items: Array<Maybe<Table>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TableFilter = {
  AND: InputMaybe<Array<InputMaybe<TableFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TableFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  richTableContent_contains: InputMaybe<Scalars['String']>;
  richTableContent_exists: InputMaybe<Scalars['Boolean']>;
  richTableContent_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TableLinkingCollections = {
  __typename: 'TableLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type TableLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TableOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TableRichTableContent = {
  __typename: 'TableRichTableContent';
  json: Scalars['JSON'];
  links: TableRichTableContentLinks;
};

export type TableRichTableContentAssets = {
  __typename: 'TableRichTableContentAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TableRichTableContentEntries = {
  __typename: 'TableRichTableContentEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TableRichTableContentLinks = {
  __typename: 'TableRichTableContentLinks';
  assets: TableRichTableContentAssets;
  entries: TableRichTableContentEntries;
  resources: TableRichTableContentResources;
};

export type TableRichTableContentResources = {
  __typename: 'TableRichTableContentResources';
  block: Array<TableRichTableContentResourcesBlock>;
  hyperlink: Array<TableRichTableContentResourcesHyperlink>;
  inline: Array<TableRichTableContentResourcesInline>;
};

export type TableRichTableContentResourcesBlock = ResourceLink & {
  __typename: 'TableRichTableContentResourcesBlock';
  sys: ResourceSys;
};

export type TableRichTableContentResourcesHyperlink = ResourceLink & {
  __typename: 'TableRichTableContentResourcesHyperlink';
  sys: ResourceSys;
};

export type TableRichTableContentResourcesInline = ResourceLink & {
  __typename: 'TableRichTableContentResourcesInline';
  sys: ResourceSys;
};

export type TableTagsCollection = {
  __typename: 'TableTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TableTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type Tag = Entry &
  _Node & {
    __typename: 'Tag';
    _id: Scalars['ID'];
    category: Maybe<Scalars['String']>;
    contentfulMetadata: ContentfulMetadata;
    excludePersonalisationSegmentsCollection: Maybe<TagExcludePersonalisationSegmentsCollection>;
    linkedFrom: Maybe<TagLinkingCollections>;
    name: Maybe<Scalars['String']>;
    personalisationSegmentsCollection: Maybe<TagPersonalisationSegmentsCollection>;
    sys: Sys;
    value: Maybe<Scalars['String']>;
  };

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagCategoryArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagExcludePersonalisationSegmentsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagExcludePersonalisationSegmentsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PersonalisationSegmentsFilter>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagPersonalisationSegmentsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagPersonalisationSegmentsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PersonalisationSegmentsFilter>;
};

/** Tags for categorization of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tag) */
export type TagValueArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TagCollection = {
  __typename: 'TagCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TagExcludePersonalisationSegmentsCollection = {
  __typename: 'TagExcludePersonalisationSegmentsCollection';
  items: Array<Maybe<PersonalisationSegments>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TagExcludePersonalisationSegmentsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TagFilter = {
  AND: InputMaybe<Array<InputMaybe<TagFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TagFilter>>>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  excludePersonalisationSegments: InputMaybe<CfPersonalisationSegmentsNestedFilter>;
  excludePersonalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  personalisationSegments: InputMaybe<CfPersonalisationSegmentsNestedFilter>;
  personalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagLinkingCollections = {
  __typename: 'TagLinkingCollections';
  alertNotificationCollection: Maybe<AlertNotificationCollection>;
  articleCollection: Maybe<ArticleCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  personCollection: Maybe<PersonCollection>;
  supportCompositionCollection: Maybe<SupportCompositionCollection>;
  tableCollection: Maybe<TableCollection>;
  tagWrapperCollection: Maybe<TagWrapperCollection>;
  teaserCollection: Maybe<TeaserCollection>;
  teaserWrapperCollection: Maybe<TeaserWrapperCollection>;
};

export type TagLinkingCollectionsAlertNotificationCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsAlertNotificationCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsPersonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsPersonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsSupportCompositionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsSupportCompositionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTableCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTableCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTagWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTagWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TagLinkingCollectionsTeaserWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagLinkingCollectionsTeaserWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TagLinkingCollectionsAlertNotificationCollectionOrder {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsPersonCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagLinkingCollectionsSupportCompositionCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTableCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTagWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsTeaserWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TagPersonalisationSegmentsCollection = {
  __typename: 'TagPersonalisationSegmentsCollection';
  items: Array<Maybe<PersonalisationSegments>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TagPersonalisationSegmentsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapper = Entry &
  _Node & {
    __typename: 'TagWrapper';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TagWrapperLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<TagWrapperTagsCollection>;
    title: Maybe<Scalars['String']>;
  };

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapperTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TagWrapperTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** A container for grouping multiple tags [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/tagWrapper) */
export type TagWrapperTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TagWrapperCollection = {
  __typename: 'TagWrapperCollection';
  items: Array<Maybe<TagWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TagWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<TagWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TagWrapperFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagWrapperLinkingCollections = {
  __typename: 'TagWrapperLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type TagWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TagWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TagWrapperTagsCollection = {
  __typename: 'TagWrapperTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TagWrapperTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/**
 * Represents a taxonomy concept entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
  __typename: 'TaxonomyConcept';
  id: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapper = Entry &
  _Node & {
    __typename: 'TeamCardsWrapper';
    _id: Scalars['ID'];
    cardsCollection: Maybe<TeamCardsWrapperCardsCollection>;
    contentfulMetadata: ContentfulMetadata;
    cta: Maybe<CtaButton>;
    linkedFrom: Maybe<TeamCardsWrapperLinkingCollections>;
    preamble: Maybe<Scalars['String']>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
  };

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapperCardsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeamCardsWrapperCardsFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapperCtaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapperPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teamCardsWrapper) */
export type TeamCardsWrapperTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TeamCardsWrapperCardsCollection = {
  __typename: 'TeamCardsWrapperCardsCollection';
  items: Array<Maybe<TeamCardsWrapperCardsItem>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TeamCardsWrapperCardsFilter = {
  AND: InputMaybe<Array<InputMaybe<TeamCardsWrapperCardsFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TeamCardsWrapperCardsFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TeamCardsWrapperCardsItem = Article | Teaser;

export type TeamCardsWrapperCollection = {
  __typename: 'TeamCardsWrapperCollection';
  items: Array<Maybe<TeamCardsWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TeamCardsWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<TeamCardsWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TeamCardsWrapperFilter>>>;
  cards: InputMaybe<CfcardsMultiTypeNestedFilter>;
  cardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  cta: InputMaybe<CfCtaButtonNestedFilter>;
  cta_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TeamCardsWrapperLinkingCollections = {
  __typename: 'TeamCardsWrapperLinkingCollections';
  assemblyCareersAboutLifeAtTeliaCollection: Maybe<AssemblyCareersAboutLifeAtTeliaCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type TeamCardsWrapperLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<
      InputMaybe<TeamCardsWrapperLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder>
    >
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeamCardsWrapperLinkingCollectionsAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeamCardsWrapperLinkingCollectionsAssemblyCareersCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeamCardsWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TeamCardsWrapperLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeamCardsWrapperLinkingCollectionsAssemblyCareersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeamCardsWrapperOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type Teaser = Entry &
  _Node & {
    __typename: 'Teaser';
    _id: Scalars['ID'];
    backgroundMedia: Maybe<Image> | null;
    callToActionCollection: Maybe<TeaserCallToActionCollection>;
    contentfulMetadata: ContentfulMetadata;
    embedMedia: Maybe<EmbedMedia> | null;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TeaserLinkingCollections>;
    primaryMediaImage: Maybe<Image> | null;
    primaryMediaVideo: Maybe<Video> | null;
    richPreamble: Maybe<TeaserRichPreamble> | null;
    sys: Sys;
    tagline: Maybe<Scalars['String']> | null;
    taglineType: Maybe<Scalars['String']>;
    tagsCollection: Maybe<TeaserTagsCollection>;
    teaserUrl: Maybe<Scalars['String']> | null;
    title: Maybe<Scalars['String']>;
    uniqueSellingPointsCollection: Maybe<TeaserUniqueSellingPointsCollection>;
  };

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserBackgroundMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserCallToActionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserCallToActionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<CtaButtonFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserEmbedMediaArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<EmbedMediaFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserPrimaryMediaImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserPrimaryMediaVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserRichPreambleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserTaglineArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserTaglineTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserTeaserUrlArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Content linking to another piece of content [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaser) */
export type TeaserUniqueSellingPointsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserUniqueSellingPointsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityFilter>;
};

export type TeaserCallToActionCollection = {
  __typename: 'TeaserCallToActionCollection';
  items: Array<Maybe<CtaButton>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserCallToActionCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TeaserCollection = {
  __typename: 'TeaserCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TeaserFilter = {
  AND: InputMaybe<Array<InputMaybe<TeaserFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TeaserFilter>>>;
  backgroundMedia: InputMaybe<CfImageNestedFilter>;
  backgroundMedia_exists: InputMaybe<Scalars['Boolean']>;
  callToAction: InputMaybe<CfCtaButtonNestedFilter>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia: InputMaybe<CfEmbedMediaNestedFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryMediaImage: InputMaybe<CfImageNestedFilter>;
  primaryMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  primaryMediaVideo: InputMaybe<CfVideoNestedFilter>;
  primaryMediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_contains: InputMaybe<Scalars['String']>;
  richPreamble_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tagline: InputMaybe<Scalars['String']>;
  taglineType: InputMaybe<Scalars['String']>;
  taglineType_contains: InputMaybe<Scalars['String']>;
  taglineType_exists: InputMaybe<Scalars['Boolean']>;
  taglineType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taglineType_not: InputMaybe<Scalars['String']>;
  taglineType_not_contains: InputMaybe<Scalars['String']>;
  taglineType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_contains: InputMaybe<Scalars['String']>;
  tagline_exists: InputMaybe<Scalars['Boolean']>;
  tagline_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_not: InputMaybe<Scalars['String']>;
  tagline_not_contains: InputMaybe<Scalars['String']>;
  tagline_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl: InputMaybe<Scalars['String']>;
  teaserUrl_contains: InputMaybe<Scalars['String']>;
  teaserUrl_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teaserUrl_not: InputMaybe<Scalars['String']>;
  teaserUrl_not_contains: InputMaybe<Scalars['String']>;
  teaserUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueSellingPoints: InputMaybe<CfUspEntityNestedFilter>;
  uniqueSellingPointsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type TeaserLinkingCollections = {
  __typename: 'TeaserLinkingCollections';
  annualSustainabilityReportCollection: Maybe<AnnualSustainabilityReportCollection>;
  assemblyCareersAboutLifeAtTeliaCollection: Maybe<AssemblyCareersAboutLifeAtTeliaCollection>;
  assemblyCareersCollection: Maybe<AssemblyCareersCollection>;
  assemblyCareersTeamsPageCollection: Maybe<AssemblyCareersTeamsPageCollection>;
  assemblyCollectionPageCollection: Maybe<AssemblyCollectionPageCollection>;
  assemblyLevel0Collection: Maybe<AssemblyLevel0Collection>;
  assemblyLevel1Collection: Maybe<AssemblyLevel1Collection>;
  assemblyMenuTeasersCollection: Maybe<AssemblyMenuTeasersCollection>;
  assemblyOpenPositionsCollection: Maybe<AssemblyOpenPositionsCollection>;
  assemblyReportsAndPresentationsCollection: Maybe<AssemblyReportsAndPresentationsCollection>;
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  codeOfConductCollection: Maybe<CodeOfConductCollection>;
  entryCollection: Maybe<EntryCollection>;
  newsroomAssemblyCollection: Maybe<NewsroomAssemblyCollection>;
  teamCardsWrapperCollection: Maybe<TeamCardsWrapperCollection>;
  teaserWrapperCollection: Maybe<TeaserWrapperCollection>;
};

export type TeaserLinkingCollectionsAnnualSustainabilityReportCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAnnualSustainabilityReportCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyCareersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyCareersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyCareersTeamsPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyCollectionPageCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyCollectionPageCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyLevel0CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyLevel0CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyLevel1CollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyLevel1CollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyMenuTeasersCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblyMenuTeasersCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyOpenPositionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyOpenPositionsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblyReportsAndPresentationsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsAssemblySolutionsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<TeaserLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsCodeOfConductCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsCodeOfConductCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsNewsroomAssemblyCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsNewsroomAssemblyCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsTeamCardsWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsTeamCardsWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserLinkingCollectionsTeaserWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserLinkingCollectionsTeaserWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TeaserLinkingCollectionsAnnualSustainabilityReportCollectionOrder {
  IntroPreambleAsc = 'introPreamble_ASC',
  IntroPreambleDesc = 'introPreamble_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
}

export enum TeaserLinkingCollectionsAssemblyCareersAboutLifeAtTeliaCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyCareersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  StoryCardsTitleAsc = 'storyCardsTitle_ASC',
  StoryCardsTitleDesc = 'storyCardsTitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyCareersTeamsPageCollectionOrder {
  CardsTitleAsc = 'cardsTitle_ASC',
  CardsTitleDesc = 'cardsTitle_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyCollectionPageCollectionOrder {
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyLevel0CollectionOrder {
  BottomCardsHeadingAsc = 'bottomCardsHeading_ASC',
  BottomCardsHeadingDesc = 'bottomCardsHeading_DESC',
  FilteredItemsHeadingAsc = 'filteredItemsHeading_ASC',
  FilteredItemsHeadingDesc = 'filteredItemsHeading_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCarouselHeadingAsc = 'topCarouselHeading_ASC',
  TopCarouselHeadingDesc = 'topCarouselHeading_DESC',
}

export enum TeaserLinkingCollectionsAssemblyLevel1CollectionOrder {
  BottomCarouselHeadingAsc = 'bottomCarouselHeading_ASC',
  BottomCarouselHeadingDesc = 'bottomCarouselHeading_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TableHeadingAsc = 'tableHeading_ASC',
  TableHeadingDesc = 'tableHeading_DESC',
  TableToggleAsc = 'tableToggle_ASC',
  TableToggleDesc = 'tableToggle_DESC',
  TopFirstCarouselHeadingAsc = 'topFirstCarouselHeading_ASC',
  TopFirstCarouselHeadingDesc = 'topFirstCarouselHeading_DESC',
  TopSecondCarouselHeadingAsc = 'topSecondCarouselHeading_ASC',
  TopSecondCarouselHeadingDesc = 'topSecondCarouselHeading_DESC',
}

export enum TeaserLinkingCollectionsAssemblyMenuTeasersCollectionOrder {
  MenuTeasersAsc = 'menuTeasers_ASC',
  MenuTeasersDesc = 'menuTeasers_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyOpenPositionsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsAssemblyReportsAndPresentationsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
}

export enum TeaserLinkingCollectionsAssemblySolutionsCollectionOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum TeaserLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum TeaserLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TeaserLinkingCollectionsCodeOfConductCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsNewsroomAssemblyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeaserLinkingCollectionsTeamCardsWrapperCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TeaserLinkingCollectionsTeaserWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TeaserOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TeaserRichPreamble = {
  __typename: 'TeaserRichPreamble';
  json: Scalars['JSON'];
  links: TeaserRichPreambleLinks;
};

export type TeaserRichPreambleAssets = {
  __typename: 'TeaserRichPreambleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TeaserRichPreambleEntries = {
  __typename: 'TeaserRichPreambleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TeaserRichPreambleLinks = {
  __typename: 'TeaserRichPreambleLinks';
  assets: TeaserRichPreambleAssets;
  entries: TeaserRichPreambleEntries;
  resources: TeaserRichPreambleResources;
};

export type TeaserRichPreambleResources = {
  __typename: 'TeaserRichPreambleResources';
  block: Array<TeaserRichPreambleResourcesBlock>;
  hyperlink: Array<TeaserRichPreambleResourcesHyperlink>;
  inline: Array<TeaserRichPreambleResourcesInline>;
};

export type TeaserRichPreambleResourcesBlock = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesBlock';
  sys: ResourceSys;
};

export type TeaserRichPreambleResourcesHyperlink = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesHyperlink';
  sys: ResourceSys;
};

export type TeaserRichPreambleResourcesInline = ResourceLink & {
  __typename: 'TeaserRichPreambleResourcesInline';
  sys: ResourceSys;
};

export type TeaserTagsCollection = {
  __typename: 'TeaserTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TeaserUniqueSellingPointsCollection = {
  __typename: 'TeaserUniqueSellingPointsCollection';
  items: Array<Maybe<UspEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserUniqueSellingPointsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapper = Entry &
  _Node & {
    __typename: 'TeaserWrapper';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<TeaserWrapperLinkingCollections>;
    name: Maybe<Scalars['String']>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    tagsCollection: Maybe<TeaserWrapperTagsCollection>;
    teaserListCollection: Maybe<TeaserWrapperTeaserListCollection>;
    type: Maybe<Scalars['String']>;
  };

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperTagsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserWrapperTagsCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TagFilter>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperTeaserListCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserWrapperTeaserListCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TeaserFilter>;
};

/** Wraps teasers into a list [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/teaserWrapper) */
export type TeaserWrapperTypeArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type TeaserWrapperCollection = {
  __typename: 'TeaserWrapperCollection';
  items: Array<Maybe<TeaserWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type TeaserWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<TeaserWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<TeaserWrapperFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tags: InputMaybe<CfTagNestedFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserList: InputMaybe<CfTeaserNestedFilter>;
  teaserListCollection_exists: InputMaybe<Scalars['Boolean']>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TeaserWrapperLinkingCollections = {
  __typename: 'TeaserWrapperLinkingCollections';
  campaignCollection: Maybe<CampaignCollection>;
  entryCollection: Maybe<EntryCollection>;
};

export type TeaserWrapperLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<TeaserWrapperLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TeaserWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum TeaserWrapperLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TeaserWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type TeaserWrapperTagsCollection = {
  __typename: 'TeaserWrapperTagsCollection';
  items: Array<Maybe<Tag>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserWrapperTagsCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TeaserWrapperTeaserListCollection = {
  __typename: 'TeaserWrapperTeaserListCollection';
  items: Array<Maybe<Teaser>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TeaserWrapperTeaserListCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntity = Entry &
  _Node & {
    __typename: 'UspEntity';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    description: Maybe<Scalars['String']>;
    icon: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    link: Maybe<Scalars['String']>;
    linkedFrom: Maybe<UspEntityLinkingCollections>;
    sys: Sys;
    title: Maybe<Scalars['String']>;
  };

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityDescriptionArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityIconArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityLinkArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A single Unique Selling Point [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntity) */
export type UspEntityTitleArgs = {
  locale: InputMaybe<Scalars['String']>;
};

export type UspEntityCollection = {
  __typename: 'UspEntityCollection';
  items: Array<Maybe<UspEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type UspEntityFilter = {
  AND: InputMaybe<Array<InputMaybe<UspEntityFilter>>>;
  OR: InputMaybe<Array<InputMaybe<UspEntityFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon: InputMaybe<Scalars['String']>;
  icon_contains: InputMaybe<Scalars['String']>;
  icon_exists: InputMaybe<Scalars['Boolean']>;
  icon_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not: InputMaybe<Scalars['String']>;
  icon_not_contains: InputMaybe<Scalars['String']>;
  icon_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link: InputMaybe<Scalars['String']>;
  link_contains: InputMaybe<Scalars['String']>;
  link_exists: InputMaybe<Scalars['Boolean']>;
  link_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not: InputMaybe<Scalars['String']>;
  link_not_contains: InputMaybe<Scalars['String']>;
  link_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UspEntityLinkingCollections = {
  __typename: 'UspEntityLinkingCollections';
  assemblySolutionsCollection: Maybe<AssemblySolutionsCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  entryCollection: Maybe<EntryCollection>;
  teaserCollection: Maybe<TeaserCollection>;
  uspEntityWrapperCollection: Maybe<UspEntityWrapperCollection>;
};

export type UspEntityLinkingCollectionsAssemblySolutionsCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<UspEntityLinkingCollectionsAssemblySolutionsCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type UspEntityLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<UspEntityLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type UspEntityLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type UspEntityLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<UspEntityLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type UspEntityLinkingCollectionsUspEntityWrapperCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<UspEntityLinkingCollectionsUspEntityWrapperCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum UspEntityLinkingCollectionsAssemblySolutionsCollectionOrder {
  AlternatingTeasersTitleAsc = 'alternatingTeasersTitle_ASC',
  AlternatingTeasersTitleDesc = 'alternatingTeasersTitle_DESC',
  BottomCardsTitleAsc = 'bottomCardsTitle_ASC',
  BottomCardsTitleDesc = 'bottomCardsTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UspCardsTitleAsc = 'uspCardsTitle_ASC',
  UspCardsTitleDesc = 'uspCardsTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum UspEntityLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum UspEntityLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum UspEntityLinkingCollectionsUspEntityWrapperCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum UspEntityOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A wrapper containing multiple USP Entities [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntityWrapper) */
export type UspEntityWrapper = Entry &
  _Node & {
    __typename: 'UspEntityWrapper';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<UspEntityWrapperLinkingCollections>;
    readableId: Maybe<Scalars['String']>;
    sys: Sys;
    uspEntriesCollection: Maybe<UspEntityWrapperUspEntriesCollection>;
  };

/** A wrapper containing multiple USP Entities [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntityWrapper) */
export type UspEntityWrapperInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A wrapper containing multiple USP Entities [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntityWrapper) */
export type UspEntityWrapperLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A wrapper containing multiple USP Entities [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntityWrapper) */
export type UspEntityWrapperReadableIdArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** A wrapper containing multiple USP Entities [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/uspEntityWrapper) */
export type UspEntityWrapperUspEntriesCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<UspEntityWrapperUspEntriesCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<UspEntityFilter>;
};

export type UspEntityWrapperCollection = {
  __typename: 'UspEntityWrapperCollection';
  items: Array<Maybe<UspEntityWrapper>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type UspEntityWrapperFilter = {
  AND: InputMaybe<Array<InputMaybe<UspEntityWrapperFilter>>>;
  OR: InputMaybe<Array<InputMaybe<UspEntityWrapperFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  uspEntries: InputMaybe<CfUspEntityNestedFilter>;
  uspEntriesCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type UspEntityWrapperLinkingCollections = {
  __typename: 'UspEntityWrapperLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};

export type UspEntityWrapperLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum UspEntityWrapperOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type UspEntityWrapperUspEntriesCollection = {
  __typename: 'UspEntityWrapperUspEntriesCollection';
  items: Array<Maybe<UspEntity>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export enum UspEntityWrapperUspEntriesCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type Video = Entry &
  _Node & {
    __typename: 'Video';
    _id: Scalars['ID'];
    contentfulMetadata: ContentfulMetadata;
    copyrightLogo: Maybe<Image>;
    copyrightText: Maybe<Scalars['String']>;
    internalName: Maybe<Scalars['String']>;
    linkedFrom: Maybe<VideoLinkingCollections>;
    posterImage: Maybe<Image>;
    sys: Sys;
    video: Maybe<Asset>;
  };

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoCopyrightLogoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoCopyrightTextArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoInternalNameArgs = {
  locale: InputMaybe<Scalars['String']>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoLinkedFromArgs = {
  allowedLocales: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoPosterImageArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageFilter>;
};

/** Video asset container [See type definition](https://app.contentful.com/spaces/u5c1v3pt22v8/content_types/video) */
export type VideoVideoArgs = {
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
};

export type VideoCollection = {
  __typename: 'VideoCollection';
  items: Array<Maybe<Video>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type VideoFilter = {
  AND: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  OR: InputMaybe<Array<InputMaybe<VideoFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightLogo: InputMaybe<CfImageNestedFilter>;
  copyrightLogo_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  posterImage: InputMaybe<CfImageNestedFilter>;
  posterImage_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  video_exists: InputMaybe<Scalars['Boolean']>;
};

export type VideoLinkingCollections = {
  __typename: 'VideoLinkingCollections';
  articleCollection: Maybe<ArticleCollection>;
  assemblySolutionsFlexibleCollection: Maybe<AssemblySolutionsFlexibleCollection>;
  campaignCollection: Maybe<CampaignCollection>;
  ctaButtonCollection: Maybe<CtaButtonCollection>;
  entryCollection: Maybe<EntryCollection>;
  factBoxCollection: Maybe<FactBoxCollection>;
  supportCompositionCollection: Maybe<SupportCompositionCollection>;
  teaserCollection: Maybe<TeaserCollection>;
};

export type VideoLinkingCollectionsArticleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsArticleCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsAssemblySolutionsFlexibleCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<
    Array<InputMaybe<VideoLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder>>
  >;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsCampaignCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsCampaignCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsCtaButtonCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsCtaButtonCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsEntryCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsFactBoxCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsFactBoxCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsSupportCompositionCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsSupportCompositionCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type VideoLinkingCollectionsTeaserCollectionArgs = {
  limit: InputMaybe<Scalars['Int']>;
  locale: InputMaybe<Scalars['String']>;
  order: InputMaybe<Array<InputMaybe<VideoLinkingCollectionsTeaserCollectionOrder>>>;
  preview: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
};

export enum VideoLinkingCollectionsArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PublicationDateAsc = 'publicationDate_ASC',
  PublicationDateDesc = 'publicationDate_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum VideoLinkingCollectionsAssemblySolutionsFlexibleCollectionOrder {
  AlternatingTeasersAnchorTitleAsc = 'alternatingTeasersAnchorTitle_ASC',
  AlternatingTeasersAnchorTitleDesc = 'alternatingTeasersAnchorTitle_DESC',
  BottomAccordionfaqSectionAnchorTitleAsc = 'bottomAccordionfaqSectionAnchorTitle_ASC',
  BottomAccordionfaqSectionAnchorTitleDesc = 'bottomAccordionfaqSectionAnchorTitle_DESC',
  BottomPromotionCardAnchorTitleAsc = 'bottomPromotionCardAnchorTitle_ASC',
  BottomPromotionCardAnchorTitleDesc = 'bottomPromotionCardAnchorTitle_DESC',
  BottomPromotionCardTypeAsc = 'bottomPromotionCardType_ASC',
  BottomPromotionCardTypeDesc = 'bottomPromotionCardType_DESC',
  IntroSectionAnchorTitleAsc = 'introSectionAnchorTitle_ASC',
  IntroSectionAnchorTitleDesc = 'introSectionAnchorTitle_DESC',
  IntroSectionTitleAsc = 'introSectionTitle_ASC',
  IntroSectionTitleDesc = 'introSectionTitle_DESC',
  MidFaqSectionAnchorTitleAsc = 'midFaqSectionAnchorTitle_ASC',
  MidFaqSectionAnchorTitleDesc = 'midFaqSectionAnchorTitle_DESC',
  MidSectionTitleWithMediaAnchorTitleAsc = 'midSectionTitleWithMediaAnchorTitle_ASC',
  MidSectionTitleWithMediaAnchorTitleDesc = 'midSectionTitleWithMediaAnchorTitle_DESC',
  MiniParagraphsAnchorTitleAsc = 'miniParagraphsAnchorTitle_ASC',
  MiniParagraphsAnchorTitleDesc = 'miniParagraphsAnchorTitle_DESC',
  RichtextSectionAnchorTitleAsc = 'richtextSectionAnchorTitle_ASC',
  RichtextSectionAnchorTitleDesc = 'richtextSectionAnchorTitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TopCardsAnchorTitleAsc = 'topCardsAnchorTitle_ASC',
  TopCardsAnchorTitleDesc = 'topCardsAnchorTitle_DESC',
  TopCardsTitleAsc = 'topCardsTitle_ASC',
  TopCardsTitleDesc = 'topCardsTitle_DESC',
  UspAnchorTitleAsc = 'uspAnchorTitle_ASC',
  UspAnchorTitleDesc = 'uspAnchorTitle_DESC',
  UspTitleAsc = 'uspTitle_ASC',
  UspTitleDesc = 'uspTitle_DESC',
}

export enum VideoLinkingCollectionsCampaignCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum VideoLinkingCollectionsCtaButtonCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaTypeAsc = 'ctaType_ASC',
  CtaTypeDesc = 'ctaType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTriggerAsc = 'linkTrigger_ASC',
  LinkTriggerDesc = 'linkTrigger_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum VideoLinkingCollectionsFactBoxCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum VideoLinkingCollectionsSupportCompositionCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ReadableIdAsc = 'readableId_ASC',
  ReadableIdDesc = 'readableId_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum VideoLinkingCollectionsTeaserCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TaglineTypeAsc = 'taglineType_ASC',
  TaglineTypeDesc = 'taglineType_DESC',
  TaglineAsc = 'tagline_ASC',
  TaglineDesc = 'tagline_DESC',
  TeaserUrlAsc = 'teaserUrl_ASC',
  TeaserUrlDesc = 'teaserUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum VideoOrder {
  CopyrightTextAsc = 'copyrightText_ASC',
  CopyrightTextDesc = 'copyrightText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID'];
};

export type CfAlertNotificationNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfAlertNotificationNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfAlertNotificationNestedFilter>>>;
  body: InputMaybe<Scalars['String']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  body_not: InputMaybe<Scalars['String']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  body_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfArticleNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfArticleNestedFilter>>>;
  authorCollection_exists: InputMaybe<Scalars['Boolean']>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  factBoxCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImageCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideoCollection_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publicationDate: InputMaybe<Scalars['DateTime']>;
  publicationDate_exists: InputMaybe<Scalars['Boolean']>;
  publicationDate_gt: InputMaybe<Scalars['DateTime']>;
  publicationDate_gte: InputMaybe<Scalars['DateTime']>;
  publicationDate_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publicationDate_lt: InputMaybe<Scalars['DateTime']>;
  publicationDate_lte: InputMaybe<Scalars['DateTime']>;
  publicationDate_not: InputMaybe<Scalars['DateTime']>;
  publicationDate_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  slug: InputMaybe<Scalars['String']>;
  slug_contains: InputMaybe<Scalars['String']>;
  slug_exists: InputMaybe<Scalars['Boolean']>;
  slug_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug_not: InputMaybe<Scalars['String']>;
  slug_not_contains: InputMaybe<Scalars['String']>;
  slug_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfAuthorNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfAuthorNestedFilter>>>;
  bio_contains: InputMaybe<Scalars['String']>;
  bio_exists: InputMaybe<Scalars['Boolean']>;
  bio_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  email: InputMaybe<Scalars['String']>;
  email_contains: InputMaybe<Scalars['String']>;
  email_exists: InputMaybe<Scalars['Boolean']>;
  email_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email_not: InputMaybe<Scalars['String']>;
  email_not_contains: InputMaybe<Scalars['String']>;
  email_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook: InputMaybe<Scalars['String']>;
  facebook_contains: InputMaybe<Scalars['String']>;
  facebook_exists: InputMaybe<Scalars['Boolean']>;
  facebook_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  facebook_not: InputMaybe<Scalars['String']>;
  facebook_not_contains: InputMaybe<Scalars['String']>;
  facebook_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn: InputMaybe<Scalars['String']>;
  linkedIn_contains: InputMaybe<Scalars['String']>;
  linkedIn_exists: InputMaybe<Scalars['Boolean']>;
  linkedIn_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkedIn_not: InputMaybe<Scalars['String']>;
  linkedIn_not_contains: InputMaybe<Scalars['String']>;
  linkedIn_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profilePicture_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  twitter: InputMaybe<Scalars['String']>;
  twitter_contains: InputMaybe<Scalars['String']>;
  twitter_exists: InputMaybe<Scalars['Boolean']>;
  twitter_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  twitter_not: InputMaybe<Scalars['String']>;
  twitter_not_contains: InputMaybe<Scalars['String']>;
  twitter_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfCtaButtonNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfCtaButtonNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  ctaText: InputMaybe<Scalars['String']>;
  ctaText_contains: InputMaybe<Scalars['String']>;
  ctaText_exists: InputMaybe<Scalars['Boolean']>;
  ctaText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaText_not: InputMaybe<Scalars['String']>;
  ctaText_not_contains: InputMaybe<Scalars['String']>;
  ctaText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType: InputMaybe<Scalars['String']>;
  ctaType_contains: InputMaybe<Scalars['String']>;
  ctaType_exists: InputMaybe<Scalars['Boolean']>;
  ctaType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ctaType_not: InputMaybe<Scalars['String']>;
  ctaType_not_contains: InputMaybe<Scalars['String']>;
  ctaType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageTrigger_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger: InputMaybe<Scalars['String']>;
  linkTrigger_contains: InputMaybe<Scalars['String']>;
  linkTrigger_exists: InputMaybe<Scalars['Boolean']>;
  linkTrigger_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linkTrigger_not: InputMaybe<Scalars['String']>;
  linkTrigger_not_contains: InputMaybe<Scalars['String']>;
  linkTrigger_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  videoTrigger_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfEmbedMediaNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfEmbedMediaNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfEmbedMediaNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  url: InputMaybe<Scalars['String']>;
  url_contains: InputMaybe<Scalars['String']>;
  url_exists: InputMaybe<Scalars['Boolean']>;
  url_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  url_not: InputMaybe<Scalars['String']>;
  url_not_contains: InputMaybe<Scalars['String']>;
  url_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfEventNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfEventNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfEventNestedFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  date: InputMaybe<Scalars['DateTime']>;
  date_exists: InputMaybe<Scalars['Boolean']>;
  date_gt: InputMaybe<Scalars['DateTime']>;
  date_gte: InputMaybe<Scalars['DateTime']>;
  date_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  date_lt: InputMaybe<Scalars['DateTime']>;
  date_lte: InputMaybe<Scalars['DateTime']>;
  date_not: InputMaybe<Scalars['DateTime']>;
  date_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaGroupsCollection_exists: InputMaybe<Scalars['Boolean']>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  webcastsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfFactBoxNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFactBoxNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFactBoxNestedFilter>>>;
  body_contains: InputMaybe<Scalars['String']>;
  body_exists: InputMaybe<Scalars['Boolean']>;
  body_not_contains: InputMaybe<Scalars['String']>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mediaAssetsCollection_exists: InputMaybe<Scalars['Boolean']>;
  mediaImage_exists: InputMaybe<Scalars['Boolean']>;
  mediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfFactBoxWrapperNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFactBoxWrapperNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFactBoxWrapperNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  factBoxesCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfFaqSectionNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfFaqSectionNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfFaqSectionNestedFilter>>>;
  backgroundTitle_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  faqSectionCollection_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle: InputMaybe<Scalars['String']>;
  sectionTitle_contains: InputMaybe<Scalars['String']>;
  sectionTitle_exists: InputMaybe<Scalars['Boolean']>;
  sectionTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sectionTitle_not: InputMaybe<Scalars['String']>;
  sectionTitle_not_contains: InputMaybe<Scalars['String']>;
  sectionTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfImageNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfImageNestedFilter>>>;
  altText: InputMaybe<Scalars['String']>;
  altText_contains: InputMaybe<Scalars['String']>;
  altText_exists: InputMaybe<Scalars['Boolean']>;
  altText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  altText_not: InputMaybe<Scalars['String']>;
  altText_not_contains: InputMaybe<Scalars['String']>;
  altText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightImg_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea: InputMaybe<Scalars['String']>;
  focusArea_contains: InputMaybe<Scalars['String']>;
  focusArea_exists: InputMaybe<Scalars['Boolean']>;
  focusArea_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  focusArea_not: InputMaybe<Scalars['String']>;
  focusArea_not_contains: InputMaybe<Scalars['String']>;
  focusArea_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink: InputMaybe<Scalars['String']>;
  hyperlink_contains: InputMaybe<Scalars['String']>;
  hyperlink_exists: InputMaybe<Scalars['Boolean']>;
  hyperlink_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hyperlink_not: InputMaybe<Scalars['String']>;
  hyperlink_not_contains: InputMaybe<Scalars['String']>;
  hyperlink_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  image_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfPersonalisationSegmentsNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfPersonalisationSegmentsNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfPersonalisationSegmentsNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
};

export type CfRedirectRuleNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfRedirectRuleNestedFilter>>>;
  Destination: InputMaybe<Scalars['String']>;
  Destination_contains: InputMaybe<Scalars['String']>;
  Destination_exists: InputMaybe<Scalars['Boolean']>;
  Destination_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  Destination_not: InputMaybe<Scalars['String']>;
  Destination_not_contains: InputMaybe<Scalars['String']>;
  Destination_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  OR: InputMaybe<Array<InputMaybe<CfRedirectRuleNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  flag: InputMaybe<Scalars['String']>;
  flag_contains: InputMaybe<Scalars['String']>;
  flag_exists: InputMaybe<Scalars['Boolean']>;
  flag_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flag_not: InputMaybe<Scalars['String']>;
  flag_not_contains: InputMaybe<Scalars['String']>;
  flag_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchBy: InputMaybe<Scalars['String']>;
  matchBy_contains: InputMaybe<Scalars['String']>;
  matchBy_exists: InputMaybe<Scalars['Boolean']>;
  matchBy_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  matchBy_not: InputMaybe<Scalars['String']>;
  matchBy_not_contains: InputMaybe<Scalars['String']>;
  matchBy_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirectCode: InputMaybe<Scalars['String']>;
  redirectCode_contains: InputMaybe<Scalars['String']>;
  redirectCode_exists: InputMaybe<Scalars['Boolean']>;
  redirectCode_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  redirectCode_not: InputMaybe<Scalars['String']>;
  redirectCode_not_contains: InputMaybe<Scalars['String']>;
  redirectCode_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subject: InputMaybe<Scalars['String']>;
  subject_contains: InputMaybe<Scalars['String']>;
  subject_exists: InputMaybe<Scalars['Boolean']>;
  subject_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subject_not: InputMaybe<Scalars['String']>;
  subject_not_contains: InputMaybe<Scalars['String']>;
  subject_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfResourceNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfResourceNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfResourceNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key: InputMaybe<Scalars['String']>;
  key_contains: InputMaybe<Scalars['String']>;
  key_exists: InputMaybe<Scalars['Boolean']>;
  key_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key_not: InputMaybe<Scalars['String']>;
  key_not_contains: InputMaybe<Scalars['String']>;
  key_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSectionAssemblyNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfSectionAssemblyNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfSectionAssemblyNestedFilter>>>;
  contentCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfSeoNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_all: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_none: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_contains_some: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keywords_exists: InputMaybe<Scalars['Boolean']>;
  no_follow: InputMaybe<Scalars['Boolean']>;
  no_follow_exists: InputMaybe<Scalars['Boolean']>;
  no_follow_not: InputMaybe<Scalars['Boolean']>;
  no_index: InputMaybe<Scalars['Boolean']>;
  no_index_exists: InputMaybe<Scalars['Boolean']>;
  no_index_not: InputMaybe<Scalars['Boolean']>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription: InputMaybe<Scalars['String']>;
  socialMediaDescription_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaDescription_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaDescription_not: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_contains: InputMaybe<Scalars['String']>;
  socialMediaDescription_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName: InputMaybe<Scalars['String']>;
  socialMediaSiteName_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaSiteName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaSiteName_not: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_contains: InputMaybe<Scalars['String']>;
  socialMediaSiteName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle: InputMaybe<Scalars['String']>;
  socialMediaTitle_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_exists: InputMaybe<Scalars['Boolean']>;
  socialMediaTitle_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMediaTitle_not: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_contains: InputMaybe<Scalars['String']>;
  socialMediaTitle_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  xRobotsTag: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_exists: InputMaybe<Scalars['Boolean']>;
  xRobotsTag_gt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_gte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  xRobotsTag_lt: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_lte: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not: InputMaybe<Scalars['DateTime']>;
  xRobotsTag_not_in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type CfTagNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>;
  category: InputMaybe<Scalars['String']>;
  category_contains: InputMaybe<Scalars['String']>;
  category_exists: InputMaybe<Scalars['Boolean']>;
  category_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category_not: InputMaybe<Scalars['String']>;
  category_not_contains: InputMaybe<Scalars['String']>;
  category_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  excludePersonalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  personalisationSegmentsCollection_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  value: InputMaybe<Scalars['String']>;
  value_contains: InputMaybe<Scalars['String']>;
  value_exists: InputMaybe<Scalars['Boolean']>;
  value_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  value_not: InputMaybe<Scalars['String']>;
  value_not_contains: InputMaybe<Scalars['String']>;
  value_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTeamCardsWrapperNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTeamCardsWrapperNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTeamCardsWrapperNestedFilter>>>;
  cardsCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  cta_exists: InputMaybe<Scalars['Boolean']>;
  preamble: InputMaybe<Scalars['String']>;
  preamble_contains: InputMaybe<Scalars['String']>;
  preamble_exists: InputMaybe<Scalars['Boolean']>;
  preamble_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preamble_not: InputMaybe<Scalars['String']>;
  preamble_not_contains: InputMaybe<Scalars['String']>;
  preamble_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfTeaserNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTeaserNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTeaserNestedFilter>>>;
  backgroundMedia_exists: InputMaybe<Scalars['Boolean']>;
  callToActionCollection_exists: InputMaybe<Scalars['Boolean']>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryMediaImage_exists: InputMaybe<Scalars['Boolean']>;
  primaryMediaVideo_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_contains: InputMaybe<Scalars['String']>;
  richPreamble_exists: InputMaybe<Scalars['Boolean']>;
  richPreamble_not_contains: InputMaybe<Scalars['String']>;
  sys: InputMaybe<SysFilter>;
  tagline: InputMaybe<Scalars['String']>;
  taglineType: InputMaybe<Scalars['String']>;
  taglineType_contains: InputMaybe<Scalars['String']>;
  taglineType_exists: InputMaybe<Scalars['Boolean']>;
  taglineType_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taglineType_not: InputMaybe<Scalars['String']>;
  taglineType_not_contains: InputMaybe<Scalars['String']>;
  taglineType_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_contains: InputMaybe<Scalars['String']>;
  tagline_exists: InputMaybe<Scalars['Boolean']>;
  tagline_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagline_not: InputMaybe<Scalars['String']>;
  tagline_not_contains: InputMaybe<Scalars['String']>;
  tagline_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl: InputMaybe<Scalars['String']>;
  teaserUrl_contains: InputMaybe<Scalars['String']>;
  teaserUrl_exists: InputMaybe<Scalars['Boolean']>;
  teaserUrl_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teaserUrl_not: InputMaybe<Scalars['String']>;
  teaserUrl_not_contains: InputMaybe<Scalars['String']>;
  teaserUrl_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uniqueSellingPointsCollection_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfTeaserWrapperNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfTeaserWrapperNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfTeaserWrapperNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: InputMaybe<Scalars['String']>;
  name_contains: InputMaybe<Scalars['String']>;
  name_exists: InputMaybe<Scalars['Boolean']>;
  name_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name_not: InputMaybe<Scalars['String']>;
  name_not_contains: InputMaybe<Scalars['String']>;
  name_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId: InputMaybe<Scalars['String']>;
  readableId_contains: InputMaybe<Scalars['String']>;
  readableId_exists: InputMaybe<Scalars['Boolean']>;
  readableId_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  readableId_not: InputMaybe<Scalars['String']>;
  readableId_not_contains: InputMaybe<Scalars['String']>;
  readableId_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  teaserListCollection_exists: InputMaybe<Scalars['Boolean']>;
  type: InputMaybe<Scalars['String']>;
  type_contains: InputMaybe<Scalars['String']>;
  type_exists: InputMaybe<Scalars['Boolean']>;
  type_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type_not: InputMaybe<Scalars['String']>;
  type_not_contains: InputMaybe<Scalars['String']>;
  type_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfUspEntityNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfUspEntityNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfUspEntityNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  description: InputMaybe<Scalars['String']>;
  description_contains: InputMaybe<Scalars['String']>;
  description_exists: InputMaybe<Scalars['Boolean']>;
  description_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description_not: InputMaybe<Scalars['String']>;
  description_not_contains: InputMaybe<Scalars['String']>;
  description_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon: InputMaybe<Scalars['String']>;
  icon_contains: InputMaybe<Scalars['String']>;
  icon_exists: InputMaybe<Scalars['Boolean']>;
  icon_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  icon_not: InputMaybe<Scalars['String']>;
  icon_not_contains: InputMaybe<Scalars['String']>;
  icon_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link: InputMaybe<Scalars['String']>;
  link_contains: InputMaybe<Scalars['String']>;
  link_exists: InputMaybe<Scalars['Boolean']>;
  link_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  link_not: InputMaybe<Scalars['String']>;
  link_not_contains: InputMaybe<Scalars['String']>;
  link_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sys: InputMaybe<SysFilter>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfVideoNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfVideoNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  copyrightLogo_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText: InputMaybe<Scalars['String']>;
  copyrightText_contains: InputMaybe<Scalars['String']>;
  copyrightText_exists: InputMaybe<Scalars['Boolean']>;
  copyrightText_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  copyrightText_not: InputMaybe<Scalars['String']>;
  copyrightText_not_contains: InputMaybe<Scalars['String']>;
  copyrightText_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName: InputMaybe<Scalars['String']>;
  internalName_contains: InputMaybe<Scalars['String']>;
  internalName_exists: InputMaybe<Scalars['Boolean']>;
  internalName_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  internalName_not: InputMaybe<Scalars['String']>;
  internalName_not_contains: InputMaybe<Scalars['String']>;
  internalName_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  posterImage_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  video_exists: InputMaybe<Scalars['Boolean']>;
};

export type CfaboutTheCompanyMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfaboutTheCompanyMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfaboutTheCompanyMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfadditionalCardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfadditionalCardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfadditionalCardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfbottomCardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfbottomCardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfbottomCardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfbottomCarouselMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfbottomCarouselMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfbottomCarouselMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfcardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfcardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfcardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfcareersMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfcareersMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfcareersMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfcontactUsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfcontactUsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfcontactUsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfcontentMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfcontentMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfcontentMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  seoMetadata_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
};

export type CfinvestorsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfinvestorsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfinvestorsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CflinkedArticleMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CflinkedArticleMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CflinkedArticleMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CflinkedArticlesMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CflinkedArticlesMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CflinkedArticlesMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfnewsroomMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfnewsroomMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfnewsroomMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfsolutionsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfsolutionsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfsolutionsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfstoryCardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfstoryCardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfstoryCardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfsustainabilityMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfsustainabilityMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfsustainabilityMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CftopCardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CftopCardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CftopCardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CftopCarouselItemsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CftopCarouselItemsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CftopCarouselItemsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CftopFirstCarouselItemsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CftopFirstCarouselItemsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CftopFirstCarouselItemsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CftopSecondCarouselItemsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CftopSecondCarouselItemsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CftopSecondCarouselItemsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CfuspCardsMultiTypeNestedFilter = {
  AND: InputMaybe<Array<InputMaybe<CfuspCardsMultiTypeNestedFilter>>>;
  OR: InputMaybe<Array<InputMaybe<CfuspCardsMultiTypeNestedFilter>>>;
  contentfulMetadata: InputMaybe<ContentfulMetadataFilter>;
  embedMedia_exists: InputMaybe<Scalars['Boolean']>;
  sys: InputMaybe<SysFilter>;
  tagsCollection_exists: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  title_contains: InputMaybe<Scalars['String']>;
  title_exists: InputMaybe<Scalars['Boolean']>;
  title_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title_not: InputMaybe<Scalars['String']>;
  title_not_contains: InputMaybe<Scalars['String']>;
  title_not_in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetAllAnnualGeneralMeetingYearsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetAllAnnualGeneralMeetingYearsQuery = {
  __typename: 'Query';
  allYears: {
    __typename: 'EventCollection';
    items: Array<{ __typename: 'Event'; date: any }>;
  };
  heroBanner: {
    __typename: 'TeaserCollection';
    items: Array<{
      __typename: 'Teaser';
      title: string;
      richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
      primaryMediaImage: {
        __typename: 'Image';
        altText: string;
        image: { __typename: 'Asset'; url: string };
      } | null;
    }>;
  };
};

export type GetAnnualGeneralMeetingsByYearQueryVariables = Exact<{
  date_gte: Scalars['DateTime'];
  date_lte: Scalars['DateTime'];
  locale: Scalars['String'];
}>;

export type GetAnnualGeneralMeetingsByYearQuery = {
  __typename: 'Query';
  data: {
    __typename: 'EventCollection';
    items: Array<{
      __typename: 'Event';
      title: string;
      date: any;
      body: {
        __typename: 'EventBody';
        json: any;
        links: {
          __typename: 'EventBodyLinks';
          entries: {
            __typename: 'EventBodyEntries';
            block: Array<
              | { __typename: 'AlertNotification'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AlertWrapper'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AnnualSustainabilityReport';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyCareers'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AssemblyCareersAboutLifeAtTelia';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'AssemblyCareersTeamsPage'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyCollectionPage'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyLevel1'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyMenuTeasers'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyOpenPositions'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AssemblyReportsAndPresentations';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'AssemblySolutions'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblySolutionsFlexible'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Author'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'BoardMember'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'CodeOfConduct'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'CtaButton'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'EmbedMedia';
                  url: string;
                  internalName: string;
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'Event'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'Image';
                  hyperlink: string | null;
                  image: {
                    __typename: 'Asset';
                    title: string;
                    url: string;
                  };
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'NewsroomAssembly'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Person'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'PersonalisationSegments'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'RedirectEntity'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'RedirectRule'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'SectionAssembly'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'SupportComposition'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TagWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TeamCardsWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TeaserWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'UspEntity'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'UspEntityWrapper'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'Video';
                  video: {
                    __typename: 'Asset';
                    title: string;
                    url: string;
                  };
                  sys: { __typename: 'Sys'; id: string };
                }
             
            >;
          };
          assets: {
            __typename: 'EventBodyAssets';
            hyperlink: Array<{
              __typename: 'Asset';
              contentType: string;
              title: string;
              url: string;
              sys: { __typename: 'Sys'; id: string };
            }>;
          };
        };
      } | null;
      mediaGroupsCollection: {
        __typename: 'EventMediaGroupsCollection';
        items: Array<{
          __typename: 'FactBox';
          title: string;
          mediaAssetsCollection: {
            __typename: 'AssetCollection';
            items: Array<{
              __typename: 'Asset';
              title: string;
              description: string;
              url: string;
              contentType: string;
            }>;
          };
        }>;
      };
      webcastsCollection: {
        __typename: 'EventWebcastsCollection';
        items: Array<{
          __typename: 'Article';
          title: string;
          slug: string;
        }>;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetAnnualSustainabilityReportsQueryVariables = Exact<{
  locale: InputMaybe<Scalars['String']>;
}>;

export type GetAnnualSustainabilityReportsQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AnnualSustainabilityReportCollection';
    items: Array<{
      __typename: 'AnnualSustainabilityReport';
      introTitle: string;
      introPreamble: string;
      heroBanner:
        | ({ __typename: 'Teaser' } & AnnualSustainabilityReportsHeroBannerFragment)
       ;
      introCta: {
        __typename: 'CtaButton';
        ctaText: string;
        linkTrigger: string;
      };
      introVideo: {
        __typename: 'Asset';
        url: string;
        contentType: string;
        description: string;
      };
      billboard:
        | ({ __typename: 'Teaser' } & AnnualSustainabilityReportsBillboardTeaserFragment)
       ;
      graphicImagesCollection: {
        __typename: 'AssetCollection';
        items: Array<{
          __typename: 'Asset';
          description: string;
          url: string;
          contentfulMetadata: {
            __typename: 'ContentfulMetadata';
            tags: Array<{
              __typename: 'ContentfulTag';
              id: string;
              name: string;
            }>;
          };
        }>;
      };
      graphicImages2Collection: {
        __typename: 'AssetCollection';
        items: Array<{
          __typename: 'Asset';
          description: string;
          url: string;
          contentfulMetadata: {
            __typename: 'ContentfulMetadata';
            tags: Array<{
              __typename: 'ContentfulTag';
              id: string;
              name: string;
            }>;
          };
        }>;
      };
      linkedArticlesCollection: {
        __typename: 'AnnualSustainabilityReportLinkedArticlesCollection';
        items: Array<
          | ({ __typename: 'Article' } & AnnualSustainabilityReportsLinkedArticlesArticleFragment)
          | ({ __typename: 'Teaser' } & AnnualSustainabilityReportsLinkedArticlesTeaserFragment)
         
        >;
      };
      linkedArticle:
        | ({ __typename: 'Article' } & AnnualSustainabilityReportsLinkedArticlesArticleFragment)
        | ({ __typename: 'Teaser' } & AnnualSustainabilityReportsLinkedArticlesTeaserFragment)
       ;
    }>;
  };
};

export type AnnualSustainabilityReportsBillboardTeaserFragment = {
  __typename: 'Teaser';
  teaserUrl: string | null;
  title: string;
  tagline: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type AnnualSustainabilityReportsHeroBannerFragment = {
  __typename: 'Teaser';
  title: string;
  tagline: string | null;
  slug: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  primaryMediaVideo: {
    __typename: 'Video';
    video: { __typename: 'Asset'; url: string };
  } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type AnnualSustainabilityReportsLinkedArticlesArticleFragment = {
  __typename: 'Article';
  title: string;
  slug: string;
  preamble: string | null;
  category: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      altText: string;
      focusArea: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
} & ContentfulMetadataFragmentFragment;

export type AnnualSustainabilityReportsLinkedArticlesTeaserFragment = {
  __typename: 'Teaser';
  title: string;
  teaserUrl: string | null;
  tagline: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type ArticleFragment = {
  __typename: 'Article';
  readableId: string;
  title: string;
  category: string | null;
  preamble: string | null;
  mediaImageCollection: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      focusArea: string;
      altText: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
  mediaVideoCollection: {
    __typename: 'ArticleMediaVideoCollection';
    items: Array<{
      __typename: 'Video';
      video: { __typename: 'Asset'; url: string };
      posterImage: {
        __typename: 'Image';
        focusArea: string;
        altText: string;
        image: { __typename: 'Asset'; url: string };
      };
    }>;
  };
  body: ({ __typename: 'ArticleBody' } & ArticleBodyFragment) | null;
  tagsCollection: {
    __typename: 'ArticleTagsCollection';
    items: Array<{ __typename: 'Tag'; value: string }>;
  };
  seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment) | null;
} & ContentfulMetadataFragmentFragment &
  ArticleSlugsForAllLocalesFragment;

export type GetArticleQueryVariables = Exact<{
  id: Scalars['String'];
  locale: InputMaybe<Scalars['String']>;
}>;

export type GetArticleQuery = {
  __typename: 'Query';
  article: ({ __typename: 'Article' } & ArticleFragment);
};

export type ArticleBodyFragment = {
  __typename: 'ArticleBody';
  json: any;
  links: {
    __typename: 'ArticleBodyLinks';
    entries: {
      __typename: 'ArticleBodyEntries';
      block: Array<
        | { __typename: 'AlertNotification'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AlertWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AnnualSustainabilityReport'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersAboutLifeAtTelia'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersTeamsPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCollectionPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel1'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyMenuTeasers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyOpenPositions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyReportsAndPresentations'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutionsFlexible'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Author'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'BoardMember'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'CodeOfConduct'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'CtaButton';
            ctaText: string;
            linkTrigger: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | {
            __typename: 'EmbedMedia';
            url: string;
            internalName: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | {
            __typename: 'Event';
            title: string;
            date: any;
            body: { __typename: 'EventBody'; json: any } | null;
            mediaGroupsCollection: {
              __typename: 'EventMediaGroupsCollection';
              items: Array<{
                __typename: 'FactBox';
                body: { __typename: 'FactBoxBody'; json: any };
                callToActionCollection: {
                  __typename: 'FactBoxCallToActionCollection';
                  items: Array<{ __typename: 'CtaButton'; url: string }>;
                };
              }>;
            };
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Image';
            hyperlink: string | null;
            image: {
              __typename: 'Asset';
              title: string;
              url: string;
              description: string;
            };
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'NewsroomAssembly'; sys: { __typename: 'Sys'; id: string } }
        | ({
            __typename: 'Person';
            sys: { __typename: 'Sys'; id: string };
          } & ArticleBodyBlockPersonFragment)
        | { __typename: 'PersonalisationSegments'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectRule'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SectionAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SupportComposition'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TagWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeamCardsWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeaserWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntityWrapper'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Video';
            video: { __typename: 'Asset'; title: string; url: string };
            sys: { __typename: 'Sys'; id: string };
          }
       
      >;
    };
    assets: {
      __typename: 'ArticleBodyAssets';
      hyperlink: Array<{
        __typename: 'Asset';
        contentType: string;
        title: string;
        url: string;
        sys: { __typename: 'Sys'; id: string };
      }>;
    };
  };
};

export type ArticleBodyBlockPersonFragment = {
  __typename: 'Person';
  fullName: string;
  position: string;
  slug: string;
  tag: { __typename: 'Tag'; name: string };
  images: {
    __typename: 'PersonMediaImagesCollection';
    items: Array<{
      __typename: 'Image';
      focusArea: string;
      altText: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
};

export type ArticleCardFragment = {
  __typename: 'Article';
  slug: string;
  title: string;
  preamble: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      focusArea: string;
      altText: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
};

export type GetArticleIdQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetArticleIdQuery = {
  __typename: 'Query';
  enData: {
    __typename: 'ArticleCollection';
    items: Array<{ __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }>;
  };
  svData: {
    __typename: 'ArticleCollection';
    items: Array<{ __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }>;
  };
};

export type ArticleSlugsForAllLocalesFragment = {
  __typename: 'Article';
  svSlug: string;
  enSlug: string;
};

export type GetAssemblySolutionsQueryVariables = Exact<{
  slug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetAssemblySolutionsQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblySolutionsCollection';
    items: Array<{
      __typename: 'AssemblySolutions';
      introSectionTitle: string;
      uspTitle: string;
      uspCardsTitle: string;
      alternatingTeasersTitle: string;
      bottomCardsTitle: string;
      bottomPromotionCardType: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblySolutionsHeroBannerFragment);
      introSectionCarouselCollection: {
        __typename: 'AssemblySolutionsIntroSectionCarouselCollection';
        items: Array<({ __typename: 'Teaser' } & TeaserCardFragment)>;
      };
      introSectionCtaLink: {
        __typename: 'CtaButton';
        ctaText: string;
        linkTrigger: string;
      };
      uspPreamble: { __typename: 'AssemblySolutionsUspPreamble'; json: any };
      uspIconsCollection: {
        __typename: 'AssemblySolutionsUspIconsCollection';
        items: Array<{
          __typename: 'UspEntity';
          title: string;
          description: string;
          icon: string;
        }>;
      };
      uspCardsPreamble: { __typename: 'AssemblySolutionsUspCardsPreamble'; json: any };
      uspCardsCollection: {
        __typename: 'AssemblySolutionsUspCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & AssemblyLevel0TopCarouselItemsCollectionArticleFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      uspCardsCtaLink: {
        __typename: 'CtaButton';
        ctaText: string;
        linkTrigger: string;
      };
      alternatingTeasersCollection: {
        __typename: 'AssemblySolutionsAlternatingTeasersCollection';
        items: Array<({ __typename: 'Teaser' } & TeaserCardFragment)>;
      };
      alternatingTeasersCtaLink: {
        __typename: 'CtaButton';
        ctaText: string;
        linkTrigger: string;
      };
      bottomCardsPreamble: {
        __typename: 'AssemblySolutionsBottomCardsPreamble';
        json: any;
      };
      bottomCardsCollection: {
        __typename: 'AssemblySolutionsBottomCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & AssemblyLevel0TopCarouselItemsCollectionArticleFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      bottomPromotionCard: ({ __typename: 'Teaser' } & TeaserCardFragment);
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type AssemblySolutionsHeroBannerFragment = {
  __typename: 'Teaser';
  title: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  primaryMediaVideo: {
    __typename: 'Video';
    video: { __typename: 'Asset'; url: string };
  } | null;
};

export type GetAboutLifeAtTeliaQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetAboutLifeAtTeliaQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyCareersAboutLifeAtTeliaCollection';
    items: Array<{
      __typename: 'AssemblyCareersAboutLifeAtTelia';
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      body: {
        __typename: 'AssemblyCareersAboutLifeAtTeliaBody';
        json: any;
        links: {
          __typename: 'AssemblyCareersAboutLifeAtTeliaBodyLinks';
          entries: {
            __typename: 'AssemblyCareersAboutLifeAtTeliaBodyEntries';
            block: Array<
              | { __typename: 'AlertNotification'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AlertWrapper'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AnnualSustainabilityReport';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyCareers'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AssemblyCareersAboutLifeAtTelia';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'AssemblyCareersTeamsPage'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyCollectionPage'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyLevel1'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyMenuTeasers'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblyOpenPositions'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'AssemblyReportsAndPresentations';
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'AssemblySolutions'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'AssemblySolutionsFlexible'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Author'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'BoardMember'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'CodeOfConduct'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'CtaButton';
                  ctaText: string;
                  linkTrigger: string;
                  sys: { __typename: 'Sys'; id: string };
                }
              | {
                  __typename: 'EmbedMedia';
                  url: string;
                  internalName: string;
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'Event'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'Image';
                  hyperlink: string | null;
                  image: {
                    __typename: 'Asset';
                    title: string;
                    url: string;
                    description: string;
                  };
                  sys: { __typename: 'Sys'; id: string };
                }
              | { __typename: 'NewsroomAssembly'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Person'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'PersonalisationSegments'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'RedirectEntity'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'RedirectRule'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'SectionAssembly'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'SupportComposition'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TagWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TeamCardsWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'TeaserWrapper'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'UspEntity'; sys: { __typename: 'Sys'; id: string } }
              | { __typename: 'UspEntityWrapper'; sys: { __typename: 'Sys'; id: string } }
              | {
                  __typename: 'Video';
                  video: {
                    __typename: 'Asset';
                    title: string;
                    url: string;
                  };
                  sys: { __typename: 'Sys'; id: string };
                }
             
            >;
          };
          assets: {
            __typename: 'AssemblyCareersAboutLifeAtTeliaBodyAssets';
            hyperlink: Array<{
              __typename: 'Asset';
              contentType: string;
              title: string;
              url: string;
              sys: { __typename: 'Sys'; id: string };
            }>;
          };
        };
      };
      teamCards: {
        __typename: 'TeamCardsWrapper';
        title: string;
        preamble: string;
        cta: { __typename: 'CtaButton'; text: string; url: string };
        cardsCollection: {
          __typename: 'TeamCardsWrapperCardsCollection';
          items: Array<
            | ({ __typename: 'Article' } & ArticleCardFragment)
            | ({ __typename: 'Teaser' } & TeaserCardFragment)
           
          >;
        };
      };
      bottomCardsCollection: {
        __typename: 'AssemblyCareersAboutLifeAtTeliaBottomCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & ArticleCardFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetOpenPositionsCollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetOpenPositionsCollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyOpenPositionsCollection';
    items: Array<{
      __typename: 'AssemblyOpenPositions';
      internalName: string;
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetCareersPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetCareersPageQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyCareersCollection';
    items: Array<{
      __typename: 'AssemblyCareers';
      storyCardsTitle: string;
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      richPreamble: { __typename: 'AssemblyCareersRichPreamble'; json: any };
      storyCardsCollection: {
        __typename: 'AssemblyCareersStoryCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & ArticleCardFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      storyCardsLink: {
        __typename: 'CtaButton';
        text: string;
        url: string;
      };
      teamCards: {
        __typename: 'TeamCardsWrapper';
        title: string;
        preamble: string;
        cta: { __typename: 'CtaButton'; text: string; url: string };
        cardsCollection: {
          __typename: 'TeamCardsWrapperCardsCollection';
          items: Array<
            | ({ __typename: 'Article' } & ArticleCardFragment)
            | ({ __typename: 'Teaser' } & TeaserCardFragment)
           
          >;
        };
      };
      additionalCardsCollection: {
        __typename: 'AssemblyCareersAdditionalCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & ArticleCardFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetCareersTeamsPageQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetCareersTeamsPageQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyCareersTeamsPageCollection';
    items: Array<{
      __typename: 'AssemblyCareersTeamsPage';
      cardsTitle: string;
      cardsPreamble: string;
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      cardsCollection: {
        __typename: 'AssemblyCareersTeamsPageCardsCollection';
        items: Array<({ __typename: 'Article' } & ArticleCardFragment)>;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetCodeOfConductCollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetCodeOfConductCollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'CodeOfConductCollection';
    items: Array<{
      __typename: 'CodeOfConduct';
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      description: { __typename: 'CodeOfConductDescription'; json: any };
      billboard: {
        __typename: 'Teaser';
        teaserUrl: string | null;
        title: string;
        tagline: string | null;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      faqCollection: {
        __typename: 'CodeOfConductFaqCollection';
        items: Array<{
          __typename: 'FaqSection';
          sectionTitle: string;
          backgroundTitle: {
            __typename: 'Image';
            altText: string;
            image: { __typename: 'Asset'; url: string };
          };
          faqSectionCollection: {
            __typename: 'FaqSectionFaqSectionCollection';
            items: Array<{
              __typename: 'FactBox';
              title: string;
              body: { __typename: 'FactBoxBody'; json: any };
              tagsCollection: {
                __typename: 'FactBoxTagsCollection';
                items: Array<{
                  __typename: 'Tag';
                  name: string;
                  category: string;
                }>;
              };
            }>;
          };
        }>;
      };
      bottomCard: {
        __typename: 'Teaser';
        teaserUrl: string | null;
        title: string;
        tagline: string | null;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
    }>;
  };
};

export type GetAssemblyCollectionPageQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetAssemblyCollectionPageQuery = {
  __typename: 'Query';
  assemblyCollectionPage: {
    __typename: 'AssemblyCollectionPage';
    readableId: string;
    svSlug: string;
    enSlug: string;
    heroBanner: {
      __typename: 'Teaser';
      title: string;
      richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
      image: {
        __typename: 'Image';
        altText: string;
        focusArea: string;
        image: { __typename: 'Asset'; url: string };
      };
    };
    description: { __typename: 'AssemblyCollectionPageDescription'; json: any };
    sectionsCollection: {
      __typename: 'AssemblyCollectionPageSectionsCollection';
      items: Array<{
        __typename: 'SectionAssembly';
        internalName: string;
        type: string;
        description: { __typename: 'SectionAssemblyDescription'; json: any };
        contentCollection: {
          __typename: 'SectionAssemblyContentCollection';
          items: Array<
            | {
                __typename: 'Article';
                slug: string;
                title: string;
                preamble: string | null;
                images: {
                  __typename: 'ArticleMediaImageCollection';
                  items: Array<{
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  }>;
                };
              }
            | {
                __typename: 'AssemblyCollectionPage';
                slug: string;
                heroBanner: {
                  __typename: 'Teaser';
                  title: string;
                  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
                  primaryMediaImage: {
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  } | null;
                };
              }
            | {
                __typename: 'AssemblyLevel0';
                heroBanner: {
                  __typename: 'Teaser';
                  title: string;
                  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
                  image: {
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  };
                };
              }
            | {
                __typename: 'AssemblyLevel1';
                slug: string;
                heroBanner: {
                  __typename: 'Teaser';
                  title: string;
                  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
                  image: {
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  };
                };
              }
            | {
                __typename: 'AssemblyReportsAndPresentations';
                year: number;
                heroBanner: { __typename: 'Teaser'; title: string };
              }
            | {
                __typename: 'AssemblySolutionsFlexible';
                slug: string;
                heroBanner: {
                  __typename: 'Teaser';
                  title: string;
                  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
                  primaryMediaImage: {
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  } | null;
                };
              }
            | {
                __typename: 'NewsroomAssembly';
                heroBanner: {
                  __typename: 'Teaser';
                  title: string;
                  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
                  image: {
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  };
                };
              }
            | {
                __typename: 'Person';
                fullName: string;
                position: string;
                slug: string;
                tag: { __typename: 'Tag'; name: string };
                images: {
                  __typename: 'PersonMediaImagesCollection';
                  items: Array<{
                    __typename: 'Image';
                    focusArea: string;
                    altText: string;
                    image: { __typename: 'Asset'; url: string };
                  }>;
                };
              }
           
          >;
        };
      }>;
    };
    seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
  };
};

export type GetAssemblyCollectionPageIdQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAssemblyCollectionPageIdQuery = {
  __typename: 'Query';
  enData: {
    __typename: 'AssemblyCollectionPageCollection';
    items: Array<{
      __typename: 'AssemblyCollectionPage';
      sys: { __typename: 'Sys'; id: string };
    }>;
  };
  svData: {
    __typename: 'AssemblyCollectionPageCollection';
    items: Array<{
      __typename: 'AssemblyCollectionPage';
      sys: { __typename: 'Sys'; id: string };
    }>;
  };
};

export type ContentfulMetadataFragmentFragment = {
  __typename: 'Article';
  contentfulMetadata: {
    __typename: 'ContentfulMetadata';
    tags: Array<{ __typename: 'ContentfulTag'; id: string; name: string }>;
  };
};

export type DefinitionsContentFragment = {
  __typename: 'Tag';
  linkedFrom: {
    __typename: 'TagLinkingCollections';
    factBoxCollection: {
      __typename: 'FactBoxCollection';
      items: Array<{
        __typename: 'FactBox';
        title: string;
        body: { __typename: 'FactBoxBody'; json: any };
      }>;
    };
  };
};

export type ImageFieldsFragment = {
  __typename: 'Image';
  focusArea: string;
  altText: string;
  image: { __typename: 'Asset'; url: string };
  contentfulMetadata: {
    __typename: 'ContentfulMetadata';
    tags: Array<{ __typename: 'ContentfulTag'; name: string }>;
  };
};

export type GetAssemblyLevel0CollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetAssemblyLevel0CollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyLevel0Collection';
    items: Array<{
      __typename: 'AssemblyLevel0';
      topCarouselHeading: string;
      filteredItemsHeading: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblyLevel0HeroBannerFragment);
      topCarouselItemsCollection: {
        __typename: 'AssemblyLevel0TopCarouselItemsCollection';
        items: Array<
          | ({ __typename: 'Article' } & AssemblyLevel0TopCarouselItemsCollectionArticleFragment)
          | ({ __typename: 'Teaser' } & Level0CarouselTeaserFieldsFragment)
         
        >;
      };
      facts: {
        __typename: 'FactBoxWrapper';
        factBoxesCollection: {
          __typename: 'FactBoxWrapperFactBoxesCollection';
          items: Array<{
            __typename: 'FactBox';
            title: string;
            body: { __typename: 'FactBoxBody'; json: any };
          }>;
        };
      };
      topBillboard: {
        __typename: 'Teaser';
        teaserUrl: string | null;
        title: string;
        tagline: string | null;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null | null;
      };
      campaignCardsCollection: {
        __typename: 'AssemblyLevel0CampaignCardsCollection';
        items: Array<({ __typename: 'Teaser' } & Level0CarouselTeaserFieldsFragment)>;
      };
      filteredItemsCta: {
        __typename: 'CtaButton';
        text: string;
        url: string;
      };
      bottomBillboard: {
        __typename: 'Teaser';
        teaserUrl: string | null;
        title: string;
        tagline: string | null;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type AssemblyLevel0BottomCardItemsCollectionFragment = {
  __typename: 'Article';
  slug: string;
  title: string;
  category: string | null;
} & ContentfulMetadataFragmentFragment;

export type Level0CarouselTeaserFieldsFragment = {
  __typename: 'Teaser';
  title: string;
  tagline: string | null;
  teaserUrl: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type AssemblyLevel0HeroBannerFragment = {
  __typename: 'Teaser';
  title: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  primaryMediaVideo: {
    __typename: 'Video';
    video: { __typename: 'Asset'; url: string };
  } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type AssemblyLevel0TopCarouselItemsCollectionArticleFragment = {
  __typename: 'Article';
  title: string;
  slug: string;
  category: string | null;
  preamble: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      altText: string;
      focusArea: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
} & ContentfulMetadataFragmentFragment;

export type GetAssemblyLevel1CollectionQueryVariables = Exact<{
  slug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetAssemblyLevel1CollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyLevel1Collection';
    items: Array<{
      __typename: 'AssemblyLevel1';
      topFirstCarouselHeading: string;
      topSecondCarouselHeading: string | null;
      tableToggle: boolean;
      tableHeading: string;
      enSlug: string;
      svSlug: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblyLevel1HeroBannerFragment);
      topFirstCarouselItemsCollection: {
        __typename: 'AssemblyLevel1TopFirstCarouselItemsCollection';
        items: Array<
          | ({
              __typename: 'Article';
            } & AssemblyLevel1TopFirstCarouselItemsCollectionArticleFragment)
          | ({
              __typename: 'Teaser';
            } & AssemblyLevel1TopFirstCarouselItemsCollectionTeaserFragment)
         
        >;
      };
      topSecondCarouselItemsCollection: {
        __typename: 'AssemblyLevel1TopSecondCarouselItemsCollection';
        items: Array<
          | ({
              __typename: 'Article';
            } & AssemblyLevel1TopSecondCarouselItemsCollectionArticleFragment)
          | ({
              __typename: 'Teaser';
            } & AssemblyLevel1TopSecondCarouselItemsCollectionTeaserFragment)
         
        >;
      };
      billboard: ({ __typename: 'Teaser' } & AssemblyLevel1BillboardTeaserFragment) | null;
      tableCta: { __typename: 'CtaButton'; text: string; url: string };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type AssemblyLevel1BillboardTeaserFragment = {
  __typename: 'Teaser';
  teaserUrl: string | null;
  title: string;
  tagline: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type AssemblyLevel1BottomCarouselItemsCollectionArticleFragment = {
  __typename: 'Article';
  title: string;
  slug: string;
  preamble: string | null;
  category: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      altText: string;
      focusArea: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
} & ContentfulMetadataFragmentFragment;

export type AssemblyLevel1BottomCarouselItemsCollectionTeaserFragment = {
  __typename: 'Teaser';
  title: string;
  tagline: string | null;
  teaserUrl: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type AssemblyLevel1HeroBannerFragment = {
  __typename: 'Teaser';
  title: string;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  primaryMediaVideo: {
    __typename: 'Video';
    video: { __typename: 'Asset'; url: string };
  } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type AssemblyLevel1TopFirstCarouselItemsCollectionArticleFragment = {
  __typename: 'Article';
  title: string;
  slug: string;
  preamble: string | null;
  category: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      altText: string;
      focusArea: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
} & ContentfulMetadataFragmentFragment;

export type AssemblyLevel1TopFirstCarouselItemsCollectionTeaserFragment = {
  __typename: 'Teaser';
  title: string;
  tagline: string | null;
  teaserUrl: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type AssemblyLevel1TopSecondCarouselItemsCollectionArticleFragment = {
  __typename: 'Article';
  title: string;
  slug: string;
  preamble: string | null;
  category: string | null;
  images: {
    __typename: 'ArticleMediaImageCollection';
    items: Array<{
      __typename: 'Image';
      altText: string;
      focusArea: string;
      image: { __typename: 'Asset'; url: string };
    }>;
  };
} & ContentfulMetadataFragmentFragment;

export type AssemblyLevel1TopSecondCarouselItemsCollectionTeaserFragment = {
  __typename: 'Teaser';
  title: string;
  teaserUrl: string | null;
  tagline: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    altText: string;
    focusArea: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
};

export type GetMenuTeasersQueryVariables = Exact<{
  locale: InputMaybe<Scalars['String']>;
}>;

export type GetMenuTeasersQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblyMenuTeasersCollection';
    items: Array<{
      __typename: 'AssemblyMenuTeasers';
      aboutTheCompany:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      solutions:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      newsroom:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      investors:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      sustainability:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      careers:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
      contactUs:
        | ({ __typename: 'Article' } & ArticleCardFragment)
        | ({ __typename: 'Teaser' } & TeaserCardFragment)
       ;
    }>;
  };
};

export type GetNewsArticleQueryVariables = Exact<{
  id: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetNewsArticleQuery = {
  __typename: 'Query';
  article: ({ __typename: 'Article' } & NewsArticleArticleFragment);
};

export type NewsArticleArticleFragment = {
  __typename: 'Article';
  readableId: string;
  title: string;
  publicationDate: any | null;
  preamble: string | null;
  category: string | null;
  sys: { __typename: 'Sys'; publishedAt: any };
  body: ({ __typename: 'ArticleBody' } & ArticleBodyFragment) | null;
  tagsCollection: {
    __typename: 'ArticleTagsCollection';
    items: Array<{ __typename: 'Tag'; value: string }>;
  };
} & ContentfulMetadataFragmentFragment &
  ArticleSlugsForAllLocalesFragment;

export type GetNewsroomAssemblyLevelCollectionQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type GetNewsroomAssemblyLevelCollectionQuery = {
  __typename: 'Query';
  data: {
    __typename: 'NewsroomAssemblyCollection';
    items: Array<{
      __typename: 'NewsroomAssembly';
      internalName: string;
      heroBanner: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          focusArea: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
      };
      pressAndMediaBillboard: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        primaryMediaImage: {
          __typename: 'Image';
          altText: string;
          image: { __typename: 'Asset'; url: string };
        } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
      };
      mediaKitBillboard: {
        __typename: 'Teaser';
        title: string;
        richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
        callToActionCollection: {
          __typename: 'TeaserCallToActionCollection';
          items: Array<{
            __typename: 'CtaButton';
            text: string;
            url: string;
          }>;
        };
      };
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type GetPersonQueryVariables = Exact<{
  slug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetPersonQuery = {
  __typename: 'Query';
  data: {
    __typename: 'PersonCollection';
    items: Array<{
      __typename: 'Person';
      readableId: string;
      fullName: string;
      position: string;
      enSlug: string;
      svSlug: string;
      bio: { __typename: 'PersonBio'; json: any };
      tag: { __typename: 'Tag'; name: string };
      mediaImagesCollection: {
        __typename: 'PersonMediaImagesCollection';
        items: Array<({ __typename: 'Image' } & ImageFieldsFragment)>;
      };
    }>;
  };
};

export type RelatedArticlesFromStandaloneTagsFragment = {
  __typename: 'TagCollection';
  items: Array<{
    __typename: 'Tag';
    name: string;
    value: string;
    linkedFrom: {
      __typename: 'TagLinkingCollections';
      relatedArticles: {
        __typename: 'ArticleCollection';
        items: Array<
          | ({
              __typename: 'Article';
              title: string;
              slug: string;
              category: string | null;
              publicationDate: any | null;
              sys: { __typename: 'Sys'; publishedAt: any };
            } & ContentfulMetadataFragmentFragment)
         
        >;
      };
    };
  }>;
};

export type ReportsAndPresentationsAssemblyFieldsFragment = {
  __typename: 'AssemblyReportsAndPresentations';
  year: number;
  heroBanner: {
    __typename: 'Teaser';
    title: string;
    preamble: { __typename: 'TeaserRichPreamble'; json: any };
    primaryMediaImage: {
      __typename: 'Image';
      altText: string;
      image: { __typename: 'Asset'; url: string };
    } | null;
  };
  eventsCollection: {
    __typename: 'AssemblyReportsAndPresentationsEventsCollection';
    items: Array<({ __typename: 'Event' } & ReportsAndPresentationsEventFieldsFragment)>;
  };
  seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
};

export type ReportsAndPresentationsByYearQueryVariables = Exact<{
  locale: Scalars['String'];
  year: InputMaybe<Scalars['Int']>;
}>;

export type ReportsAndPresentationsByYearQuery = {
  __typename: 'Query';
  assemblyContent: {
    __typename: 'AssemblyReportsAndPresentationsCollection';
    items: Array<
      | ({
          __typename: 'AssemblyReportsAndPresentations';
        } & ReportsAndPresentationsAssemblyFieldsFragment)
     
    >;
  };
};

export type ReportsAndPresentationsContentQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type ReportsAndPresentationsContentQuery = {
  __typename: 'Query';
  definitionsContent: {
    __typename: 'TagCollection';
    items: Array<({ __typename: 'Tag' } & DefinitionsContentFragment)>;
  };
  availableYears: {
    __typename: 'AssemblyReportsAndPresentationsCollection';
    items: Array<{ __typename: 'AssemblyReportsAndPresentations'; year: number }>;
  };
  resourceCollection: {
    __typename: 'ResourceCollection';
    items: Array<{ __typename: 'Resource'; key: string; value: string }>;
  };
};

export type ReportsAndPresentationsEventFieldsFragment = {
  __typename: 'Event';
  internalName: string;
  title: string;
  mediaGroupsCollection: {
    __typename: 'EventMediaGroupsCollection';
    items: Array<{
      __typename: 'FactBox';
      mediaAssetsCollection: {
        __typename: 'AssetCollection';
        items: Array<{
          __typename: 'Asset';
          title: string;
          url: string;
          contentType: string;
        }>;
      };
    }>;
  };
  webcastsCollection: {
    __typename: 'EventWebcastsCollection';
    items: Array<{ __typename: 'Article'; title: string; slug: string }>;
  };
};

export type SeoMetadataFragment = {
  __typename: 'Seo';
  title: string;
  description: string;
  keywords: Array<string>;
  noIndex: boolean;
  noFollow: boolean;
  xRobotsTag: any;
  socialMediaSiteName: string;
  socialMediaTitle: string;
  socialMediaDescription: string;
  socialMediaImage: {
    __typename: 'Image';
    image: { __typename: 'Asset'; url: string };
  };
};

export type GetAssemblySolutionsFlexibleQueryVariables = Exact<{
  slug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GetAssemblySolutionsFlexibleQuery = {
  __typename: 'Query';
  data: {
    __typename: 'AssemblySolutionsFlexibleCollection';
    items: Array<{
      __typename: 'AssemblySolutionsFlexible';
      introSectionTitle: string;
      uspTitle: string;
      topCardsTitle: string;
      bottomPromotionCardType: string;
      introSectionAnchorTitle: string;
      topCardsAnchorTitle: string;
      uspAnchorTitle: string;
      miniParagraphsAnchorTitle: string;
      midSectionTitleWithMediaAnchorTitle: string;
      richtextSectionAnchorTitle: string;
      midFaqSectionAnchorTitle: string;
      alternatingTeasersAnchorTitle: string;
      bottomAccordionfaqSectionAnchorTitle: string;
      bottomPromotionCardAnchorTitle: string;
      heroBanner: ({ __typename: 'Teaser' } & AssemblySolutionsHeroBannerFragment);
      introSectionPreamble:
        | ({
            __typename: 'AssemblySolutionsFlexibleIntroSectionPreamble';
          } & IntroSectionPreambleFragment)
       ;
      introSectionMedia:
        | {
            __typename: 'Image';
            altText: string;
            focusArea: string;
            image: { __typename: 'Asset'; url: string };
          }
        | { __typename: 'Video'; video: { __typename: 'Asset'; url: string } }
       ;
      uspPreamble: { __typename: 'AssemblySolutionsFlexibleUspPreamble'; json: any };
      uspIconsCollection: {
        __typename: 'AssemblySolutionsFlexibleUspIconsCollection';
        items: Array<{
          __typename: 'UspEntity';
          title: string;
          description: string;
          icon: string;
        }>;
      };
      topCardsCollection: {
        __typename: 'AssemblySolutionsFlexibleTopCardsCollection';
        items: Array<
          | ({ __typename: 'Article' } & AssemblyLevel0TopCarouselItemsCollectionArticleFragment)
          | ({ __typename: 'Teaser' } & TeaserCardFragment)
         
        >;
      };
      miniParagraphs: {
        __typename: 'FaqSection';
        sectionTitle: string;
        faqSectionCollection: {
          __typename: 'FaqSectionFaqSectionCollection';
          items: Array<{
            __typename: 'FactBox';
            title: string;
            body: { __typename: 'FactBoxBody'; json: any };
          }>;
        };
      };
      midSectionTitleWithMedia:
        | ({ __typename: 'Teaser' } & AssemblySolutionsHeroBannerFragment)
       ;
      midFaqSection: {
        __typename: 'FaqSection';
        sectionTitle: string;
        backgroundTitle: {
          __typename: 'Image';
          altText: string;
          image: { __typename: 'Asset'; url: string };
        };
        faqSectionCollection: {
          __typename: 'FaqSectionFaqSectionCollection';
          items: Array<{
            __typename: 'FactBox';
            title: string;
            body: { __typename: 'FactBoxBody'; json: any };
            tagsCollection: {
              __typename: 'FactBoxTagsCollection';
              items: Array<{
                __typename: 'Tag';
                name: string;
                category: string;
              }>;
            };
          }>;
        };
      };
      richtextSection:
        | ({ __typename: 'AssemblySolutionsFlexibleRichtextSection' } & RichtextSectionFragment)
       ;
      alternatingTeasersCollection: {
        __typename: 'AssemblySolutionsFlexibleAlternatingTeasersCollection';
        items: Array<({ __typename: 'Teaser' } & TeaserCardFragment)>;
      };
      bottomAccordionfaqSection: {
        __typename: 'FaqSection';
        sectionTitle: string;
        backgroundTitle: {
          __typename: 'Image';
          altText: string;
          image: { __typename: 'Asset'; url: string };
        };
        faqSectionCollection: {
          __typename: 'FaqSectionFaqSectionCollection';
          items: Array<{
            __typename: 'FactBox';
            title: string;
            body: { __typename: 'FactBoxBody'; json: any };
            tagsCollection: {
              __typename: 'FactBoxTagsCollection';
              items: Array<{
                __typename: 'Tag';
                name: string;
                category: string;
              }>;
            };
          }>;
        };
      };
      bottomPromotionCard: ({ __typename: 'Teaser' } & TeaserCardFragment);
      seoMetadata: ({ __typename: 'Seo' } & SeoMetadataFragment);
    }>;
  };
};

export type IntroSectionPreambleFragment = {
  __typename: 'AssemblySolutionsFlexibleIntroSectionPreamble';
  json: any;
  links: {
    __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleLinks';
    entries: {
      __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleEntries';
      block: Array<
        | { __typename: 'AlertNotification'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AlertWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AnnualSustainabilityReport'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersAboutLifeAtTelia'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersTeamsPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCollectionPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel1'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyMenuTeasers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyOpenPositions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyReportsAndPresentations'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutionsFlexible'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Author'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'BoardMember'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'CodeOfConduct'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'CtaButton';
            ctaText: string;
            linkTrigger: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | {
            __typename: 'EmbedMedia';
            url: string;
            internalName: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'Event'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Image';
            hyperlink: string | null;
            image: {
              __typename: 'Asset';
              title: string;
              url: string;
              description: string;
            };
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'NewsroomAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Person'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'PersonalisationSegments'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectRule'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SectionAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SupportComposition'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TagWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeamCardsWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeaserWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntityWrapper'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Video';
            video: { __typename: 'Asset'; title: string; url: string };
            sys: { __typename: 'Sys'; id: string };
          }
       
      >;
    };
    assets: {
      __typename: 'AssemblySolutionsFlexibleIntroSectionPreambleAssets';
      hyperlink: Array<{
        __typename: 'Asset';
        contentType: string;
        title: string;
        url: string;
        sys: { __typename: 'Sys'; id: string };
      }>;
    };
  };
};

export type RichtextSectionFragment = {
  __typename: 'AssemblySolutionsFlexibleRichtextSection';
  json: any;
  links: {
    __typename: 'AssemblySolutionsFlexibleRichtextSectionLinks';
    entries: {
      __typename: 'AssemblySolutionsFlexibleRichtextSectionEntries';
      block: Array<
        | { __typename: 'AlertNotification'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AlertWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AnnualSustainabilityReport'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Article'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersAboutLifeAtTelia'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCareersTeamsPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyCollectionPage'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel0'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyLevel1'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyMenuTeasers'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyOpenPositions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblyReportsAndPresentations'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutions'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'AssemblySolutionsFlexible'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Author'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'BoardMember'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Campaign'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'CodeOfConduct'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'CtaButton';
            ctaText: string;
            linkTrigger: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | {
            __typename: 'EmbedMedia';
            url: string;
            internalName: string;
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'Event'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBox'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FactBoxWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'FaqSection'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Image';
            hyperlink: string | null;
            image: {
              __typename: 'Asset';
              title: string;
              url: string;
              description: string;
            };
            sys: { __typename: 'Sys'; id: string };
          }
        | { __typename: 'NewsroomAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Person'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'PersonalisationSegments'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'RedirectRule'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Resource'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'ResourceSet'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SectionAssembly'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Seo'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'SupportComposition'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Table'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Tag'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TagWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeamCardsWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'Teaser'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'TeaserWrapper'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntity'; sys: { __typename: 'Sys'; id: string } }
        | { __typename: 'UspEntityWrapper'; sys: { __typename: 'Sys'; id: string } }
        | {
            __typename: 'Video';
            video: { __typename: 'Asset'; title: string; url: string };
            sys: { __typename: 'Sys'; id: string };
          }
       
      >;
    };
    assets: {
      __typename: 'AssemblySolutionsFlexibleRichtextSectionAssets';
      hyperlink: Array<{
        __typename: 'Asset';
        contentType: string;
        title: string;
        url: string;
        sys: { __typename: 'Sys'; id: string };
      }>;
    };
  };
};

export type GetTagsWithFilteredRelatedArticlesQueryVariables = Exact<{
  tagsFilter: InputMaybe<Array<TagFilter> | TagFilter>;
  locale: Scalars['String'];
}>;

export type GetTagsWithFilteredRelatedArticlesQuery = {
  __typename: 'Query';
  data: ({ __typename: 'TagCollection' } & RelatedArticlesFromStandaloneTagsFragment);
};

export type TeaserCardFragment = {
  __typename: 'Teaser';
  title: string;
  teaserUrl: string | null;
  tagline: string | null;
  richPreamble: { __typename: 'TeaserRichPreamble'; json: any } | null;
  primaryMediaImage: {
    __typename: 'Image';
    focusArea: string;
    altText: string;
    image: { __typename: 'Asset'; url: string };
  } | null;
  callToActionCollection: {
    __typename: 'TeaserCallToActionCollection';
    items: Array<{ __typename: 'CtaButton'; text: string; url: string }>;
  };
};

export type WebcastArticleQueryVariables = Exact<{
  slug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type WebcastArticleQuery = {
  __typename: 'Query';
  articleCollection: {
    __typename: 'ArticleCollection';
    items: Array<
      | ({
          __typename: 'Article';
          readableId: string;
          title: string;
          publicationDate: any | null;
          embedMedia: { __typename: 'EmbedMedia'; url: string } | null;
          body: ({ __typename: 'ArticleBody' } & ArticleBodyFragment) | null;
          sys: { __typename: 'Sys'; publishedAt: any };
        } & ArticleSlugsForAllLocalesFragment)
     
    >;
  };
};

export const AnnualSustainabilityReportsBillboardTeaserFragmentDoc = gql`
  fragment AnnualSustainabilityReportsBillboardTeaser on Teaser {
    teaserUrl
    title
    richPreamble {
      json
    }
    callToActionCollection {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
    tagline
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const AnnualSustainabilityReportsHeroBannerFragmentDoc = gql`
  fragment AnnualSustainabilityReportsHeroBanner on Teaser {
    title
    tagline
    slug: teaserUrl
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
    primaryMediaVideo {
      video {
        url
      }
    }
    callToActionCollection {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const ContentfulMetadataFragmentFragmentDoc = gql`
  fragment ContentfulMetadataFragment on Article {
    contentfulMetadata {
      tags {
        id
        name
      }
    }
  }
`;
export const AnnualSustainabilityReportsLinkedArticlesArticleFragmentDoc = gql`
  fragment AnnualSustainabilityReportsLinkedArticlesArticle on Article {
    ...ContentfulMetadataFragment
    title
    slug
    preamble
    category
    images: mediaImageCollection(limit: 1) {
      items {
        altText
        focusArea
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AnnualSustainabilityReportsLinkedArticlesTeaserFragmentDoc = gql`
  fragment AnnualSustainabilityReportsLinkedArticlesTeaser on Teaser {
    title
    teaserUrl
    tagline
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const ArticleSlugsForAllLocalesFragmentDoc = gql`
  fragment ArticleSlugsForAllLocales on Article {
    svSlug: slug(locale: "sv")
    enSlug: slug(locale: "en-US")
  }
`;
export const ArticleBodyBlockPersonFragmentDoc = gql`
  fragment ArticleBodyBlockPerson on Person {
    fullName
    position
    slug
    tag {
      name
    }
    images: mediaImagesCollection(limit: 1) {
      items {
        focusArea
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const ArticleBodyFragmentDoc = gql`
  fragment ArticleBody on ArticleBody {
    json
    links {
      entries {
        block {
          sys {
            id
          }
          __typename
          ... on Image {
            image {
              title
              url(transform: { format: WEBP })
              description
            }
            hyperlink
          }
          ... on Video {
            video {
              title
              url
            }
          }
          ... on EmbedMedia {
            url
            internalName
          }
          ... on Person {
            ...ArticleBodyBlockPerson
          }
          ... on CtaButton {
            ctaText
            linkTrigger
          }
          ... on Event {
            title
            body {
              json
            }
            date
            mediaGroupsCollection {
              items {
                body {
                  json
                }
                callToActionCollection(limit: 1) {
                  items {
                    url: linkTrigger
                  }
                }
              }
            }
          }
        }
      }
      assets {
        hyperlink {
          sys {
            id
          }
          contentType
          title
          url
        }
      }
    }
  }
`;
export const SeoMetadataFragmentDoc = gql`
  fragment SeoMetadata on Seo {
    title
    description
    keywords
    noIndex
    noFollow
    xRobotsTag
    socialMediaSiteName
    socialMediaTitle
    socialMediaDescription
    socialMediaImage {
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    ...ContentfulMetadataFragment
    readableId
    title
    ...ArticleSlugsForAllLocales
    category
    preamble
    mediaImageCollection(limit: 1) {
      items {
        focusArea
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
    mediaVideoCollection(limit: 1) {
      items {
        video {
          url
        }
        posterImage {
          focusArea
          altText
          image {
            url(transform: { format: WEBP })
          }
        }
      }
    }
    body {
      ...ArticleBody
    }
    tagsCollection {
      items {
        value
      }
    }
    seoMetadata {
      ...SeoMetadata
    }
  }
`;
export const ArticleCardFragmentDoc = gql`
  fragment ArticleCard on Article {
    __typename
    slug
    title
    preamble
    images: mediaImageCollection(limit: 1) {
      items {
        focusArea
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AssemblySolutionsHeroBannerFragmentDoc = gql`
  fragment AssemblySolutionsHeroBanner on Teaser {
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
    primaryMediaVideo {
      video {
        url
      }
    }
  }
`;
export const DefinitionsContentFragmentDoc = gql`
  fragment DefinitionsContent on Tag {
    linkedFrom(allowedLocales: ["en-US", "sv"]) {
      factBoxCollection {
        items {
          title
          body {
            json
          }
        }
      }
    }
  }
`;
export const ImageFieldsFragmentDoc = gql`
  fragment ImageFields on Image {
    focusArea
    altText
    image {
      url(transform: { format: WEBP })
    }
    contentfulMetadata {
      tags {
        name
      }
    }
  }
`;
export const AssemblyLevel0BottomCardItemsCollectionFragmentDoc = gql`
  fragment AssemblyLevel0BottomCardItemsCollection on Article {
    ...ContentfulMetadataFragment
    slug
    title
    category
  }
`;
export const Level0CarouselTeaserFieldsFragmentDoc = gql`
  fragment Level0CarouselTeaserFields on Teaser {
    title
    tagline
    teaserUrl
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const AssemblyLevel0HeroBannerFragmentDoc = gql`
  fragment AssemblyLevel0HeroBanner on Teaser {
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
    primaryMediaVideo {
      video {
        url
      }
    }
    callToActionCollection(limit: 1) {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const AssemblyLevel0TopCarouselItemsCollectionArticleFragmentDoc = gql`
  fragment AssemblyLevel0TopCarouselItemsCollectionArticle on Article {
    ...ContentfulMetadataFragment
    title
    slug
    category
    preamble
    images: mediaImageCollection(limit: 1) {
      items {
        altText
        focusArea
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AssemblyLevel1BillboardTeaserFragmentDoc = gql`
  fragment AssemblyLevel1BillboardTeaser on Teaser {
    teaserUrl
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
    tagline
    callToActionCollection {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const AssemblyLevel1BottomCarouselItemsCollectionArticleFragmentDoc = gql`
  fragment AssemblyLevel1BottomCarouselItemsCollectionArticle on Article {
    ...ContentfulMetadataFragment
    title
    slug
    preamble
    category
    images: mediaImageCollection(limit: 1) {
      items {
        altText
        focusArea
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AssemblyLevel1BottomCarouselItemsCollectionTeaserFragmentDoc = gql`
  fragment AssemblyLevel1BottomCarouselItemsCollectionTeaser on Teaser {
    title
    tagline
    teaserUrl
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const AssemblyLevel1HeroBannerFragmentDoc = gql`
  fragment AssemblyLevel1HeroBanner on Teaser {
    title
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
    primaryMediaVideo {
      video {
        url
      }
    }
    callToActionCollection(limit: 1) {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const AssemblyLevel1TopFirstCarouselItemsCollectionArticleFragmentDoc = gql`
  fragment AssemblyLevel1TopFirstCarouselItemsCollectionArticle on Article {
    ...ContentfulMetadataFragment
    title
    slug
    preamble
    category
    images: mediaImageCollection(limit: 1) {
      items {
        altText
        focusArea
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AssemblyLevel1TopFirstCarouselItemsCollectionTeaserFragmentDoc = gql`
  fragment AssemblyLevel1TopFirstCarouselItemsCollectionTeaser on Teaser {
    title
    tagline
    teaserUrl
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const AssemblyLevel1TopSecondCarouselItemsCollectionArticleFragmentDoc = gql`
  fragment AssemblyLevel1TopSecondCarouselItemsCollectionArticle on Article {
    ...ContentfulMetadataFragment
    title
    slug
    preamble
    category
    images: mediaImageCollection(limit: 1) {
      items {
        altText
        focusArea
        image {
          url(transform: { format: WEBP })
        }
      }
    }
  }
`;
export const AssemblyLevel1TopSecondCarouselItemsCollectionTeaserFragmentDoc = gql`
  fragment AssemblyLevel1TopSecondCarouselItemsCollectionTeaser on Teaser {
    title
    teaserUrl
    tagline
    richPreamble {
      json
    }
    primaryMediaImage {
      altText
      focusArea
      image {
        url(transform: { format: WEBP })
      }
    }
  }
`;
export const NewsArticleArticleFragmentDoc = gql`
  fragment NewsArticleArticle on Article {
    ...ContentfulMetadataFragment
    readableId
    ...ArticleSlugsForAllLocales
    title
    publicationDate
    sys {
      publishedAt
    }
    preamble
    category
    body {
      ...ArticleBody
    }
    tagsCollection {
      items {
        value
      }
    }
  }
`;
export const RelatedArticlesFromStandaloneTagsFragmentDoc = gql`
  fragment RelatedArticlesFromStandaloneTags on TagCollection {
    items {
      ... on Tag {
        name
        value
        linkedFrom(allowedLocales: ["en-US", "sv"]) {
          relatedArticles: articleCollection(order: [publicationDate_DESC]) {
            items {
              ... on Article {
                ...ContentfulMetadataFragment
                title
                slug
                category
                publicationDate
                sys {
                  publishedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const ReportsAndPresentationsEventFieldsFragmentDoc = gql`
  fragment ReportsAndPresentationsEventFields on Event {
    internalName
    title
    mediaGroupsCollection(limit: 4) {
      items {
        mediaAssetsCollection {
          items {
            title
            url
            contentType
          }
        }
      }
    }
    webcastsCollection(limit: 30) {
      items {
        ... on Article {
          title
          slug
        }
      }
    }
  }
`;
export const ReportsAndPresentationsAssemblyFieldsFragmentDoc = gql`
  fragment ReportsAndPresentationsAssemblyFields on AssemblyReportsAndPresentations {
    year
    heroBanner {
      title
      preamble: richPreamble {
        json
      }
      primaryMediaImage {
        altText
        image {
          url(transform: { format: WEBP })
        }
      }
    }
    eventsCollection(limit: 10) {
      items {
        ...ReportsAndPresentationsEventFields
      }
    }
    seoMetadata {
      ...SeoMetadata
    }
  }
`;
export const IntroSectionPreambleFragmentDoc = gql`
  fragment IntroSectionPreamble on AssemblySolutionsFlexibleIntroSectionPreamble {
    json
    links {
      entries {
        block {
          sys {
            id
          }
          __typename
          ... on Image {
            image {
              title
              url(transform: { format: WEBP })
              description
            }
            hyperlink
          }
          ... on Video {
            video {
              title
              url
            }
          }
          ... on EmbedMedia {
            url
            internalName
          }
          ... on CtaButton {
            ctaText
            linkTrigger
          }
        }
      }
      assets {
        hyperlink {
          sys {
            id
          }
          contentType
          title
          url
        }
      }
    }
  }
`;
export const RichtextSectionFragmentDoc = gql`
  fragment richtextSection on AssemblySolutionsFlexibleRichtextSection {
    json
    links {
      entries {
        block {
          sys {
            id
          }
          __typename
          ... on Image {
            image {
              title
              url(transform: { format: WEBP })
              description
            }
            hyperlink
          }
          ... on Video {
            video {
              title
              url
            }
          }
          ... on EmbedMedia {
            url
            internalName
          }
          ... on CtaButton {
            ctaText
            linkTrigger
          }
        }
      }
      assets {
        hyperlink {
          sys {
            id
          }
          contentType
          title
          url
        }
      }
    }
  }
`;
export const TeaserCardFragmentDoc = gql`
  fragment TeaserCard on Teaser {
    __typename
    title
    teaserUrl
    richPreamble {
      json
    }
    tagline
    primaryMediaImage {
      focusArea
      altText
      image {
        url(transform: { format: WEBP })
      }
    }
    callToActionCollection(limit: 1) {
      items {
        text: ctaText
        url: linkTrigger
      }
    }
  }
`;
export const GetAllAnnualGeneralMeetingYearsDocument = gql`
  query GetAllAnnualGeneralMeetingYears($locale: String!) {
    allYears: eventCollection(where: { type: "Annual General Meeting" }) {
      items {
        date
      }
    }
    heroBanner: teaserCollection(
      limit: 1
      where: { internalName: "annual-general-meeting-hero-banner" }
      locale: $locale
    ) {
      items {
        title
        richPreamble {
          json
        }
        primaryMediaImage {
          altText
          image {
            url(transform: { format: WEBP })
          }
        }
      }
    }
  }
`;
export const GetAnnualGeneralMeetingsByYearDocument = gql`
  query GetAnnualGeneralMeetingsByYear(
    $date_gte: DateTime!
    $date_lte: DateTime!
    $locale: String!
  ) {
    data: eventCollection(
      limit: 5
      where: { type: "Annual General Meeting", date_gte: $date_gte, date_lte: $date_lte }
      locale: $locale
    ) {
      items {
        title
        date
        body {
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on Image {
                  image {
                    title
                    url(transform: { format: WEBP })
                  }
                  hyperlink
                }
                ... on Video {
                  video {
                    title
                    url
                  }
                }
                ... on EmbedMedia {
                  url
                  internalName
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                contentType
                title
                url
              }
            }
          }
        }
        mediaGroupsCollection {
          items {
            title
            mediaAssetsCollection(limit: 10) {
              items {
                title
                description
                url
                contentType
              }
            }
          }
        }
        webcastsCollection(limit: 30) {
          items {
            ... on Article {
              title
              slug
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${SeoMetadataFragmentDoc}
`;
export const GetAnnualSustainabilityReportsDocument = gql`
  query GetAnnualSustainabilityReports($locale: String) {
    data: annualSustainabilityReportCollection(limit: 1, locale: $locale) {
      items {
        heroBanner {
          ... on Teaser {
            ...AnnualSustainabilityReportsHeroBanner
          }
        }
        introTitle
        introPreamble
        introCta {
          ctaText
          linkTrigger
        }
        introVideo {
          url
          contentType
          description
        }
        billboard {
          ... on Teaser {
            ...AnnualSustainabilityReportsBillboardTeaser
          }
        }
        graphicImagesCollection(limit: 2) {
          items {
            contentfulMetadata {
              tags {
                id
                name
              }
            }
            description
            url
          }
        }
        graphicImages2Collection(limit: 2) {
          items {
            contentfulMetadata {
              tags {
                id
                name
              }
            }
            description
            url
          }
        }
        linkedArticlesCollection(limit: 4) {
          items {
            ... on Article {
              ...AnnualSustainabilityReportsLinkedArticlesArticle
            }
            ... on Teaser {
              ...AnnualSustainabilityReportsLinkedArticlesTeaser
            }
          }
        }
        linkedArticle {
          ... on Article {
            ...AnnualSustainabilityReportsLinkedArticlesArticle
          }
          ... on Teaser {
            ...AnnualSustainabilityReportsLinkedArticlesTeaser
          }
        }
      }
    }
  }
  ${AnnualSustainabilityReportsHeroBannerFragmentDoc}
  ${AnnualSustainabilityReportsBillboardTeaserFragmentDoc}
  ${AnnualSustainabilityReportsLinkedArticlesArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${AnnualSustainabilityReportsLinkedArticlesTeaserFragmentDoc}
`;
export const GetArticleDocument = gql`
  query GetArticle($id: String!, $locale: String) {
    article(id: $id, locale: $locale) {
      ... on Article {
        ...Article
      }
    }
  }
  ${ArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${ArticleSlugsForAllLocalesFragmentDoc}
  ${ArticleBodyFragmentDoc}
  ${ArticleBodyBlockPersonFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetArticleIdDocument = gql`
  query GetArticleId($slug: String!) {
    enData: articleCollection(limit: 1, where: { slug: $slug }, locale: "en-US") {
      items {
        sys {
          id
        }
      }
    }
    svData: articleCollection(limit: 1, where: { slug: $slug }, locale: "sv") {
      items {
        sys {
          id
        }
      }
    }
  }
`;
export const GetAssemblySolutionsDocument = gql`
  query getAssemblySolutions($slug: String!, $locale: String!) {
    data: assemblySolutionsCollection(limit: 1, locale: $locale, where: { slug: $slug }) {
      items {
        heroBanner {
          ... on Teaser {
            ...AssemblySolutionsHeroBanner
          }
        }
        introSectionTitle
        introSectionCarouselCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
          }
        }
        introSectionCtaLink {
          ctaText
          linkTrigger
        }
        uspTitle
        uspPreamble {
          json
        }
        uspIconsCollection(limit: 4) {
          items {
            title
            description
            icon
          }
        }
        uspCardsTitle
        uspCardsPreamble {
          json
        }
        uspCardsCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
            ... on Article {
              ...AssemblyLevel0TopCarouselItemsCollectionArticle
            }
          }
        }
        uspCardsCtaLink {
          ctaText
          linkTrigger
        }
        alternatingTeasersTitle
        alternatingTeasersCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
          }
        }
        alternatingTeasersCtaLink {
          ctaText
          linkTrigger
        }
        bottomCardsTitle
        bottomCardsPreamble {
          json
        }
        bottomCardsCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
            ... on Article {
              ...AssemblyLevel0TopCarouselItemsCollectionArticle
            }
          }
        }
        bottomPromotionCard {
          ... on Teaser {
            ...TeaserCard
          }
        }
        bottomPromotionCardType
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${AssemblySolutionsHeroBannerFragmentDoc}
  ${TeaserCardFragmentDoc}
  ${AssemblyLevel0TopCarouselItemsCollectionArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetAboutLifeAtTeliaDocument = gql`
  query GetAboutLifeAtTelia($locale: String!) {
    data: assemblyCareersAboutLifeAtTeliaCollection(limit: 1, locale: $locale) {
      __typename
      items {
        heroBanner {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        body {
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on Image {
                  image {
                    title
                    url(transform: { format: WEBP })
                    description
                  }
                  hyperlink
                }
                ... on Video {
                  video {
                    title
                    url
                  }
                }
                ... on EmbedMedia {
                  url
                  internalName
                }
                ... on CtaButton {
                  ctaText
                  linkTrigger
                }
              }
            }
            assets {
              hyperlink {
                sys {
                  id
                }
                contentType
                title
                url
              }
            }
          }
        }
        teamCards {
          title
          preamble
          cta {
            text: ctaText
            url: linkTrigger
          }
          cardsCollection(limit: 8) {
            items {
              ... on Article {
                ...ArticleCard
              }
              ... on Teaser {
                ...TeaserCard
              }
            }
          }
        }
        bottomCardsCollection(limit: 2) {
          items {
            ... on Teaser {
              ...TeaserCard
            }
            ... on Article {
              ...ArticleCard
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${ArticleCardFragmentDoc}
  ${TeaserCardFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetOpenPositionsCollectionDocument = gql`
  query GetOpenPositionsCollection($locale: String!) {
    data: assemblyOpenPositionsCollection(limit: 1, locale: $locale) {
      __typename
      items {
        internalName
        heroBanner {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${SeoMetadataFragmentDoc}
`;
export const GetCareersPageDocument = gql`
  query GetCareersPage($locale: String!) {
    data: assemblyCareersCollection(limit: 1, locale: $locale) {
      items {
        heroBanner {
          title
          callToActionCollection(limit: 1) {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        richPreamble {
          json
        }
        storyCardsTitle
        storyCardsCollection(limit: 4) {
          items {
            ... on Article {
              ...ArticleCard
            }
            ... on Teaser {
              ...TeaserCard
            }
          }
        }
        storyCardsLink {
          text: ctaText
          url: linkTrigger
        }
        teamCards {
          title
          preamble
          cta {
            text: ctaText
            url: linkTrigger
          }
          cardsCollection(limit: 8) {
            items {
              ... on Article {
                ...ArticleCard
              }
              ... on Teaser {
                ...TeaserCard
              }
            }
          }
        }
        additionalCardsCollection(limit: 2) {
          items {
            ... on Article {
              ...ArticleCard
            }
            ... on Teaser {
              ...TeaserCard
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${ArticleCardFragmentDoc}
  ${TeaserCardFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetCareersTeamsPageDocument = gql`
  query GetCareersTeamsPage($locale: String!) {
    data: assemblyCareersTeamsPageCollection(limit: 1, locale: $locale) {
      __typename
      items {
        heroBanner {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        cardsTitle
        cardsPreamble
        cardsCollection {
          items {
            ... on Article {
              ...ArticleCard
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${ArticleCardFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetCodeOfConductCollectionDocument = gql`
  query GetCodeOfConductCollection($locale: String!) {
    data: codeOfConductCollection(limit: 1, locale: $locale) {
      items {
        heroBanner {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        description {
          json
        }
        billboard {
          teaserUrl
          title
          richPreamble {
            json
          }
          callToActionCollection(limit: 5) {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
          tagline
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        faqCollection(limit: 5) {
          items {
            sectionTitle
            backgroundTitle {
              altText
              image {
                url(transform: { format: WEBP })
              }
            }
            faqSectionCollection(limit: 25) {
              items {
                title
                body {
                  json
                }
                tagsCollection(limit: 3) {
                  items {
                    name
                    category
                  }
                }
              }
            }
          }
        }
        bottomCard {
          teaserUrl
          title
          richPreamble {
            json
          }
          callToActionCollection {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
          tagline
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
      }
    }
  }
`;
export const GetAssemblyCollectionPageDocument = gql`
  query GetAssemblyCollectionPage($id: String!, $locale: String!) {
    assemblyCollectionPage(id: $id, locale: $locale) {
      svSlug: slug(locale: "sv")
      enSlug: slug(locale: "en-US")
      readableId
      heroBanner {
        title
        richPreamble {
          json
        }
        image: primaryMediaImage {
          altText
          focusArea
          image {
            url(transform: { format: WEBP })
          }
        }
      }
      description {
        json
      }
      sectionsCollection {
        items {
          internalName
          description {
            json
          }
          type
          contentCollection {
            items {
              __typename
              ... on Article {
                slug
                title
                preamble
                images: mediaImageCollection(limit: 1) {
                  items {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on AssemblyLevel0 {
                heroBanner {
                  title
                  richPreamble {
                    json
                  }
                  image: primaryMediaImage {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on AssemblyLevel1 {
                slug
                heroBanner {
                  title
                  richPreamble {
                    json
                  }
                  image: primaryMediaImage {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on AssemblyReportsAndPresentations {
                year
                heroBanner {
                  title
                }
              }
              ... on NewsroomAssembly {
                heroBanner {
                  title
                  richPreamble {
                    json
                  }
                  image: primaryMediaImage {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on Person {
                fullName
                position
                slug
                tag {
                  name
                }
                images: mediaImagesCollection(limit: 1) {
                  items {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on AssemblyCollectionPage {
                slug
                heroBanner {
                  title
                  richPreamble {
                    json
                  }
                  primaryMediaImage {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
              ... on AssemblySolutionsFlexible {
                slug
                heroBanner {
                  title
                  richPreamble {
                    json
                  }
                  primaryMediaImage {
                    focusArea
                    altText
                    image {
                      url(transform: { format: WEBP })
                    }
                  }
                }
              }
            }
          }
        }
      }
      seoMetadata {
        ...SeoMetadata
      }
    }
  }
  ${SeoMetadataFragmentDoc}
`;
export const GetAssemblyCollectionPageIdDocument = gql`
  query GetAssemblyCollectionPageId($slug: String!) {
    enData: assemblyCollectionPageCollection(limit: 1, where: { slug: $slug }, locale: "en-US") {
      items {
        sys {
          id
        }
      }
    }
    svData: assemblyCollectionPageCollection(limit: 1, where: { slug: $slug }, locale: "sv") {
      items {
        sys {
          id
        }
      }
    }
  }
`;
export const GetAssemblyLevel0CollectionDocument = gql`
  query GetAssemblyLevel0Collection($locale: String!) {
    data: assemblyLevel0Collection(limit: 1, locale: $locale) {
      items {
        heroBanner {
          ... on Teaser {
            ...AssemblyLevel0HeroBanner
          }
        }
        topCarouselHeading
        topCarouselItemsCollection {
          items {
            ... on Article {
              ...AssemblyLevel0TopCarouselItemsCollectionArticle
            }
            ... on Teaser {
              ...Level0CarouselTeaserFields
            }
          }
        }
        facts {
          factBoxesCollection(limit: 8) {
            items {
              title
              body {
                json
              }
            }
          }
        }
        topBillboard {
          teaserUrl
          title
          richPreamble {
            json
          }
          callToActionCollection {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
          tagline
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        campaignCardsCollection(limit: 4) {
          items {
            ... on Teaser {
              ...Level0CarouselTeaserFields
            }
          }
        }
        filteredItemsHeading
        filteredItemsCta {
          text: ctaText
          url: linkTrigger
        }
        bottomBillboard {
          teaserUrl
          title
          richPreamble {
            json
          }
          callToActionCollection {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
          tagline
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${AssemblyLevel0HeroBannerFragmentDoc}
  ${AssemblyLevel0TopCarouselItemsCollectionArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${Level0CarouselTeaserFieldsFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetAssemblyLevel1CollectionDocument = gql`
  query GetAssemblyLevel1Collection($slug: String!, $locale: String!) {
    data: assemblyLevel1Collection(limit: 1, where: { slug: $slug }, locale: $locale) {
      items {
        enSlug: slug(locale: "en-US")
        svSlug: slug(locale: "sv")
        heroBanner {
          ... on Teaser {
            ...AssemblyLevel1HeroBanner
          }
        }
        topFirstCarouselHeading
        topFirstCarouselItemsCollection(limit: 3) {
          items {
            ... on Article {
              ...AssemblyLevel1TopFirstCarouselItemsCollectionArticle
            }
            ... on Teaser {
              ...AssemblyLevel1TopFirstCarouselItemsCollectionTeaser
            }
          }
        }
        topSecondCarouselHeading
        topSecondCarouselItemsCollection {
          items {
            ... on Article {
              ...AssemblyLevel1TopSecondCarouselItemsCollectionArticle
            }
            ... on Teaser {
              ...AssemblyLevel1TopSecondCarouselItemsCollectionTeaser
            }
          }
        }
        billboard {
          ... on Teaser {
            ...AssemblyLevel1BillboardTeaser
          }
        }
        tableToggle
        tableHeading
        tableCta {
          text: ctaText
          url: linkTrigger
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${AssemblyLevel1HeroBannerFragmentDoc}
  ${AssemblyLevel1TopFirstCarouselItemsCollectionArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${AssemblyLevel1TopFirstCarouselItemsCollectionTeaserFragmentDoc}
  ${AssemblyLevel1TopSecondCarouselItemsCollectionArticleFragmentDoc}
  ${AssemblyLevel1TopSecondCarouselItemsCollectionTeaserFragmentDoc}
  ${AssemblyLevel1BillboardTeaserFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetMenuTeasersDocument = gql`
  query getMenuTeasers($locale: String) {
    data: assemblyMenuTeasersCollection(limit: 1, locale: $locale) {
      items {
        aboutTheCompany {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        solutions {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        newsroom {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        investors {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        sustainability {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        careers {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
        contactUs {
          ... on Teaser {
            ...TeaserCard
          }
          ... on Article {
            ...ArticleCard
          }
        }
      }
    }
  }
  ${TeaserCardFragmentDoc}
  ${ArticleCardFragmentDoc}
`;
export const GetNewsArticleDocument = gql`
  query GetNewsArticle($id: String!, $locale: String!) {
    article(id: $id, locale: $locale) {
      ... on Article {
        ...NewsArticleArticle
      }
    }
  }
  ${NewsArticleArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${ArticleSlugsForAllLocalesFragmentDoc}
  ${ArticleBodyFragmentDoc}
  ${ArticleBodyBlockPersonFragmentDoc}
`;
export const GetNewsroomAssemblyLevelCollectionDocument = gql`
  query GetNewsroomAssemblyLevelCollection($locale: String!) {
    data: newsroomAssemblyCollection(limit: 1, locale: $locale) {
      __typename
      items {
        internalName
        heroBanner {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
        }
        pressAndMediaBillboard {
          title
          richPreamble {
            json
          }
          primaryMediaImage {
            altText
            image {
              url(transform: { format: WEBP })
            }
          }
          callToActionCollection {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
        }
        mediaKitBillboard {
          title
          richPreamble {
            json
          }
          callToActionCollection {
            items {
              text: ctaText
              url: linkTrigger
            }
          }
        }
        seoMetadata {
          ...SeoMetadata
        }
      }
    }
  }
  ${SeoMetadataFragmentDoc}
`;
export const GetPersonDocument = gql`
  query GetPerson($slug: String!, $locale: String!) {
    data: personCollection(limit: 1, where: { slug: $slug }, locale: $locale) {
      items {
        readableId
        enSlug: slug
        svSlug: slug
        fullName
        position
        bio {
          json
        }
        tag {
          name
        }
        mediaImagesCollection {
          items {
            ...ImageFields
          }
        }
      }
    }
  }
  ${ImageFieldsFragmentDoc}
`;
export const ReportsAndPresentationsByYearDocument = gql`
  query ReportsAndPresentationsByYear($locale: String!, $year: Int) {
    assemblyContent: assemblyReportsAndPresentationsCollection(
      where: { year: $year }
      limit: 1
      locale: $locale
      order: [year_DESC]
    ) {
      items {
        ...ReportsAndPresentationsAssemblyFields
      }
    }
  }
  ${ReportsAndPresentationsAssemblyFieldsFragmentDoc}
  ${ReportsAndPresentationsEventFieldsFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const ReportsAndPresentationsContentDocument = gql`
  query ReportsAndPresentationsContent($locale: String!) {
    definitionsContent: tagCollection(locale: $locale, where: { value: "Definition" }) {
      items {
        ...DefinitionsContent
      }
    }
    availableYears: assemblyReportsAndPresentationsCollection(locale: $locale, order: [year_ASC]) {
      items {
        year
      }
    }
    resourceCollection(
      locale: $locale
      where: { OR: [{ key: "download" }, { key: "read-more" }, { key: "watch" }] }
    ) {
      items {
        key
        value
      }
    }
  }
  ${DefinitionsContentFragmentDoc}
`;
export const GetAssemblySolutionsFlexibleDocument = gql`
  query getAssemblySolutionsFlexible($slug: String!, $locale: String!) {
    data: assemblySolutionsFlexibleCollection(limit: 1, locale: $locale, where: { slug: $slug }) {
      items {
        heroBanner {
          ... on Teaser {
            ...AssemblySolutionsHeroBanner
          }
        }
        introSectionTitle
        introSectionPreamble {
          ...IntroSectionPreamble
        }
        introSectionMedia {
          ... on Image {
            altText
            focusArea
            image {
              url(transform: { format: WEBP })
            }
          }
          ... on Video {
            video {
              url
            }
          }
        }
        uspTitle
        uspPreamble {
          json
        }
        uspIconsCollection(limit: 4) {
          items {
            title
            description
            icon
          }
        }
        topCardsTitle
        topCardsCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
            ... on Article {
              ...AssemblyLevel0TopCarouselItemsCollectionArticle
            }
          }
        }
        miniParagraphs {
          sectionTitle
          faqSectionCollection(limit: 25) {
            items {
              title
              body {
                json
              }
            }
          }
        }
        midSectionTitleWithMedia {
          ... on Teaser {
            ...AssemblySolutionsHeroBanner
          }
        }
        midFaqSection {
          sectionTitle
          backgroundTitle {
            altText
            image {
              url(transform: { format: WEBP })
            }
          }
          faqSectionCollection(limit: 25) {
            items {
              title
              body {
                json
              }
              tagsCollection(limit: 3) {
                items {
                  name
                  category
                }
              }
            }
          }
        }
        richtextSection {
          ...richtextSection
        }
        alternatingTeasersCollection {
          items {
            ... on Teaser {
              ...TeaserCard
            }
          }
        }
        bottomAccordionfaqSection {
          sectionTitle
          backgroundTitle {
            altText
            image {
              url(transform: { format: WEBP })
            }
          }
          faqSectionCollection(limit: 25) {
            items {
              title
              body {
                json
              }
              tagsCollection(limit: 3) {
                items {
                  name
                  category
                }
              }
            }
          }
        }
        bottomPromotionCard {
          ... on Teaser {
            ...TeaserCard
          }
        }
        bottomPromotionCardType
        seoMetadata {
          ...SeoMetadata
        }
        introSectionAnchorTitle
        topCardsAnchorTitle
        uspAnchorTitle
        miniParagraphsAnchorTitle
        midSectionTitleWithMediaAnchorTitle
        richtextSectionAnchorTitle
        midFaqSectionAnchorTitle
        alternatingTeasersAnchorTitle
        bottomAccordionfaqSectionAnchorTitle
        bottomPromotionCardAnchorTitle
      }
    }
  }
  ${AssemblySolutionsHeroBannerFragmentDoc}
  ${IntroSectionPreambleFragmentDoc}
  ${TeaserCardFragmentDoc}
  ${AssemblyLevel0TopCarouselItemsCollectionArticleFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
  ${RichtextSectionFragmentDoc}
  ${SeoMetadataFragmentDoc}
`;
export const GetTagsWithFilteredRelatedArticlesDocument = gql`
  query GetTagsWithFilteredRelatedArticles($tagsFilter: [TagFilter!], $locale: String!) {
    data: tagCollection(limit: 7, where: { OR: $tagsFilter }, locale: $locale) {
      ...RelatedArticlesFromStandaloneTags
    }
  }
  ${RelatedArticlesFromStandaloneTagsFragmentDoc}
  ${ContentfulMetadataFragmentFragmentDoc}
`;
export const WebcastArticleDocument = gql`
  query WebcastArticle($slug: String!, $locale: String!) {
    articleCollection(
      limit: 1
      where: {
        slug: $slug
        contentfulMetadata: { tags_exists: true, tags: { id_contains_some: ["webcast"] } }
      }
      locale: $locale
    ) {
      items {
        readableId
        ...ArticleSlugsForAllLocales
        title
        embedMedia {
          url
        }
        body {
          ...ArticleBody
        }
        publicationDate
        sys {
          publishedAt
        }
      }
    }
  }
  ${ArticleSlugsForAllLocalesFragmentDoc}
  ${ArticleBodyFragmentDoc}
  ${ArticleBodyBlockPersonFragmentDoc}
`;
